import defaultConfig from '../configs/default.config.json';
import store from '../store/store.js';

const getLibraryExternalLink = ({ host, brand, bookMeta }) => {
  return (
    `${host}` +
    `?key=${brand}-viewer` +
    `&bookId=${bookMeta.publicationId}` +
    `&paraId=${bookMeta.paraId}`
  );
};

const getCombinedExternalLink = ({
  host,
  bookMeta,
  parsedResponse,
  externalLinksConfig,
}) => {
  let stemsQueryString = '';
  let quotesQueryString = '';

  if (!parsedResponse?.stems) {
    return '';
  }

  for (const stem of parsedResponse.stems) {
    stemsQueryString +=
      parsedResponse.stems.indexOf(stem) === parsedResponse.stems.length - 1
        ? `${stem}`
        : `${stem}_`;
  }

  for (const quotes of parsedResponse.quotes) {
    const quotesToString = quotes.join(' ');

    quotesQueryString +=
      parsedResponse.quotes.indexOf(quotes) === parsedResponse.quotes.length - 1
        ? `"${quotesToString}"`
        : `"${quotesToString}"_`;
  }

  return (
    `${host}` +
    `${bookMeta.publicationId}/` +
    `${externalLinksConfig.queryString}` +
    `${bookMeta.language}` +
    `&stems=${stemsQueryString}` +
    `&quotes=${quotesQueryString}` +
    `&paraId=${bookMeta.paraId}`
  );
};

const getEmbeddedExternalLink = ({ bookMeta }) => {
  return `${bookMeta.sourceUrl}`;
};

export default {
  /**
   * @returns {String|Boolean} - external link
   */
  getExternalLink({ bookMeta, parsedResponse, brand }) {
    const isExtension = store.getters['ContextStore/isExtension'];
    if (isExtension) {
      return `${
        store.getters['ContextStore/getExtensionHost']
      }/${bookMeta.slug || bookMeta.publicationId}`;
    }

    let externalLink = ``;

    bookMeta = bookMeta || {};

    const externalLinksConfig = defaultConfig.externalLinksConfig[brand];
    const externalHosts = store.getters['ContextStore/getExternalHosts'];
    const host =
      externalHosts?.[brand] ||
      (externalLinksConfig && externalLinksConfig.library.host);

    if (!bookMeta) {
      return false;
    }

    if (!bookMeta.urlRules.type) {
      return bookMeta.sourceUrl || false;
    }

    switch (bookMeta.urlRules.type) {
      case 'library':
        externalLink = getLibraryExternalLink({ host, brand, bookMeta });
        break;
      case 'combined':
        externalLink = getCombinedExternalLink({
          host,
          bookMeta,
          parsedResponse,
          externalLinksConfig,
        });
        break;
      case 'embedded':
        externalLink = getEmbeddedExternalLink({ bookMeta });
        break;
    }
    return externalLink;
  },
};
