import {
  FILTER_CATEGORY_AUTHORS,
  FILTER_CATEGORY_PUBLICATIONS,
  FILTER_CATEGORY_CATEGORIES,
} from '@/constants/constants';

class FilterCategory {
  constructor(data = {}) {
    this.name = data.name || '';
    this.enabled = data.name === FILTER_CATEGORY_CATEGORIES || !!data.enabled;
    this.totalCount = data.totalCount || 0;
    this.selectedCount = data.selectedCount || 0;
    this.list = data.list || [];
  }
}

class FilterCategoryBuilder {
  setName(name) {
    this.name = name;
    return this;
  }

  setEnabled(enabled) {
    this.enabled = enabled;
    return this;
  }

  setTotalCount(totalCount) {
    this.totalCount = totalCount;
    return this;
  }

  setList(list) {
    for (const [key] of Object.entries(list)) {
      list[key].checked = false;
    }
    this.list = list;
    return this;
  }

  build() {
    return new FilterCategory(this);
  }
}

const createFilterCategoryByName = (categoryName, data) => {
  if (!data) {
    return new FilterCategoryBuilder().setName(categoryName).build();
  }
  return new FilterCategoryBuilder()
    .setName(categoryName)
    .setEnabled(!!data.enabled)
    .setTotalCount(data.total)
    .setList(data.list)
    .build();
};

class Filter {
  constructor(data = {}) {
    this.categories = data.categories || {};
    this.authors = data.authors || {};
    this.publications = data.publications || {};
  }
}

class FilterBuilder {
  setCategories(categories) {
    this.categories = createFilterCategoryByName(
      FILTER_CATEGORY_CATEGORIES,
      categories
    );
    return this;
  }

  setAuthors(authors) {
    this.authors = createFilterCategoryByName(FILTER_CATEGORY_AUTHORS, authors);
    return this;
  }

  setPublications(publications) {
    this.publications = createFilterCategoryByName(
      FILTER_CATEGORY_PUBLICATIONS,
      publications
    );
    return this;
  }

  build() {
    return new Filter(this);
  }
}

const createFilter = (data = {}) => {
  return new FilterBuilder()
    .setCategories(data.categories)
    .setAuthors(data.authors)
    .setPublications(data.publications)
    .build();
};

export default {
  createFilterCategoryByName,

  createFilter,
};
