<template>
  <div class="search-results-tooltip" dir="auto">
    <div data-test="content" class="search-results-tooltip-content">
      <div class="tooltip-block">
        <div class="wrapper-title-block">
          <div class="title-block">
            <div class="text-title">{{ $tc('Info.Tooltip.Title') }}</div>
            <div class="text-description">
              {{ $tc('Info.Tooltip.Description') }}
            </div>
          </div>
        </div>

        <div data-test="examples" class="wrapper-text-list">
          <div class="text-list">
            <div class="example-block">
              <div v-html="$tc('Info.Tooltip.Paragraph1.label')" />
            </div>
            <div class="descrition-block">
              {{ $tc('Info.Tooltip.Paragraph1.title') }}
            </div>
          </div>
          <div class="text-list">
            <div class="example-block">
              <div v-html="$tc('Info.Tooltip.Paragraph2.label')" />
            </div>
            <div class="descrition-block">
              {{ $tc('Info.Tooltip.Paragraph2.title') }}
            </div>
          </div>
          <div class="text-list">
            <div class="example-block">
              <div v-html="$tc('Info.Tooltip.Paragraph3.label')" />
            </div>
            <div class="descrition-block">
              {{ $tc('Info.Tooltip.Paragraph3.title') }}
            </div>
          </div>
          <div class="text-list">
            <div class="example-block">
              <div v-html="$tc('Info.Tooltip.Paragraph4.label')" />
            </div>
            <div class="descrition-block">
              {{ $tc('Info.Tooltip.Paragraph4.title') }}
            </div>
          </div>
        </div>
        <!--/examples-->
      </div>

      <div class="tooltip-block">
        <div class="wrapper-title-block spec-title-block">
          <div class="title-block">
            <div class="text-title">{{ $tc('Info.HotKeys.Title') }}</div>
          </div>
        </div>

        <div class="search-keys">
          <div class="search-key-item">
            <div class="key-icon-block">
              <div class="key-icon">
                <span class="key-button">Backspace</span>
                <span class="no-key-button">or</span>
                <span class="key-button padd-left">Delete</span>
              </div>
              <div class="guide-rail" />
            </div>
            <div class="key-description-block">
              {{ $tc('Info.HotKeys.Control1.label1') }}
            </div>
          </div>

          <div class="search-key-item">
            <div class="key-icon-block">
              <div class="key-icon">
                <span class="key-button">&uarr;</span>
                <span class="no-key-button">or</span>
                <span class="key-button">&darr;</span>
              </div>
              <div class="guide-rail" />
            </div>
            <div class="key-description-block">
              {{ $tc('Info.HotKeys.Control2.label1') }} <br />
              {{ $tc('Info.HotKeys.Control2.label2') }}
            </div>
          </div>

          <div class="search-key-item">
            <div class="key-icon-block">
              <div class="key-icon">
                <span class="key-button padd-right">Ctrl</span>
                <span class="no-key-button">+</span>
                <span class="key-button">I</span>
              </div>
              <div class="guide-rail" />
            </div>
            <div class="key-description-block">
              {{ $tc('Info.HotKeys.Control3.label1') }}
            </div>
          </div>

          <div class="search-key-item">
            <div class="key-icon-block">
              <div class="key-icon">
                <span class="key-button padd-right">Ctrl</span>
                <span class="no-key-button">+</span>
                <span class="key-button">&larr;</span>
                <span class="key-button">&rarr;</span>
              </div>
              <div class="guide-rail" />
            </div>
            <div class="key-description-block">
              {{ $tc('Info.HotKeys.Control4.label1') }}
            </div>
          </div>

          <div class="search-key-item">
            <div class="key-icon-block">
              <div class="key-icon">
                <span class="key-button">Esc</span>
              </div>
              <div class="guide-rail" />
            </div>
            <div class="key-description-block">
              {{ $tc('Info.HotKeys.Control5.label1') }}
            </div>
          </div>

          <div class="search-key-item">
            <div class="key-icon-block">
              <div class="key-icon">
                <span class="key-button padd-right">Shift</span>
                <span class="no-key-button">+</span>
                <span class="key-button">&larr;</span>
                <span class="key-button">&rarr;</span>
                <span class="no-key-button">or</span>
                <span class="key-button">&uarr;</span>
                <span class="key-button">&darr;</span>
              </div>
              <div class="guide-rail" />
            </div>
            <div class="key-description-block">
              {{ $tc('Info.HotKeys.Control6.label1') }}
            </div>
          </div>

          <div class="search-key-item">
            <div class="key-icon-block">
              <div class="key-icon">
                <span class="key-button">Tab</span>
                <span class="no-key-button">or</span>
                <span class="key-button">Shift</span>
                <span class="no-key-button">+</span>
                <span class="key-button">Tab</span>
              </div>
              <div class="guide-rail" />
            </div>
            <div class="key-description-block">
              {{ $tc('Info.HotKeys.Control7.label1') }}
            </div>
          </div>

          <div class="search-key-item">
            <div class="key-icon-block">
              <div class="key-icon">
                <span class="key-button padd-left">Enter</span>
              </div>
              <div class="guide-rail" />
            </div>
            <div class="key-description-block">
              {{ $tc('Info.HotKeys.Control8.label1') }}
            </div>
          </div>

          <div class="search-key-item">
            <div class="key-icon-block">
              <div class="key-icon">
                <span class="key-button">&larr;</span>
                <span class="no-key-button">or</span>
                <span class="key-button">&rarr;</span>
              </div>
              <div class="guide-rail" />
            </div>
            <div class="key-description-block">
              {{ $tc('Info.HotKeys.Control9.label1') }}
            </div>
          </div>

          <div class="search-key-item">
            <div class="key-icon-block">
              <div class="key-icon">
                <span class="key-button padd-left padd-right">Space</span>
                <span class="no-key-button">or</span>
                <span class="key-button padd-right">Shift</span>
                <span class="no-key-button">+</span>
                <span class="key-button padd-left padd-right">Space</span>
              </div>
              <div class="guide-rail" />
            </div>
            <div class="key-description-block">
              {{ $tc('Info.HotKeys.Control10.label1') }}
            </div>
          </div>

          <div class="search-key-item" />

          <div class="search-key-item">
            <div class="key-icon-block">
              <div class="key-icon">
                <span class="key-button padd-right">Shift</span>
              </div>
              <div class="guide-rail" />
            </div>
            <div class="key-description-block">
              {{ $tc('Info.HotKeys.Control11.label1') }}
            </div>
          </div>
        </div>
        <!--/search-keys-->
      </div>
    </div>
    <div
      class="close-tooltip-block search-results-close"
      data-test="close"
      @click="hideInfo"
    >
      <span class="icon-container">
        <BaseIcon icon-name="ico-close" />
      </span>
    </div>
  </div>
</template>

<script>
  import BaseIcon from '@/components/BaseIcon';

  import * as log from 'loglevel';
  log.setLevel('debug');

  export default {
    name: 'Info',
    components: {
      BaseIcon,
    },
    methods: {
      hideInfo() {
        this.$store.dispatch('ContextStore/setInfoOpened', false);
      },
    },
  };
</script>

<style lang="less">
  @import 'Info.less';
</style>
