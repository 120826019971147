<template>
  <div class="error-window-block">
    <span class="error-img font-icon icon-cup-of-coffe-with-heart" />
    <div class="error-message">
      <div>{{ infoMessage }}</div>
      <div class="reload-link" v-show="showRetryBlock">
        <span class="icon-container reload-img">
          <BaseIcon icon-name="ico-reload" />
        </span>
        <a href="">{{ $tc('BaseErrorWindow.reloadingMessage') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  import BaseIcon from '../components/BaseIcon';
  import { mapState } from 'vuex';

  export default {
    name: 'BaseErrorWindow',
    components: {
      BaseIcon,
    },
    data() {
      return {
        infoMessage: this.$tc(this.errorMessage),
      };
    },
    computed: {
      ...mapState('ErrorStore', ['errorMessage', 'stopRetry']),
      showRetryBlock() {
        return this.$store.getters['ErrorStore/getIsRetryBlockShouldBeShown'];
      },
    },
    watch: {
      stopRetry: {
        handler: function(value) {
          if (value) {
            this.infoMessage = this.$tc('BaseErrorWindow.retry.laterMessage');
          }
        },
      },
      errorMessage: {
        handler: function(value) {
          if (value) {
            this.infoMessage = this.$tc(value);
          }
        },
      },
    },
  };
</script>

<style lang="less">
  @import '../assets/styles/variables.less';

  .error-window-block {
    flex-grow: 1;
    position: relative;
    padding: 50px 20px;
    box-shadow: inset 0 2px 4px 0 fade(@c-black, 17%);

    .error-img {
      height: 100px;
      justify-content: center;
      margin: auto;
      display: flex;
      color: @c-gray;

      &:before {
        font-size: 100px;
      }
    }

    .error-message {
      font-size: var(--font-error-size);
      padding: 20px;
      text-align: center;
      font-weight: 500;

      a {
        color: var(--color-lilac);
        font-size: var(--font-reload-size);
        text-decoration: none;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .reload-link {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
      color: @c-lilac;

      .icon-container {
        margin-inline-end: 10px;
      }
    }

    .reload-img {
      display: flex;
      animation: 1s linear 0s infinite normal spin;

      svg {
        width: 24px;
        height: 24px;
        fill: var(--color-lilac);
      }
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    .close-button {
      width: 10%;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
</style>
