import each from 'lodash/each';
import first from 'lodash/first';

import SearchHighlighter from './OfflineSearch/publication/search-highlighter';
import TextUtils from './OfflineSearch/publication/dom-utils/text-utils';

import { SCROLL_UP } from '../constants/constants';

const searchWordDecoratorClassName = 'search-req';
const quoteDecoratorClassName = 'search-req';
const searchSentenceDecoratorClassName = 'search-sentence';
const decoratorClassNames = [
  searchWordDecoratorClassName,
  quoteDecoratorClassName,
  searchSentenceDecoratorClassName,
];

const searchHighlighterInst = new SearchHighlighter();
const textUtilsInst = new TextUtils();

export default {
  highlightWords(element, wordForms) {
    searchHighlighterInst.decorateSearchWords(
      wordForms,
      element,
      searchWordDecoratorClassName
    );
  },

  highlightQuotes(element, quotes) {
    searchHighlighterInst.decorateSearchQuotes(
      quotes,
      element,
      quoteDecoratorClassName
    );
  },

  undecorateSearchSentence() {
    const undecorateContainer = first(document.find('.nota-wrapper'));
    if (!undecorateContainer) {
      return;
    }
    each(decoratorClassNames, function(decoratorClassName) {
      searchHighlighterInst.undecorateByClass(
        decoratorClassName,
        undecorateContainer
      );
    });
  },

  highlightSentence(element, foundSentence) {
    if (!element) {
      return;
    }
    const sentenceElement = window.document.createElement('DIV');

    sentenceElement.innerHTML = foundSentence;
    const qouteFoundSentence = textUtilsInst.extractContent(sentenceElement);
    const quote = textUtilsInst.createQuoteFromSentence(qouteFoundSentence);

    searchHighlighterInst.decorateSearchQuotes(
      [quote],
      element,
      searchSentenceDecoratorClassName
    );
  },

  highlightSearchSentenceInParagraph(paragraph, sentence) {
    const element = window.document.createElement('DIV');
    element.innerHTML = paragraph;
    this.highlightSentence(element, sentence);
    return element.innerHTML;
  },

  highlightSearchResultInSentence(sentence, wordForms, quotes) {
    const element = window.document.createElement('DIV');
    element.innerHTML = sentence;
    this.highlightSearchResult(element, wordForms, quotes);
    return element.innerHTML;
  },

  highlightSearchResult(element, wordForms, quotes) {
    wordForms = wordForms || [];
    quotes = quotes || [];

    if (wordForms.length !== 0) {
      this.highlightWords(element, wordForms);
    }
    if (quotes.length !== 0) {
      this.highlightQuotes(element, quotes);
    }
  },

  wrapTextBySentenceDecorator(text) {
    return '<span class="search-sentence">' + text + '</span>';
  },

  createHTML(text, paragraph, locator, tag, index, direction) {
    let paraClass;
    if (direction) {
      paraClass =
        index === 0 && direction === SCROLL_UP
          ? '"><span class="paragraph-number-first">'
          : '"><span class="paragraph-number">';
    } else {
      paraClass =
        index === 0
          ? '"><span class="paragraph-number-first">'
          : '"><span class="paragraph-number">';
    }

    return tag && tag.indexOf('h') !== -1
      ? this.createTitle(text, paragraph, tag, locator, paraClass)
      : this.createPara(text, paragraph, locator, paraClass);
  },

  createTitle(text, paragraph, tag, locator, paraClass) {
    return (
      '<' +
      tag +
      ' data-sw-id="' +
      (locator || '') +
      paraClass +
      paragraph +
      '</span>' +
      text +
      ' </' +
      tag +
      '>'
    );
  },

  createPara(text, paragraph, locator, paraClass) {
    return (
      '<p data-sw-id="' +
      locator +
      paraClass +
      paragraph +
      '</span>' +
      text +
      ' </p>'
    );
  },
};
