import get from 'lodash/get';

import FullTextSearchService from '@/services/Search/FullTextSearchService';
import ParseResponse from '@/services/ElasticSearch/ParseResponse';
import request from '@/services/ElasticSearch/Request';

import languageUtils from '@/utils/languageUtils.js';

import config from '@/configs/default.config.json';

import * as log from 'loglevel';
import {
  TIMER_FOR_GET_CONFIG_RETRY,
  COUNTER_FOR_GET_CONFIG_ATTEMPTS,
  FOCUS_SELECTOR_PARAGRAPHS,
  FOCUS_SELECTOR_SEARCH_FIELD,
  FOCUS_SELECTOR_HITS,
} from '@/constants/constants';

log.setLevel('error');
let timer;
let attemtpCounter = 0;

const state = _createInitialContextState();

function _createInitialContextState() {
  return {
    parameters: {},
    env: null,
    brand: null,
    searchInfo: null,
    esInfoMap: null,
    esOnline: null,
    isPartialAccess: null,
    lang: 'en',
    hitsActive: true,
    mainPopupOpened: false,
    menuOpened: false,
    keyboardOpened: false,
    infoOpened: false,
    focus: {
      [FOCUS_SELECTOR_SEARCH_FIELD]: true,
      [FOCUS_SELECTOR_HITS]: false,
      [FOCUS_SELECTOR_PARAGRAPHS]: false,
    },
  };
}

const getters = {
  isExtension: state => {
    return state.isExtension;
  },
  getEnv: state => {
    return state.env;
  },
  getBrand: state => {
    return state.brand;
  },
  getEsInfoMap: state => {
    return state.esInfoMap;
  },
  getSearchInfo: state => {
    return state.searchInfo;
  },
  getSupplementalLibConfig: state => {
    return get(state, 'parameters.supplementalLibConfig', {});
  },
  getIndexSummaryUrls: state => {
    return get(state, 'parameters.indexSummaryUrls', {});
  },
  getServerUrls: state => {
    return get(state, 'parameters.serverUrls', {});
  },
  getElasticSearchUrl: state => {
    return get(state, 'parameters.elasticSearchUrl', null);
  },
  getExtensionHost: state => {
    return get(state, 'parameters.extensionHost', null);
  },
  getExternalHosts: state => {
    return get(state, 'parameters.externalHosts', null);
  },
  getLang: state => {
    return state.lang;
  },
  getLocalizedNumber: state => number => {
    return languageUtils.localizeNumber(number, state.lang);
  },
  isParametersInitiated: state => {
    return Object.keys(state.parameters).length > 0;
  },
};

const actions = {
  async initSearch({ commit }) {
    try {
      const initSearchResponse = await initFullTextSearchService();

      const esInfoMap = initSearchResponse?.esInfoMap;
      if (!Object.keys(esInfoMap).length) {
        commit('setEsOnline', false);
        throw new Error('Elasticsearch is unavailable');
      }

      const searchInfo = initSearchResponse.searchInfo;
      if (!searchInfo) {
        throw new Error('Search info was not found');
      }

      commit('setSearchInfo', searchInfo);
      commit('setEsInfoMap', esInfoMap);
      commit('setEsOnline', true);
    } catch (error) {
      log.error(error);
      throw error;
    }
  },
  initConfigParameters({ commit, state }) {
    const parameters = get(config, 'parameters', {});
    commit('setBrand', parameters.brand);

    parameters.elasticSearchUrl = state.elasticSearchUrl;
    commit('setParameters', parameters);
  },
  async initRemoteConfigParameters({ commit, dispatch }, env) {
    try {
      if (!config.parameters.indexSummaryUrls.hasOwnProperty(env)) {
        log.warn(
          `[search-widget] Requested config for unknown environment.
           Please, provide proper environment name in place where search widget defined.`
        );
      }
      const remoteConfig = await getRemoteConfigParameters(
        config.parameters.indexSummaryUrls[env]
      );
      dispatch('ErrorStore/setIsErrorOccurred', false, { root: true });
      commit('setParameters', remoteConfig);
      return Promise.resolve();
    } catch (error) {
      log.error(
        '[search-widget] Set config action failed with error: ' + error
      );
      if (
        error.response &&
        (error.response.status === 500 || error.response.status === 404)
      ) {
        dispatch('ErrorStore/setIsErrorOccurred', true, { root: true });
        if (attemtpCounter < COUNTER_FOR_GET_CONFIG_ATTEMPTS) {
          getConfigureParameters(dispatch, env);
        } else {
          dispatch('ErrorStore/setStopRetry', true, { root: true });
        }
      }
      return Promise.reject();
    }
  },
  setPartialAccess({ commit }, isPartialAccess) {
    commit('setPartialAccess', isPartialAccess);
  },
  setEnv({ commit }, env) {
    commit('setEnv', env);
  },
  setBrand({ commit }, brand) {
    commit('setBrand', brand);
  },
  setIsExtension({ commit }, isExtension) {
    commit('setIsExtension', isExtension);
  },
  setLang({ commit, dispatch, state }, lang) {
    if (state.lang === undefined || state.lang === lang) {
      return;
    }
    dispatch('SearchStore/totalReset', null, { root: true });
    dispatch('setMenuOpened', false);
    commit('setLang', lang);
  },
  setHitsActive({ commit }, isActive) {
    commit('setHitsActive', isActive);
  },
  setMainPopupOpened({ commit, dispatch }, isOpened) {
    commit('setMainPopupOpened', isOpened);

    if (isOpened) {
      dispatch('SearchStore/getLastSearchText', null, { root: true });
      dispatch('HitsStore/getLastActiveHit', null, { root: true });
      dispatch('FilterStore/getLastUserFilter', null, { root: true });
    }
  },
  setMenuOpened({ commit }, isOpened) {
    commit('setMenuOpened', isOpened);
  },
  setKeyboardOpened({ commit }, isKeyboardOpened) {
    commit('setKeyboardOpened', isKeyboardOpened);
  },
  setInfoOpened({ commit }, isInfoOpened) {
    commit('setInfoOpened', isInfoOpened);
  },
  changeFocus({ commit, state }, options) {
    const focus = { ...state.focus };

    options.selector
      ? Object.keys(focus).forEach(
          key => (focus[key] = key === options.selector)
        )
      : Object.keys(focus).some(setFocusByDirection);

    commit('changeFocus', focus);

    function setFocusByDirection(key, index, array) {
      if (key && focus[key]) {
        focus[key] = false;

        const nextElement = array[index + options.direction];
        if (nextElement) {
          focus[nextElement] = true;
        } else {
          const i = index === array?.length - 1 ? 0 : array?.length - 1;
          focus[array[i]] = true;
        }
        return true;
      }
    }
  },
};

const mutations = {
  setParameters(state, parameters) {
    state.parameters = { ...parameters, ...state.parameters };
  },
  setEnv(state, env) {
    state.env = env;
  },
  setBrand(state, brand) {
    state.brand = brand;
  },
  setSearchInfo(state, searchInfo) {
    state.searchInfo = searchInfo;
  },
  setEsInfoMap(state, esInfoMap) {
    state.esInfoMap = esInfoMap;
  },
  setEsOnline(state, esOnline) {
    state.esOnline = esOnline;
  },
  setPartialAccess(state, isPartialAccess) {
    state.isPartialAccess = isPartialAccess;
  },
  setIsExtension(state, isExtension) {
    state.isExtension = isExtension;
  },
  setLang(state, lang) {
    state.lang = lang;
  },
  setHitsActive(state, isActive) {
    state.hitsActive = isActive;
  },
  setMainPopupOpened(state, isMainPopupOpened) {
    state.mainPopupOpened = isMainPopupOpened;
  },
  setMenuOpened(state, isMenuOpened) {
    state.menuOpened = isMenuOpened;
  },
  setKeyboardOpened(state, isKeyboardOpened) {
    state.keyboardOpened = isKeyboardOpened;
  },
  setInfoOpened(state, isInfoOpened) {
    state.infoOpened = isInfoOpened;
  },
  changeFocus(state, focus) {
    state.focus = focus;
  },
};

async function initFullTextSearchService() {
  const responses = await FullTextSearchService.init();
  return ParseResponse.parseInitSearchResponse({ responses });
}

async function getRemoteConfigParameters(indexSummaryUrls) {
  const response = await request.request(
    indexSummaryUrls,
    'get',
    `searchWidgetConfig`
  );
  return ParseResponse.parseRemoteConfigResponse({ response });
}

function getConfigureParameters(dispatch, env) {
  if (timer) {
    clearTimeout(timer);
    timer = null;
  }
  timer = setTimeout(() => {
    dispatch('initRemoteConfigParameters', env);
    attemtpCounter++;
  }, TIMER_FOR_GET_CONFIG_RETRY);
}

export default {
  name: 'ContextStore',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
