import BookMetaFactory from '@/classes/factories/BookMetaFactory';
import SearchService from '@/services/SearchService';
import SearchStemsWrapperService from '@/services/SearchStemsWrapperService';

class HitItem {
  constructor(data = {}) {
    this.id = data.id;
    this.firstGroupItem = data.firstGroupItem;
    this.bookMeta = data.bookMeta || {};
    this.text = data.text || '';
    this.paraId = data.paraId;
    this.publicationId = data.publicationId;
    this.moreTextIds = data.moreTextIds;
  }
}

class HitItemBuilder {
  setId(id) {
    this.id = id;
    return this;
  }

  setBookMeta(bookMeta) {
    this.bookMeta = bookMeta;
    return this;
  }

  setText(highlight, paragraphs) {
    if (!highlight) {
      return this;
    }

    highlight = SearchService.minimizeSent(highlight);
    const paragraphNumber =
      '<span class="paragraph-number">' + paragraphs + '</span>';
    this.text =
      paragraphNumber +
      SearchStemsWrapperService.highlightSearchResultInSentence(highlight);
    return this;
  }

  setFirstGroupItem(firstGroupItem) {
    this.firstGroupItem = firstGroupItem;
    return this;
  }

  setParaId(paraId) {
    this.paraId = paraId;
    return this;
  }

  setPublicationId(publicationId) {
    this.publicationId = publicationId;
    return this;
  }

  setMoreTextIds(moreTextIds) {
    this.moreTextIds = moreTextIds;
    return this;
  }

  build() {
    return new HitItem(this);
  }
}

const createHitItem = (data = {}) => {
  const bookMeta = BookMetaFactory.createBookMeta(data);

  return new HitItemBuilder()
    .setId(data.docId)
    .setBookMeta(bookMeta)
    .setText(data.highlight, data.paragraphs)
    .setFirstGroupItem(data.firstGroupItem)
    .setParaId(data.paraId)
    .setPublicationId(data.publicationId)
    .setMoreTextIds(data.moreTextIds)
    .build();
};

export default {
  createHitItem,
};
