export default {
  /**
   * Search Field Events
   */
  QUERY_INPUTTED: 'queryInputted',
  BACKSPACE_PRESSED: 'backspacePressed',
  IS_ERROR_OCCURRED: 'isErrorOccurred',
  QUERY_INPUTTED_AFTER_RELOAD: 'queryInputtedAfterReload',
  SHIFT_TAB_PRESSED: 'shiftTabPressed',
  TAB_PRESSED: 'tabPressed',
  ON_FOCUS: 'onFocus',
  ON_BLUR: 'onBlur',
  ON_DOUBLE_CLICK: 'onDoubleClick',
  ENTER_PRESSED: 'enterPressed',

  /**
   * Search Hits Events
   */
  HIT_ITEM_CLICKED: 'hiItemClicked',

  /**
   * Navigation Events
   */
  NAVIGATE_BACK: 'navigateBack',
  NAVIGATE_FORWARD: 'navigateForward',
};
