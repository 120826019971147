import get from 'lodash/get';

import {
  FILTER_CATEGORY_AUTHORS,
  FILTER_CATEGORY_PUBLICATIONS,
  FILTER_CATEGORY_CATEGORIES,
} from '@/constants/constants';

const _handleAuthorChecked = ({ relatedBooks, filter, selected }) => {
  selected.checked = true;
  filter[FILTER_CATEGORY_AUTHORS].selectedCount++;

  relatedBooks.forEach(book => {
    const _book = filter[FILTER_CATEGORY_PUBLICATIONS].list[book];
    if (_book) {
      _book.checked = false;
      _book.active = true;
    }
  });
};

const _handleAuthorUnchecked = ({ relatedBooks, filter, selected }) => {
  selected.checked = false;
  filter[FILTER_CATEGORY_AUTHORS].selectedCount--;

  relatedBooks.forEach(book => {
    const _book = get(filter[FILTER_CATEGORY_PUBLICATIONS], `list[${book}]`);
    if (_book && !_book.checked) {
      _book.checked = false;
      _book.active = false;
    }
  });
};

const _parseClickOnAuthor = ({
  filter,
  activeFilterCategory,
  selectedKey,
  checked,
}) => {
  const category = filter[activeFilterCategory];
  const selected = category.list[selectedKey];
  const relatedBooks = selected.related.list;

  if (checked) {
    _handleAuthorChecked({ relatedBooks, filter, selected });
  } else {
    _handleAuthorUnchecked({ relatedBooks, filter, selected });
  }
};

const _handleBookChecked = ({ relatedAuthors, filter, selected }) => {
  selected.checked = true;
  filter[FILTER_CATEGORY_PUBLICATIONS].selectedCount++;

  relatedAuthors.forEach(author => {
    const _author = filter[FILTER_CATEGORY_AUTHORS].list[author];
    if (_author && !_author.checked) {
      _author.checked = true;
      filter[FILTER_CATEGORY_AUTHORS].selectedCount++;

      const relatedBooks =
        filter[FILTER_CATEGORY_AUTHORS].list[author].related.list;
      relatedBooks.forEach(book => {
        const _book = filter[FILTER_CATEGORY_PUBLICATIONS].list[book];
        if (_book) {
          _book.active = true;
        }
      });
    }
  });
};

const _handleBookUnchecked = ({ filter, selected }) => {
  selected.checked = false;
  filter[FILTER_CATEGORY_PUBLICATIONS].selectedCount--;

  const relatedAuthor = selected.related.list[0];
  selected.active = (
    filter[FILTER_CATEGORY_AUTHORS].list[relatedAuthor] || {}
  ).checked;
};

const _parseClickOnBook = ({
  filter,
  activeFilterCategory,
  selectedKey,
  checked,
}) => {
  const category = filter[activeFilterCategory];
  const selected = category.list[selectedKey];
  const relatedAuthors = selected.related.list;
  if (checked) {
    _handleBookChecked({ relatedAuthors, filter, selected });
  } else {
    _handleBookUnchecked({ filter, selected });
  }
};

const _handleCategoryChecked = ({ filter, selected }) => {
  selected.checked = true;
  filter[FILTER_CATEGORY_CATEGORIES].selectedCount++;
};

const _handleCategoryUnchecked = ({ relatedBooks, filter, selected }) => {
  selected.checked = false;
  filter[FILTER_CATEGORY_CATEGORIES].selectedCount--;

  relatedBooks.forEach(book => {
    const _book = filter[FILTER_CATEGORY_PUBLICATIONS].list[book];
    if (_book) {
      _book.checked = false;
      filter[FILTER_CATEGORY_PUBLICATIONS].selectedCount--;

      const authors = _book.related.list;
      authors.forEach(author => {
        const _author = filter[FILTER_CATEGORY_AUTHORS].list[author];
        if (_author) {
          _author.checked = false;
          filter[FILTER_CATEGORY_AUTHORS].selectedCount--;
        }
      });
    }
  });
};

const _parseClickOnCategory = ({
  filter,
  activeFilterCategory,
  selectedKey,
  checked,
}) => {
  const category = filter[activeFilterCategory];
  const selected = category.list[selectedKey];
  const relatedBooks = selected.related.list;
  if (checked) {
    _handleCategoryChecked({ filter, selected });
  } else {
    _handleCategoryUnchecked({ relatedBooks, filter, selected });
  }
};

const filterClickHandlersNamesByCategory = {
  [FILTER_CATEGORY_AUTHORS]: _parseClickOnAuthor,
  [FILTER_CATEGORY_PUBLICATIONS]: _parseClickOnBook,
  [FILTER_CATEGORY_CATEGORIES]: _parseClickOnCategory,
};

const parseFilterClick = ({
  filter,
  activeFilterCategory,
  selectedKey,
  checked,
}) => {
  const filterClickHandlerName =
    filterClickHandlersNamesByCategory[activeFilterCategory];
  if (!filterClickHandlerName) {
    return;
  }
  filterClickHandlerName({
    filter,
    activeFilterCategory,
    selectedKey,
    checked,
  });
};

/**
 *
 * @param filter
 * @returns {
 *  {
 *    categories: [],
 *    publications: [],
 *  }
 * }
 */
const getParsedFilterQuery = ({ filter }) => {
  const filterQuery = {};

  for (const category in filter) {
    if (category === FILTER_CATEGORY_AUTHORS) {
      continue;
    }

    const categoryList = filter[category].list;
    filterQuery[category] = Object.keys(categoryList).filter(
      key => categoryList[key].checked
    );
  }

  if (filterQuery[FILTER_CATEGORY_PUBLICATIONS]?.length) {
    return {
      [FILTER_CATEGORY_PUBLICATIONS]: filterQuery[FILTER_CATEGORY_PUBLICATIONS],
    };
  }
  return filterQuery;
};

/**
 * After new query inputted only category 'Categories' should be enabled.
 * Other categories enabled only if either one item from 'Categories' checked
 *
 * @param filter
 */
const setCategoriesEnabledIfNeeded = ({ filter }) => {
  const isAnyCategoryChecked = !!Object.values(
    filter[FILTER_CATEGORY_CATEGORIES].list
  ).find(value => value.checked);

  filter[FILTER_CATEGORY_AUTHORS].enabled = isAnyCategoryChecked;
  filter[FILTER_CATEGORY_PUBLICATIONS].enabled = isAnyCategoryChecked;
};

const parseRegularFilterResponse = ({ filterState, filterNew }) => {
  for (const category in filterNew) {
    if (category === FILTER_CATEGORY_CATEGORIES) {
      filterNew[FILTER_CATEGORY_CATEGORIES] =
        filterState[FILTER_CATEGORY_CATEGORIES];
      continue;
    }

    const categoryListState = filterState[category].list;
    const categoryListNew = filterNew[category].list;

    for (const [key] of Object.entries(categoryListState)) {
      if (categoryListState[key].checked) {
        categoryListNew[key].checked = true;
        filterNew[category].selectedCount++;
      }

      if (categoryListState[key].active) {
        categoryListNew[key].active = categoryListState[key].active;
      }
    }
    filterNew[category].totalCount = Object.keys(categoryListNew).length;
  }
  return filterNew;
};

export default {
  parseFilterClick,
  parseRegularFilterResponse,
  getParsedFilterQuery,
  setCategoriesEnabledIfNeeded,
};
