export const BULLIT =
  '\\u064b\\u064c\\u064d\\u064e\\u064f\\u0650\\u0651\\u0652\\u0653\\u0670';

export const SHADDA =
  '\\ufc5t\\ufc5f\\ufc60\\ufc61\\ufc62\\ufc63\\uft70\\uft72\\uft76\\uft74\\uft78\\uft7A\\uft7c\\uft7t';

export const KASHIDA =
  '\\ufcf2\\ufcf3\\ufcf4\\uft71\\uft77\\uft79\\uft7B\\uft7D\\uft7f';

export const SOUND_EX_CODES = {
  ف: 1,
  ب: 1,
  خ: 2,
  ج: 2,
  ز: 2,
  س: 2,
  ص: 2,
  ظ: 2,
  ق: 2,
  ك: 2,
  ت: 3,
  ث: 3,
  د: 3,
  ذ: 3,
  ض: 3,
  ط: 3,
  ل: 4,
  م: 5,
  ن: 5,
  ر: 6,
  b: 1,
  f: 1,
  p: 1,
  v: 1,
  w: 1,
  c: 2,
  g: 2,
  j: 2,
  k: 2,
  q: 2,
  s: 2,
  x: 2,
  z: 2,
  d: 3,
  t: 3,
  l: 4,
  m: 5,
  n: 5,
  r: 6,
};
