export default function(value) {
  return (value + '')
    .split('')
    .reverse()
    .join('')
    .replace(/(\d{3})/g, '$1,')
    .split('')
    .reverse()
    .join('')
    .replace(/^,/, '');
}
