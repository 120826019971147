import get from 'lodash/get';

import * as log from 'loglevel';
log.setLevel('error');

import HitsFactory from '@/classes/factories/HitsFactory';
import SearchFactory from '@/classes/factories/SearchFactory';

import eventManager from '@/services/EventService';
import InputService from '@/services/InputService';
import localStorageService from '@/services/LocalStorageService';
import validateQueryService from '@/services/Search/ValidateQueryService';

import LocalStorageKeyEnum from '@/enums/LocalStorageKeyEnum';
import ErrorMessagesEnum from '@/enums/ErrorMessagesEnum';

import parseQuery from '@/utils/searchUtils';

import config from '@/configs/default.config.json';

const DEFAULT_SEARCH_TEXT = '';
const DEFAULT_PARSED_QUERY = {};
const DEFAULT_PARSED_RESPONSE = {};
const DEFAULT_SEARCH_IN_PROCESS = false;
const DEFAULT_TOTAL_VALUE = 0;
const DEFAULT_FOUND_NOTHING = false;

const state = _createInitialSearchState();

function _createInitialSearchState() {
  return {
    searchInProcess: DEFAULT_SEARCH_IN_PROCESS,
    parsedQuery: DEFAULT_PARSED_QUERY,
    parsedResponse: DEFAULT_PARSED_RESPONSE,
    searchText: DEFAULT_SEARCH_TEXT,
    totalPublications: DEFAULT_TOTAL_VALUE,
    totalHits: DEFAULT_TOTAL_VALUE,
    requestIds: [],
    foundNothing: DEFAULT_FOUND_NOTHING,
  };
}

const getters = {
  getSearchText: state => {
    return state.searchText;
  },
  getParsedQuery: state => {
    return state.parsedQuery;
  },
};

const actions = {
  async performSearch({ dispatch, commit, getters }) {
    try {
      eventManager.publish('cancelPreviousRequests');

      dispatch('setFoundNothing', false);
      dispatch('setSearchInProcess', true);
      dispatch('fillParsedQuery');

      const parsedQuery = getters['getParsedQuery'];
      if (!parsedQuery.validResp) {
        throw new Error(ErrorMessagesEnum.QUERY_IS_NOT_VALID);
      }
      const hitsSearchResponse = await dispatch('HitsStore/getHits', null, {
        root: true,
      });

      let firstDoc = get(hitsSearchResponse, 'sentencesList.rows[0]', {});
      firstDoc = HitsFactory.createHitItem(firstDoc);

      if (!firstDoc.id) {
        throw new Error(ErrorMessagesEnum.NO_HITS_FOUND);
      }

      const paragraphsSearchResponse = await dispatch(
        'ParagraphsStore/getParagraphs',
        firstDoc,
        { root: true }
      );

      const navigationListSearchResponse = await dispatch(
        'NavigationStore/getNavigation',
        firstDoc,
        { root: true }
      );

      dispatch('resetSearchStates');

      dispatch(
        'NavigationStore/fillNavigationStore',
        navigationListSearchResponse,
        { root: true }
      );
      dispatch('HitsStore/fillHitsStore', hitsSearchResponse, { root: true });
      dispatch(
        'ParagraphsStore/fillParagraphsStore',
        paragraphsSearchResponse,
        {
          root: true,
        }
      );

      commit('setTotalPublications', hitsSearchResponse.totalPublications);
      commit('setTotalHits', hitsSearchResponse.totalHits);
      commit('setParsedResponse', hitsSearchResponse.sentencesList);
      dispatch('ErrorStore/setIsRequestCancelled', false, { root: true });
    } catch (error) {
      handleError(error, dispatch, commit, this.state.SearchStore);
    } finally {
      dispatch('setSearchInProcess', false);
    }
    return Promise.resolve();
  },

  setSearchText({ commit, dispatch, rootGetters }, searchText) {
    if (this.state.SearchStore.searchText === searchText) {
      return;
    }
    if (searchText === '') {
      dispatch('resetSearchStates');
      dispatch('resetSearchStore');
      commit('setSearchText', searchText);
      dispatch('setLastSearchText');
      return;
    }
    let correctInput = searchText;
    const isInputFromVirtualKeyboard =
      rootGetters['KeyboardStore/getIsInputFromVirtualKeyboard'];
    const isBackspacePressed =
      rootGetters['KeyboardStore/getIsBackspacePressed'];

    if (isInputFromVirtualKeyboard && !isBackspacePressed) {
      correctInput = InputService.correctInputIfCursorInsideWord(searchText);
    }
    dispatch('KeyboardStore/setIsBackspacePressed', false, { root: true });
    dispatch('FilterStore/resetFilterStore', null, { root: true });
    commit('setSearchText', correctInput);
    dispatch('setLastSearchText');
  },

  fillParsedQuery({ commit, rootGetters }) {
    const lang = rootGetters['ContextStore/getLang'];
    const searchText = this.state.SearchStore.searchText;
    const filter = rootGetters['FilterStore/getSearchFilter'];
    const parsedQuery = parseQuery(
      searchText,
      config.searchConfig,
      lang,
      filter
    );
    const validatedParsedQuery = validateQueryService.validateQuery(
      searchText,
      lang,
      parsedQuery
    );
    commit('setParsedQuery', validatedParsedQuery);
  },

  resetSearchStates({ dispatch }) {
    dispatch('HitsStore/resetHitsStore', null, { root: true });
    dispatch('ParagraphsStore/resetParagraphsStore', null, {
      root: true,
    });
    dispatch('NavigationStore/resetNavigationStore', null, { root: true });
    dispatch('KeyboardStore/resetKeyboardStore', null, { root: true });
  },

  resetSearchStore({ commit, dispatch }) {
    commit('setParsedQuery');
    commit('setSearchInProcess');
    commit('setTotalPublications');
    commit('setTotalHits');
    commit('setFoundNothing', DEFAULT_FOUND_NOTHING);
    commit('setSearchInProcess', DEFAULT_SEARCH_IN_PROCESS);

    dispatch('ErrorStore/resetErrorStore', null, { root: true });
  },

  totalReset({ dispatch }) {
    dispatch('setSearchText', '');
    dispatch('resetSearchStore');
    dispatch('resetSearchStates');
    dispatch('ErrorStore/resetErrorStore', null, { root: true });
  },

  setSearchInProcess({ commit }, isInProcess) {
    commit('setSearchInProcess', isInProcess);
  },

  setFoundNothing({ commit }, foundNothing) {
    commit('setFoundNothing', foundNothing);
  },

  getLastSearchText({ commit }) {
    const lastSearchText = localStorageService.getDataFromLocalStorage(
      LocalStorageKeyEnum.SEARCH_TEXT
    );
    commit('setSearchText', lastSearchText);
  },

  setLastSearchText({ state }) {
    localStorageService.setDataIntoLocalStorage(
      LocalStorageKeyEnum.SEARCH_TEXT,
      state.searchText
    );
  },
};

const mutations = {
  setSearchText(state, searchText) {
    state.searchText = searchText || DEFAULT_SEARCH_TEXT;
  },
  setParsedQuery(state, parsedQuery) {
    state.parsedQuery = parsedQuery || DEFAULT_PARSED_QUERY;
  },
  setSearchInProcess(state, isInProcess) {
    state.searchInProcess = isInProcess || DEFAULT_SEARCH_IN_PROCESS;
  },
  setTotalPublications(state, value) {
    state.totalPublications = value || DEFAULT_TOTAL_VALUE;
  },
  setTotalHits(state, value) {
    state.totalHits = value || DEFAULT_TOTAL_VALUE;
  },

  setFoundNothing(state, foundNothing) {
    state.foundNothing = foundNothing || DEFAULT_FOUND_NOTHING;
  },
  setParsedResponse(state, parsedResponse) {
    state.parsedResponse = SearchFactory.createParsedResponse(parsedResponse);
  },
};

function handleError(error, dispatch) {
  log.info(error.message);
  dispatch('ErrorStore/setErrorMessage', error, { root: true });
}

export default {
  name: 'SearchStore',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
