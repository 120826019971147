//module search
export const setQueryInputtedForFilterButton =
  'setQueryInputtedForFilterButton';
export const setFirstVisiblePara = 'setFirstVisiblePara';

export const search_setFirstVisiblePara = 'search/setFirstVisiblePara';

//module elasticSearch

export const elasticSearch_getParagraphsByAccessStatus =
  'elasticSearch/getParagraphsByAccessStatus';
