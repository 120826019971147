<template>
  <span
    class="scroll-item"
    v-html="$sanitize(source.html)"
    dir="auto"
    @mousedown="handleMouseDown($event, source, index)"
    @mouseup="handleMouseUp($event, source, index)"
  ></span>
</template>

<script>
  import Vue from 'vue';
  import sanitizeHTML from 'sanitize-html';
  import Config from '@/configs/default.config.json';

  //TODO: move inside factory?
  Vue.prototype.$sanitize = dirty =>
    sanitizeHTML(dirty, {
      allowedTags: Config.sanitizeAllowedTags,
      allowedClasses: {
        span: Config.sanitizeAllowedClasses,
      },
      allowedAttributes: Config.sanitizeAllowedAttributes,
    });

  export default {
    name: 'ParagraphItem',
    props: {
      index: {
        type: Number,
      },
      source: {
        type: Object,
        default() {
          return {};
        },
      },
      handleMouseDown: {
        type: Function,
        default: () => {},
      },
      handleMouseUp: {
        type: Function,
        default: () => {},
      },
    },
  };
</script>

<style scoped></style>
