import stopwords from './stopwords';
import AggressiveTokenizer from './aggressive_tokenizer';

export default class Stemmer {
  constructor() {
    this.stem = token => token;
    this.stopWordList = stopwords;
  }

  addStopWord(stopWord) {
    return this.stopWordList.push(stopWord);
  }

  addStopWordList(moreStopWords) {
    this.stopWordList = this.stopWordList.concat(moreStopWords);
  }

  tokenizeAndStem(text, keepStops) {
    const stemmedTokens = [];

    new AggressiveTokenizer().tokenize(text).forEach(token => {
      if (keepStops || this.stopWordList.indexOf(token) === -1) {
        stemmedTokens.push(this.stem(token));
      }
    });

    return stemmedTokens;
  }

  attach() {
    const stemmer = {
      stem: this.stem,
      tokenizeAndStem: this.tokenizeAndStem,
    };

    String.prototype.stem = function() {
      return stemmer.stem(this);
    };

    String.prototype.tokenizeAndStem = function(keepStops) {
      return stemmer.tokenizeAndStem(this, keepStops);
    };
  }
}
