<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :aria-labelledby="iconName"
    :fill="iconColor"
    viewBox="0 0 24 24"
    role="presentation"
  >
    <use v-bind="{ 'xlink:href': `#${iconName}` }" />
  </svg>
</template>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'box',
      },
      width: {
        type: [Number, String],
        default: 20,
      },
      height: {
        type: [Number, String],
        default: 20,
      },
      iconColor: {
        type: String,
        default: 'currentColor',
      },
    },
  };
</script>
