const DEFAULT_IS_INPUT_FROM_VIRTUAL_KEYBOARD = false;
const DEFAULT_IS_BACKSPACE_PRESSED = false;
const DEFAULT_IS_COPY_PAST = false;

function _createInitialKeyboardState() {
  return {
    isInputFromVirtualKeyboard: DEFAULT_IS_INPUT_FROM_VIRTUAL_KEYBOARD,
    isBackspacePressed: DEFAULT_IS_BACKSPACE_PRESSED,
    isCopyPast: DEFAULT_IS_COPY_PAST,
  };
}

const state = _createInitialKeyboardState();

const getters = {
  getIsInputFromVirtualKeyboard: state => {
    return state.isInputFromVirtualKeyboard;
  },
  getIsBackspacePressed: state => {
    return state.isBackspacePressed;
  },
  getIsCopyPast: state => {
    return state.isCopyPast;
  },
};

const actions = {
  setIsInputFromVirtualKeyboard({ commit }, isInputFromVirtualKeyboard) {
    commit('setIsInputFromVirtualKeyboard', isInputFromVirtualKeyboard);
  },
  setIsBackspacePressed({ commit }, isBackspacePressed) {
    commit('setIsBackspacePressed', isBackspacePressed);
  },
  setIsCopyPast({ commit }, isCopyPast) {
    commit('setIsCopyPast', isCopyPast);
  },
  resetKeyboardStore({ commit }) {
    commit('setIsInputFromVirtualKeyboard');
    commit('setIsBackspacePressed');
    commit('setIsCopyPast');
  },
};

const mutations = {
  setIsInputFromVirtualKeyboard(state, isInputFromVirtualKeyboard) {
    state.isInputFromVirtualKeyboard =
      isInputFromVirtualKeyboard || DEFAULT_IS_INPUT_FROM_VIRTUAL_KEYBOARD;
  },
  setIsBackspacePressed(state, isBackspacePressed) {
    state.isBackspacePressed =
      isBackspacePressed || DEFAULT_IS_BACKSPACE_PRESSED;
  },
  setIsCopyPast(state, isCopyPast) {
    state.isCopyPast = isCopyPast || DEFAULT_IS_COPY_PAST;
  },
};

export default {
  getters,
  actions,
  mutations,
  state,
  namespaced: true,
  name: 'KeyboardStore',
};
