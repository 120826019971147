<template>
  <div
    id="sw-app"
    :class="{
      'rtl-lang': isTextRightSided,
      'dev-env': isDev,
      'brand-ocean': brand === 'ocean',
    }"
    :dir="getDirection"
  >
    <div class="icons-sprite">
      <BaseIconsSprite />
    </div>

    <div id="nav" />
    <div id="search-popup" @click="showPopup">
      <slot />
    </div>
    <Popup
      v-if="mainPopupOpened"
      :class="{ 'is-popup-displayed': mainPopupOpened }"
    />
  </div>
</template>

<script>
  import Vue from 'vue';
  import i18n from '@/i18n';
  import { mapState } from 'vuex';
  import store from '@/store/store';
  import config from '@/configs/default.config.json';

  import upperFirst from 'lodash/upperFirst';
  import camelCase from 'lodash/camelCase';

  import digits from '@/filters/digits';
  import offlineSearchService from '@/services/OfflineSearch/offlineSearch/OfflineSearchService';
  import FullTextSearchService from '@/services/Search/FullTextSearchService';
  import VueHotkey from 'v-hotkey';

  import provider from '@/provider.js';

  import Popup from '@/containers/popup/Popup';
  import BaseIconsSprite from '@/components/BaseIconsSprite';

  import * as log from 'loglevel';
  log.setLevel('error');

  Vue.use(VueHotkey);
  Vue.use(require('vue2-touch-events'));
  Vue.config.productionTip = false;
  Vue.config.devtools = true;
  Vue.filter('digits', digits);

  /*
   * Polyfills
   **/
  Vue.use(require('@webcomponents/webcomponentsjs'));

  const requireComponent = require.context(
    // The relative path of the components folder
    './components',
    // Whether or not to look in subfolders
    false,
    // The regular expression used to match base component filenames
    /Base[A-Z]\w+\.(vue|js)$/
  );

  requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName);

    // Get PascalCase name of component
    const componentName = upperFirst(
      camelCase(
        // Strip the leading `./` and extension from the filename
        fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
      )
    );
    // Register component globally
    Vue.component(
      componentName,
      // Look for the component options on `.default`, which will
      // exist if the component was exported with `export default`,
      // otherwise fall back to module's root.
      componentConfig.default || componentConfig
    );
  });

  export default {
    store,
    i18n,
    config,
    components: {
      Popup,
      BaseIconsSprite,
    },
    props: {
      profile: {
        type: String,
        default: config.parameters.brand,
      },
      isExtension: {
        type: Boolean,
        default: false,
      },
      language: {
        type: String,
        default: config.defaultLanguage,
      },
      interfaceLanguage: {
        type: String,
        default: config.defaultLanguage,
      },
      environment: {
        type: String,
        default: config.environments.development,
      },
      provider: {
        type: Object,
        default: () => {
          return provider;
        },
      },
      manager: {
        type: Object,
      },
    },
    computed: {
      ...mapState('HitsStore', ['activeHit']),
      ...mapState('ContextStore', [
        'env',
        'brand',
        'parameters',
        'lang',
        'mainPopupOpened',
      ]),
      ...mapState('SearchStore', ['parsedQuery']),
      isDev() {
        return this.env === config.environments.development;
      },
      getDirection() {
        return config.rtlLangs.indexOf(this.lang) !== -1 ? 'rtl' : 'ltr';
      },
      isTextRightSided() {
        return config.rtlLangs.indexOf(this.lang) !== -1;
      },
      isParametersInitiated() {
        return this.$store.getters['ContextStore/isParametersInitiated'];
      },
      isOnline() {
        return this.$store.getters['OfflineModeStore/getIsOnline'];
      },
    },
    watch: {
      profile: {
        immediate: true,
        handler(newVal, oldVal) {
          if (newVal !== oldVal) {
            this.$store.dispatch('ContextStore/setBrand', this.profile);
          }
        },
      },
      isExtension: {
        immediate: true,
        handler(newVal, oldVal) {
          if (newVal !== oldVal) {
            this.$store.dispatch(
              'ContextStore/setIsExtension',
              this.isExtension
            );
          }
        },
      },
      language: {
        immediate: true,
        handler(newVal, oldVal) {
          if (newVal !== oldVal) {
            const newLang = this.getLanguage();
            this.$store.dispatch('ContextStore/setLang', newLang);
          }
        },
      },
      interfaceLanguage: {
        immediate: true,
        handler(newVal, oldVal) {
          if (
            newVal !== oldVal &&
            config.interfaceLanguages.indexOf(newVal) !== -1
          ) {
            this.$i18n.locale = newVal;
          }
        },
      },
      environment: {
        immediate: true,
        handler(newVal, oldVal) {
          if (newVal !== oldVal) {
            this.$store.dispatch('ContextStore/setEnv', this.environment);
          }
        },
      },
      provider: {
        immediate: true,
        handler(newVal, oldValue) {
          if (newVal !== oldValue) {
            Object.assign(provider, newVal);
          }
        },
      },
      activeHit: {
        immediate: true,
        handler(newDoc, oldDoc) {
          if (newDoc !== oldDoc && typeof newDoc === 'object') {
            if (newDoc.bookMeta.urlRules.type === 'library') {
              this.setActiveHitCoverPath(newDoc.publicationId);
            }
          }
        },
      },
      'manager.openPopup'() {
        this.showPopup();
      },
      'manager.getDocByDocId'(params) {
        if (params.docId) {
          this.$_getDocByDocId(params.docId);
        }
      },
      'manager.isPartialAccess'(isPartialAccess) {
        this.$_setPartialAccess(isPartialAccess);
      },
      isOnline(currIsOnline, prevIsOnline) {
        if (
          currIsOnline &&
          !this.isParametersInitiated &&
          prevIsOnline !== null
        ) {
          this.init();
        }
      },
    },
    mounted() {
      window.addEventListener(
        'online',
        offlineSearchService.updateOnlineStatus
      );
      window.addEventListener(
        'offline',
        offlineSearchService.updateOnlineStatus
      );
      this.$el.focus();
    },
    async created() {
      await this.init();
    },
    methods: {
      async init() {
        try {
          if (process.env.NODE_ENV === config.environments.development) {
            this.$store.dispatch('ContextStore/setMainPopupOpened', true);
          }
          offlineSearchService.updateOnlineStatus();
          this.$store.dispatch('ContextStore/setEnv', this.environment);
          await this.$store.dispatch(
            'ContextStore/initRemoteConfigParameters',
            this.env
          );
          this.$store.dispatch('ContextStore/initConfigParameters');
          this.$store.dispatch('ContextStore/setBrand', this.profile);
          this.$store.dispatch('ContextStore/setLang', this.getLanguage());
          this.$store.dispatch('ContextStore/initSearch');
          this.$store.dispatch('FilterStore/removeLastUserFilter');
          this.$store.dispatch('HitsStore/removeLastActiveHit');
          this.customFontsUpload();
        } catch (error) {
          log.error(`[search widget]: init failed with ${error}`);
        }
      },
      $_getDocByDocId(docId) {
        FullTextSearchService.getDocById({ docId, language: this.lang }).then(
          data => {
            this.$emit('searchWidgetEvent', {
              type: 'getDocByDocId',
              data: {
                publication: data,
                query: this.parsedQuery,
              },
            });
          }
        );
      },

      $_setPartialAccess(isPartialAccess) {
        this.$store.dispatch('ContextStore/setPartialAccess', isPartialAccess);
      },

      showPopup() {
        setTimeout(() => {
          this.$el.querySelector('.search-field-wrapper input').focus();
        }); //TODO: '.search-field-wrapper input' is not consistent query
        this.$i18n.locale = this.interfaceLanguage;
        this.$store.dispatch('ContextStore/setMainPopupOpened', true);
      },

      getLanguage() {
        if (!config.defaultLanguages[this.language]) {
          return config.defaultLanguage;
        }
        return this.language;
      },

      customFontsUpload() {
        const fonts = ['Amiri'];
        const externalAppHost = this.parameters.appHost || './';

        fonts.forEach(fontName => {
          const style = document.createElement('style');
          style.innerHTML =
            '@font-face {' +
            'font-family: ' +
            fontName +
            '; ' +
            'src: url("' +
            externalAppHost +
            'assets/fonts/' +
            fontName +
            '.woff") format("woff");' +
            '}';
          const ref = document.head.querySelector('script');
          document.head.insertBefore(style, ref);
        });
      },

      async setActiveHitCoverPath(publicationId) {
        const coverPath = await provider.getCoverPath(publicationId);
        if (coverPath) {
          //TODO: Vue.set will b unavailable in Vue 3
          Vue.set(this.activeHit, 'coverPath', coverPath);
        }
      },
    },
  };
</script>

<style lang="less">
  @import './assets/styles/fonts.less';
  @import './App.less';
</style>
