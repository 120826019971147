<template>
  <div class="search-field-wrapper" @paste.prevent="transformPasteText">
    <slot>
      <BaseInput
        id="search-input"
        v-focus="focus.searchField"
        autofocus
        tabindex="0"
        :placeholder="$tc(placeholder)"
        @baseInputEvent="handleBaseInputEvent"
      />
    </slot>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import CopyService from '@/services/CopyService.js';
  import { focus } from 'vue-focus';
  import BaseInput from '@/components/BaseInput';
  import config from '@/configs/default.config.json';
  import EventEnum from '@/enums/EventEnum';
  import eventManager from '@/services/EventService';
  import {
    FOCUS_SELECTOR_HITS,
    FOCUS_SELECTOR_PARAGRAPHS,
    FOCUS_SELECTOR_SEARCH_FIELD,
  } from '@/constants/constants';

  let nextFocusElement;

  export default {
    name: 'SearchField',
    directives: {
      focus: focus,
    },
    components: {
      BaseInput,
    },
    computed: {
      ...mapState('ContextStore', ['brand', 'lang', 'focus']),
      ...mapState('OfflineModeStore', ['isOffline']),
      ...mapState('KeyboardStore', ['isCopyPast']),
      ...mapState('SearchStore', ['searchText']),
      placeholder() {
        let placeholder;
        if (this.brand === config.brands.ool) {
          placeholder = 'BaseInput.placeholderOOL';
        } else {
          placeholder = 'BaseInput.placeholder';
        }
        return placeholder;
      },
    },
    methods: {
      async handleBaseInputEvent(data) {
        switch (data.type) {
          case EventEnum.QUERY_INPUTTED:
            this.$store.dispatch(
              'SearchStore/setSearchText',
              data.payload.searchText
            );
            if (data?.payload?.searchText?.trim().length >= 3) {
              await this.$store.dispatch('SearchStore/performSearch', {
                newRequest: true,
              });
              this.$store.dispatch('FilterStore/performFilterSearch', true);
            } else {
              eventManager.publish('cancelPreviousRequests');
            }
            break;
          case EventEnum.BACKSPACE_PRESSED:
            this.$store.dispatch('KeyboardStore/setIsBackspacePressed', true);
            break;
          case EventEnum.IS_ERROR_OCCURRED:
            this.$store.dispatch('ErrorStore/setIsErrorOccurred', false);
            break;
          case EventEnum.QUERY_INPUTTED_AFTER_RELOAD:
            this.$store.dispatch(
              'SearchStore/setSearchText',
              data.payload.searchText
            );
            break;
          case EventEnum.SHIFT_TAB_PRESSED:
            this.setShiftTabPressed();
            break;
          case EventEnum.TAB_PRESSED:
            this.setTabPressed();
            break;
          case EventEnum.ENTER_PRESSED:
            this.setEnterPressed();
            this.setFakeBlur();
            break;
          case EventEnum.ON_FOCUS:
            this.setFocus();
            break;
          case EventEnum.ON_BLUR:
            this.setFakeBlur();
            break;
          case EventEnum.ON_DOUBLE_CLICK:
            this.selectQuery();
            break;
          default:
            break;
        }
      },

      selectQuery: function() {
        this.setFocus();
      },

      setFocus(selector) {
        if (selector) {
          this.$_changeFocus(selector);
          return;
        }

        if (!this.focus.searchField) {
          this.$_changeFocus(FOCUS_SELECTOR_SEARCH_FIELD);
        }
      },

      setShiftTabPressed() {
        nextFocusElement = FOCUS_SELECTOR_PARAGRAPHS;
      },

      setTabPressed() {
        nextFocusElement = FOCUS_SELECTOR_HITS;
      },

      setEnterPressed() {
        nextFocusElement = FOCUS_SELECTOR_HITS;
      },

      setFakeBlur() {
        this.$_changeFocus(nextFocusElement);
      },

      transformPasteText(e) {
        this.$store.dispatch('KeyboardStore/setIsCopyPast', true);

        const copyText = CopyService.getTransformedPasteText(
          e,
          this.searchText
        );
        this.$store.dispatch('SearchStore/setSearchText', copyText);
      },

      $_changeFocus(selector) {
        this.$store.dispatch('ContextStore/changeFocus', {
          selector,
        });
      },
    },
  };
</script>

<style lang="less">
  .search-field-wrapper {
    display: flex;
    flex-grow: 1;
  }
</style>
