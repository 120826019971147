import get from 'lodash/get';

import {
  FILTER_CATEGORY_AUTHORS,
  FILTER_CATEGORY_PUBLICATIONS,
  FILTER_CATEGORY_CATEGORIES,
} from '@/constants/constants';

import ParseResponseUtils from '@/services/ElasticSearch/ParseResponseUtils';

const parseInitSearchResponse = ({ responses }) => {
  return {
    searchInfo: get(responses[0], 'data', null),
    esInfoMap: responses[1] || null,
  };
};

const parseRemoteConfigResponse = ({ response }) => {
  return get(response, 'data', null);
};

const parseParagraphsResponse = ({ response }) => {
  const prevParagraphs = response[0];
  const nextParagraphs = response[1];
  return ParseResponseUtils.mergePrevNextPara(prevParagraphs, nextParagraphs);
};

const parseParagraphsToMapWithoutParaId = ({ response }) => {
  let currentParaNum = 1;
  if (response.data && response.data.hits) {
    response.data.hits.hits = response.data.hits.hits.map(rawDoc => {
      const doc = rawDoc._source;

      if (doc.sentenceNumber === 0) {
        currentParaNum += 1;
      }
      doc.paraId = 'para_' + currentParaNum;

      return rawDoc;
    });
    return parseParagraphsToMap({ response });
  }
};

const parseParagraphsToMap = ({ response }) => {
  const paragraphs = get(response, 'data.hits.hits', null);

  const paraMap = {};
  const paraIds = [];

  if (paragraphs) {
    paragraphs.forEach(paraDoc => {
      const highlightText = get(paraDoc, 'highlight.text', []);
      if (highlightText.length > 1) {
        throw new Error(
          `In creating fn parseParagraphsToMap get more then one highlight sentence, 
          need increase 'number_of_fragments' and 'fragment_size' in query 
          addHighlightQuery for get one sentence`
        );
      }
      const doc = paraDoc._source;
      if (highlightText[0]) {
        doc.text = highlightText[0];
      }
      const paraId = doc.paraId;
      if (!paraMap.hasOwnProperty(paraId)) {
        paraMap[paraId] = [];
      }
      paraMap[paraId].push(paraDoc._source);
      paraIds.push(paraId);
    });
  }

  paraIds.forEach(paraId => {
    paraMap[paraId].sort((sentA, sentB) => {
      return sentA.sentenceNumber - sentB.sentenceNumber;
    });
  });

  return paraMap;
};

const parseFilterResponse = ({ response }) => {
  const aggregations = get(response, 'data.aggregations', null);
  return {
    [FILTER_CATEGORY_PUBLICATIONS]: {
      total: get(aggregations, 'publicationsTotal.value', 0),
      list: ParseResponseUtils.parseFilterBucketsList(
        get(aggregations, 'publications.buckets', [])
      ),
    },
    [FILTER_CATEGORY_AUTHORS]: {
      total: get(aggregations, 'authorsTotal.value', 0),
      list: ParseResponseUtils.parseFilterBucketsList(
        get(aggregations, 'authors.buckets', [])
      ),
    },
    [FILTER_CATEGORY_CATEGORIES]: {
      total: get(aggregations, 'categoriesTotal.value', 0),
      list: ParseResponseUtils.parseFilterBucketsList(
        get(aggregations, 'categories.buckets', [])
      ),
    },
  };
};

const parseNavigationResponse = ({ response }) => {
  const hits = get(response, 'data.hits.hits', []);
  return hits.map(hit => hit._source);
};

const parseSingleHitResponse = ({ response }) => {
  return get(response, 'data.hits.hits[0]._source', {});
};

const parseHitsResponse = ({ response, supplementalSummary }) => {
  const sentences = get(response, 'data.hits.hits', []);
  const indexName = get(response, 'data.hits.hits[0]', {})._index;

  const stems = {};
  const quotes = {};

  let clusteredSentences = ParseResponseUtils.createClusteredHitsList(
    sentences
  );
  clusteredSentences = clusteredSentences.map(sentence => {
    const parsedSentence = { ...sentence._source };

    const summary = ParseResponseUtils.getSummaryByIndexName(
      supplementalSummary,
      indexName
    );

    const highlight = get(sentence, 'highlight.text', [
      parsedSentence.text,
    ]).join(' ... ');

    const quoteHighlight = get(sentence, 'highlight.["text.exact"]', [
      parsedSentence.text,
    ]).join(' ... ');

    const highlightedStems = ParseResponseUtils.getStems(highlight);
    const highlightedQuotes = ParseResponseUtils.getQuotes(quoteHighlight);

    ParseResponseUtils.addToMap(stems, highlightedStems);
    ParseResponseUtils.addToMap(quotes, highlightedQuotes);

    if (summary) {
      parsedSentence.type = 'Supplemental';
    }

    const sentenceHighlight =
      highlightedQuotes.length === 0 ? highlight : quoteHighlight;

    return {
      ...parsedSentence,
      ...{
        highlight: sentenceHighlight,
      },
    };
  });

  const quotesArr = Object.keys(quotes).map(function(quote) {
    return quote.split(/\s+/g).map(word => [word]);
  });
  const stemsArr = Object.keys(stems);

  return {
    indexName,
    totalPublications: get(
      response,
      'data.aggregations.totalPublications.value',
      0
    ),
    totalHits: get(response, 'data.hits.total.value', 0),
    sentencesList: {
      rows: clusteredSentences,
      stems: stemsArr,
      quotes: quotesArr,
      highlightRules: {
        stems: quotesArr.length !== 0,
        quotes: false,
      },
    },
  };
};

export default {
  parseInitSearchResponse,
  parseRemoteConfigResponse,
  parseParagraphsResponse,
  parseHitsResponse,
  parseSingleHitResponse,
  parseNavigationResponse,
  parseFilterResponse,

  parseParagraphsToMap,
  parseParagraphsToMapWithoutParaId,
};
