import maxBy from 'lodash/maxBy';
import property from 'lodash/property';
import some from 'lodash/some';

import {
  MIN_NUMBER_CHAR_META_DATA,
  MIN_META_LENGTH,
  MIN_QUOTES_NUMBER_CHAR,
} from '@/constants/constants';

function _isValidSearchByMeta(metaData) {
  const maxLengthMetaData = metaData?.length
    ? maxBy(metaData, property('length')).length
    : 0;

  return (
    maxLengthMetaData >= MIN_NUMBER_CHAR_META_DATA || metaData?.length === 0
  );
}

function _isValidStandAloneWords(standAloneWords) {
  return standAloneWords?.join('').length >= MIN_META_LENGTH;
}

function _isValidQuotesLength(quotes) {
  return some(quotes, quote => quote.length >= MIN_QUOTES_NUMBER_CHAR);
}

export default {
  validateQuery(queryText, lang, parsedQuery) {
    if (!_isValidSearchByMeta(parsedQuery.metaData)) {
      parsedQuery.validResp = false;
      return parsedQuery;
    }

    if (
      !_isValidStandAloneWords(parsedQuery?.standAloneWords) &&
      !_isValidStandAloneWords(parsedQuery?.phoneticWords) &&
      !_isValidQuotesLength(parsedQuery?.quotes)
    ) {
      parsedQuery.validResp = false;
      return parsedQuery;
    }
    parsedQuery.validResp = true;

    return parsedQuery;
  },
};
