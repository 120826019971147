'use strict';

class PublicationVisibilityStatuses {
  constructor() {
    this.enum = {
      DEFAULT: 'Default',
      AVAILABLE: 'Available',
      REGISTERED: 'Registered',
      NOT_AVAILABLE: 'NotAvailable',
      HIDDEN: 'Hidden',
      RESEARCH: 'Research',
      ENCUMBERED: 'Encumbered',
    };

    this.DEFAULT = this.enum.DEFAULT;
    this.AVAILABLE = this.enum.AVAILABLE;
    this.REGISTERED = this.enum.REGISTERED;
    this.NOT_AVAILABLE = this.enum.NOT_AVAILABLE;
    this.HIDDEN = this.enum.HIDDEN;
    this.RESEARCH = this.enum.RESEARCH;
    this.ENCUMBERED = this.enum.ENCUMBERED;

    this.labels = {
      [this.enum.DEFAULT]: 'PublicationVisibilityStatus.default.label',
      [this.enum.AVAILABLE]: 'PublicationVisibilityStatus.available.label',
      [this.enum.REGISTERED]: 'PublicationVisibilityStatus.registered.label',
      [this.enum.NOT_AVAILABLE]:
        'PublicationVisibilityStatus.notAvailable.label',
      [this.enum.HIDDEN]: 'PublicationVisibilityStatus.hidden.label',
      [this.enum.RESEARCH]: 'PublicationVisibilityStatus.research.label',
      [this.enum.ENCUMBERED]: 'PublicationVisibilityStatus.encumbered.label',
    };
  }

  getStatusesArray() {
    return [
      this.AVAILABLE,
      this.NOT_AVAILABLE,
      this.RESEARCH,
      this.ENCUMBERED,
      this.DEFAULT,
      this.REGISTERED,
    ];
  }
  getLabelByStatus(publicationStatus) {
    return this.labels[publicationStatus];
  }
  getAllLabelsMap() {
    return this.labels;
  }
  getEnum() {
    return this.enum;
  }
  inEnum(status) {
    const statusArray = this.getStatusesArray();
    return statusArray.includes(status);
  }
}

export default new PublicationVisibilityStatuses();
