import XRegExp from 'xregexp';
import arabicWordUtils from './arabicWordsUtils';
import arabicStopWords from './arabicStopWords';

const arWordre = new XRegExp('^[\\p{Arabic}]+$');

function isArabicWord(word) {
  return arWordre.test(word);
}

function isArabicWordList(wordList) {
  return wordList.every(isArabicWord);
}

const exp = {
  isArabicWordList: isArabicWordList,
};

function populatedExp() {
  [arabicWordUtils, arabicStopWords].forEach(module => {
    Object.keys(module).forEach(
      methodName => (exp[methodName] = module[methodName])
    );
  });

  return exp;
}

export default populatedExp;
