<template>
  <input
    v-model="searchInputValue"
    type="text"
    :placeholder="placeholder"
    name="searchInput"
    required
    :disabled="!isOnline"
    @dblclick="onDblClick"
    @focus="onFocus"
    @blur="onBlur"
    @keydown.exact.stop
    @keydown.shift.tab.exact="onShiftTabPressed"
    @keydown.tab.exact="onTabPressed"
    @keydown.enter.exact="onEnterPressed"
    @input="handleInput"
    ref="inputField"
  />
</template>

<script>
  import { mapState } from 'vuex';
  import EventEnum from '@/enums/EventEnum';

  export default {
    name: 'BaseInput',
    props: {
      placeholder: String,
    },
    data() {
      return {
        //for save last input after close/open popup and ability to clean in 10 min after last request
        searchInputValue: this.$store.getters['SearchStore/getSearchText'],
      };
    },
    computed: {
      ...mapState('OfflineModeStore', ['isOnline']),
      ...mapState('SearchStore', ['searchText']),
      ...mapState('ContextStore', ['lang']),
      ...mapState('KeyboardStore', [
        'isCopyPast',
        'isInputFromVirtualKeyboard',
      ]),
    },
    watch: {
      searchText(value) {
        if (this.searchInputValue !== value) {
          //for text inputted from virtual keyboard. It isn't visible in field
          this.searchInputValue = value;
        }
      },
      searchInputValue(value) {
        if (!value) {
          //input was deleted by pressing Backspace or Del
          this.$_emitBaseInputEvent('');
        } else if (this.isCopyPast || this.isInputFromVirtualKeyboard) {
          this.$store.dispatch(
            'KeyboardStore/setIsInputFromVirtualKeyboard',
            false
          );
          this.handleInput(); //for handle input after copy/past
        }
      },
    },

    mounted() {
      if (this.searchInputValue) {
        this.$emit('baseInputEvent', {
          type: EventEnum.QUERY_INPUTTED_AFTER_RELOAD,
          payload: {
            searchText: this.searchInputValue,
          },
        });
      }
    },

    methods: {
      handleInput(event) {
        if (!this.isOnline) {
          this.searchInputValue = null;
          return;
        }

        // for keyboard
        if (event && event.inputType === 'deleteContentBackward') {
          this.$emit('baseInputEvent', {
            type: EventEnum.BACKSPACE_PRESSED,
          });
        }
        const inputValue = event ? event.target.value : this.searchInputValue;
        this.$_emitBaseInputEvent(inputValue);
        this.$emit('baseInputEvent', {
          type: EventEnum.IS_ERROR_OCCURRED,
        });
      },
      onShiftTabPressed() {
        this.$emit('baseInputEvent', {
          type: EventEnum.SHIFT_TAB_PRESSED,
        });
      },
      onTabPressed() {
        this.$emit('baseInputEvent', {
          type: EventEnum.TAB_PRESSED,
        });
      },
      onEnterPressed() {
        this.$emit('baseInputEvent', {
          type: EventEnum.ENTER_PRESSED,
        });
      },
      onFocus() {
        this.$emit('baseInputEvent', {
          type: EventEnum.ON_FOCUS,
        });
      },
      onBlur() {
        this.$emit('baseInputEvent', {
          type: EventEnum.ON_BLUR,
        });
      },
      onDblClick() {
        this.$emit('baseInputEvent', {
          type: EventEnum.ON_DOUBLE_CLICK,
        });
      },
      $_emitBaseInputEvent(inputValue) {
        this.$emit('baseInputEvent', {
          type: EventEnum.QUERY_INPUTTED,
          payload: {
            searchText: inputValue,
          },
        });
      },
    },
  };
</script>
