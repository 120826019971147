import get from 'lodash/get';

import FilterFactory from '@/classes/factories/FilterFactory';
import FullTextSearchFactory from '@/classes/factories/queries/FullTextSearchFactory';

import FullTextSearchService from '@/services/Search/FullTextSearchService';
import ParseResponse from '@/services/ElasticSearch/ParseResponse';
import FilteringService from '@/services/ElasticSearch/FilteringService';

import {
  DISABLE_FILTER_AR_FA,
  DISABLE_FILTER_EN,
  FILTER_CATEGORY_CATEGORIES,
} from '@/constants/constants';

import localStorageService from '@/services/LocalStorageService';
import LocalStorageKeyEnum from '@/enums/LocalStorageKeyEnum';
import defaultConfig from '@/configs/default.config.json';

import * as log from 'loglevel';
log.setLevel('info');

const DEFAULT_FILTER_OPENED = false;
const DEFAULT_ACTIVE_FILTER_CATEGORY = null;

const state = _createInitialFilterState();

function _createInitialFilterState() {
  return {
    filterOpened: DEFAULT_FILTER_OPENED,
    activeFilterCategory: DEFAULT_ACTIVE_FILTER_CATEGORY,
    filter: FilterFactory.createFilter(),
  };
}

const getters = {
  getSearchFilter: state => {
    return state.activeFilterCategory === FILTER_CATEGORY_CATEGORIES
      ? { categories: state.filter.categories }
      : state.filter;
  },
  isFilterActive: (state, getters, rootState) => {
    let filterActiveCharsNum = DISABLE_FILTER_EN;
    const searchText = rootState.SearchStore.searchText;
    const lang = rootState.ContextStore.lang;
    const foundNothing = rootState.SearchStore.foundNothing;

    if (
      lang === defaultConfig.defaultLanguages.ar ||
      lang === defaultConfig.defaultLanguages.fa
    ) {
      filterActiveCharsNum = DISABLE_FILTER_AR_FA;
    }

    return !foundNothing && searchText?.trim().length >= filterActiveCharsNum;
  },
};

const actions = {
  fillInitialFilterStore({ commit }, payload) {
    commit('setInitialFilter', payload);
    commit('setActiveFilterCategory', null);
  },

  fillRegularFilterStore({ commit }, payload) {
    commit('setRegularFilter', payload);
  },

  resetFilterStore({ commit }) {
    commit('setFilterOpened');
    commit('setActiveFilterCategory');
    commit('setInitialFilter');
  },

  async resetUserFilter({ dispatch }) {
    dispatch('fillInitialFilterStore');
    await dispatch('SearchStore/performSearch', null, { root: true });
    dispatch('performFilterSearch', true);

    dispatch('removeLastUserFilter');
  },

  async performFilterSearch({ dispatch, rootGetters, getters }, isInitial) {
    try {
      if (!getters['isFilterActive']) {
        return;
      }

      const parsedQuery = rootGetters['SearchStore/getParsedQuery'];
      if (!parsedQuery.validResp) {
        log.info('Query is not valid');
        return;
      }
      const filterResponse = await dispatch('getFilter', isInitial);
      if (isInitial) {
        dispatch('fillInitialFilterStore', filterResponse);
      } else {
        dispatch('fillRegularFilterStore', filterResponse);
      }

      dispatch('removeLastUserFilter');
    } catch (error) {
      log.info(`performFilterSearch failed with ${error}`);
      throw error;
    }
  },

  /**
   * @returns {Promise<{
   *  categories: {total: *, list: *},
   *  publications: {total: *, list: *},
   *  authors: {total: *, list: *}}
   * >}
   */
  getFilter({ rootGetters }, isInitial) {
    try {
      const options = {
        parsedQuery: rootGetters['SearchStore/getParsedQuery'],
        language: rootGetters['ContextStore/getLang'],
        isInitial,
      };
      return getFilter(options);
    } catch (error) {
      log.info(`getFilter failed with ${error}`);
      throw error;
    }
  },

  setFilterOpened({ commit }, isOpened) {
    commit('setFilterOpened', isOpened);
  },

  setActiveFilterCategory({ commit }, activeFilterCategory) {
    const categoryName = get(activeFilterCategory, 'name', null);
    commit('setActiveFilterCategory', categoryName);
  },

  async setSubCategoryChecked({ commit, dispatch }, payload) {
    commit('setSubCategoryChecked', payload);
    await dispatch('SearchStore/performSearch', null, { root: true });
    if (payload.category === FILTER_CATEGORY_CATEGORIES) {
      await dispatch('performFilterSearch');
      commit('setCategoriesEnabledIfNeeded');
    }

    dispatch('setLastUserFilter');
  },

  getLastUserFilter({ commit }) {
    const lastUserFilter = localStorageService.getDataFromLocalStorage(
      LocalStorageKeyEnum.SEARCH_USER_FILTER
    );
    commit('setRegularFilter', lastUserFilter);
  },

  setLastUserFilter({ state }) {
    localStorageService.setDataIntoLocalStorage(
      LocalStorageKeyEnum.SEARCH_USER_FILTER,
      state.filter
    );
  },

  removeLastUserFilter() {
    localStorageService.removeDataFromLocalStorage(
      LocalStorageKeyEnum.SEARCH_USER_FILTER
    );
  },
};

const mutations = {
  setInitialFilter(state, filterSearchResponse) {
    state.filter = FilterFactory.createFilter(filterSearchResponse);
  },

  setRegularFilter(state, filterSearchResponse) {
    if (!filterSearchResponse) {
      return;
    }
    const filter = FilterFactory.createFilter(filterSearchResponse);
    state.filter = FilteringService.parseRegularFilterResponse({
      filterState: state.filter,
      filterNew: filter,
    });
  },

  setFilterOpened(state, isOpened) {
    state.filterOpened = isOpened || DEFAULT_FILTER_OPENED;
  },

  setActiveFilterCategory(state, activeFilterCategory) {
    state.activeFilterCategory =
      activeFilterCategory || DEFAULT_ACTIVE_FILTER_CATEGORY;
  },

  setSubCategoryChecked(state, payload) {
    FilteringService.parseFilterClick({
      filter: state.filter,
      activeFilterCategory: payload.category,
      selectedKey: payload.subCategory,
      checked: payload.checked,
    });
  },

  setCategoriesEnabledIfNeeded(state) {
    FilteringService.setCategoriesEnabledIfNeeded({ filter: state.filter });
  },
};

async function getFilter(options) {
  try {
    const query = FullTextSearchFactory.createFilterQueryParams(options);
    const response = await FullTextSearchService.getFilter(query);
    return ParseResponse.parseFilterResponse({ response });
  } catch (e) {
    log.info(`getFilter failed with ${e}`);
    return ParseResponse.parseFilterResponse({ response: {} });
  }
}

export default {
  name: 'FilterStore',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
