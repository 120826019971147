import { render, staticRenderFns } from "./Popup.vue?vue&type=template&id=fdb58c16&scoped=true&"
import script from "./Popup.vue?vue&type=script&lang=js&"
export * from "./Popup.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Popup.vue?vue&type=style&index=0&id=fdb58c16&scoped=true&lang=less&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "fdb58c16",
  null
  ,true
)

export default component.exports