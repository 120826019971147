<template>
  <div class="search-filter-wrapper" :class="{ dropped: activeFilterCategory }">
    <div v-click-outside.stop="hideDropDown" class="filter-categories">
      <div class="narrow-close-wrapper">
        <span>Advanced Search</span>
        <span class="close-filter-button" @click="closeFilter()">
          <BaseIcon icon-name="ico-close" />
        </span>
      </div>
      <ul>
        <li
          v-for="(category, key) in filterCategories"
          :key="key"
          :class="{
            active: isCategoryActive(category),
            'extra-wide': isCategoryExtraWide(category),
            disabled: !category.enabled,
          }"
          @click.stop.prevent="setActiveCategory(category)"
        >
          <div class="category-list-item" dir="auto">
            <span class="category-label">{{
              $tc('SearchFilter.Categories.' + category.name)
            }}</span>
            <span class="category-sep" />
            <span class="category-count">
              <span v-if="category.selectedCount > 0"
                >{{ category.selectedCount }} / </span
              >{{ category.totalCount }}</span
            >
            <BaseIcon icon-name="ico-arrow-chevron" />
          </div>
          <div v-if="isSubCategoryActive(category)" class="category-drop-down">
            <search-sub-filter :total="category.totalCount" />
          </div>
        </li>
      </ul>
      <div class="filter-control-block">
        <span class="clear-filter-button" @click="resetFilter()">
          <span class="label">{{
            $tc('SearchFilter.Buttons.clearFilter')
          }}</span>
        </span>
        <span class="close-filter-button" @click="closeFilter()">
          <BaseIcon icon-name="ico-close" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import debounce from 'lodash/debounce';

  import SearchSubFilter from '@/containers/searchsubfilter/SearchSubFilter.vue';

  import BaseIcon from '@/components/BaseIcon';

  import config from '@/configs/default.config.json';

  import ClickOutside from 'vue-click-outside';

  import {
    FILTER_CATEGORY_PUBLICATIONS,
    SW_NARROW,
  } from '@/constants/constants';

  export default {
    name: 'SearchFilter',
    components: {
      SearchSubFilter,
      BaseIcon,
    },
    directives: {
      ClickOutside: ClickOutside,
    },
    computed: {
      ...mapState('FilterStore', ['filter', 'activeFilterCategory']),

      filterCategories() {
        const self = this;
        return config.filterCategories.reduce(
          (categoriesMap, category, index) => {
            categoriesMap[index] = self.filter[category];
            return categoriesMap;
          },
          {}
        );
      },
    },
    methods: {
      setActiveCategory(category) {
        this.$store.dispatch('FilterStore/setActiveFilterCategory', category);
      },

      isCategoryActive(category) {
        return this.$_isCategoryActive(category) && category.totalCount > 0;
      },

      isCategoryExtraWide(category) {
        return category.name === FILTER_CATEGORY_PUBLICATIONS;
      },

      isSubCategoryActive(category) {
        return this.$_isCategoryActive(category);
      },

      $_isCategoryActive(category) {
        return category.name === this.activeFilterCategory;
      },

      resetFilter() {
        this.$store.dispatch('FilterStore/resetUserFilter');
      },

      closeFilter() {
        this.$store.dispatch('FilterStore/setFilterOpened', false);
      },

      getLocalizedNumber(number) {
        return this.$store.getters['ContextStore/getLocalizedNumber'](number);
      },

      hideDropDown: debounce(function(event) {
        if (
          (event &&
            event.target.className.indexOf('results-shadow-box') > -1) ||
          this.$el.offsetWidth < SW_NARROW
        ) {
          return false;
        }
        this.setActiveCategory(null);
      }, 100),
    },
  };
</script>

<style lang="less">
  @import 'SearchFilter';
</style>
