import * as log from 'loglevel';
log.setLevel('info');

import {
  SET_FIRST_VISIBLE_PARA,
  SET_QUERY_INPUTTED_FOR_FILTER_BUTTON,
} from '@/constants/mutation-types';
import {
  setFirstVisiblePara,
  setQueryInputtedForFilterButton,
} from '@/constants/actions-types';

export const namespaced = true;

export const state = {
  queryInputtedForFilterButton: null,
  firstVisiblePara: null,
};

export const mutations = {
  [SET_QUERY_INPUTTED_FOR_FILTER_BUTTON](state, payload) {
    state.queryInputtedForFilterButton = payload;
  },

  [SET_FIRST_VISIBLE_PARA](state, firstVisiblePara) {
    state.firstVisiblePara = firstVisiblePara;
  },
};

export const actions = {
  [setQueryInputtedForFilterButton]({ commit }, payload) {
    commit(SET_QUERY_INPUTTED_FOR_FILTER_BUTTON, payload);
  },

  [setFirstVisiblePara]({ commit }, firstVisiblePara) {
    commit(SET_FIRST_VISIBLE_PARA, firstVisiblePara);
  },
};
