const farsi = [
  '~\u06f1\u06f2\u06f3\u06f4\u06f5\u06f6\u06f7\u06f8\u06f9\u06f0-={Backspace:backspace}|' +
    '\u0636\u0635\u062b\u0642\u0641\u063a\u0639\u0647\u062e\u062d\u062c\u0686\\|' +
    '\u0634\u0633\u06cc\u0628\u0644\u0627\u062a\u0646\u0645\u06a9\u06af|' +
    '\u0638\u0637\u0632\u0631\u0630\u062f\u067e\u0648./{Shift:goto:1}|' +
    '{space}',
  'ّ\u00d7!@#$\ufdfc%^&*)(_+{Backspace:backspace}|' +
    '\u064b\u064c\u064d\u0631\u064a\u0627\u0644\u060c\u061b,][\\}}{{|' +
    '\u064e\u064f\u0650\u0651\u06c0\u0622\u0640\u00ab\u00bb:"|' +
    '||\u0629\u0626\u0698\u0624\u0625\u0623\u0621<>/{Shift:goto:0}|' +
    '{space}',
];

export default farsi;
