import { render, staticRenderFns } from "./SearchHitItem.vue?vue&type=template&id=c2ab123e&"
import script from "./SearchHitItem.vue?vue&type=script&lang=js&"
export * from "./SearchHitItem.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports