<template>
  <div>
    <div
      class="read-mode-search-popup"
      :class="{
        'expanded-view': expandedView,
        'expanded-infinite': expandedInfinite,
        'search-info-opened': infoOpened,
        'keyboard-opened': keyboardOpened,
        'multy-lang': multiLanguageBrand,
      }"
    >
      <div v-hotkey.self="keymap" class="read-mode-search-popup-wrapper">
        <TopBar data-test="top-bar" />
        <BottomBar v-show="showBottomBar" data-test="bottom-bar" />
        <BaseErrorWindow v-show="isErrorOccurred" data-test="error" />
      </div>
    </div>
    <div data-test="backdrop" class="popup-backdrop" @click="closePopup" />
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import defaultConfig from '@/configs/default.config';

  import TopBar from '@/containers/topbar/TopBar';
  import BottomBar from '@/containers/bottombar/BottomBar';
  import BaseErrorWindow from '@/components/BaseErrorWindow';

  import { FOCUS_SELECTOR_SEARCH_FIELD } from '@/constants/constants';

  export default {
    name: 'Popup',
    components: {
      TopBar,
      BottomBar,
      BaseErrorWindow,
    },
    computed: {
      ...mapState('ContextStore', [
        'brand',
        'isExtension',
        'mainPopupOpened',
        'keyboardOpened',
        'infoOpened',
        'focus',
      ]),
      ...mapState('SearchStore', ['searchText', 'foundNothing']),
      ...mapState('HitsStore', ['hits']),
      ...mapState('ParagraphsStore', ['paragraphs']),
      ...mapState('ErrorStore', ['isErrorOccurred']),

      showBottomBar() {
        return (
          this.infoOpened ||
          this.keyboardOpened ||
          !this.isErrorOccurred ||
          !this.searchText
        );
      },
      expandedInfinite() {
        return this.foundNothing;
      },
      multiLanguageBrand() {
        return defaultConfig.isBrandMultilanguage[this.brand];
      },
      expandedView() {
        return this.hits.length > 0 && this.paragraphs.length > 0;
      },
      keymap() {
        return {
          tab: () => {
            this.setPopupFocused({ direction: 1 });
          },
          'shift+tab': () => {
            this.setPopupFocused({ direction: -1 });
          },
          enter: () => {
            if (this.focus.hits) {
              this.setPopupFocused({ selector: FOCUS_SELECTOR_SEARCH_FIELD });
            }
          },
          esc: () => {
            this.$store.dispatch('ContextStore/setMainPopupOpened', false);
          },
        };
      },
    },
    methods: {
      closePopup() {
        this.$store.dispatch('ContextStore/setMainPopupOpened', false);
      },
      setPopupFocused(options) {
        this.$store.dispatch('ContextStore/changeFocus', options);
      },
    },
  };
</script>

<style scoped lang="less">
  @import '../../assets/styles/variables.less';

  @import 'Popup.less';
</style>
