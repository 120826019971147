export default {
  /* eslint-disable */
  getStopWordsList() {
    return {
      "کنند": null,
      "يک": null,
      "و": null,
      "در": null,
      "به": null,
      "از": null,
      "كه": null,
      "مي": null,
      "اين": null,
      "است": null,
      "را": null,
      "با": null,
      "هاي": null,
      "براي": null,
      "آن": null,
      "يك": null,
      "شود": null,
      "شده": null,
      "خود": null,
      "ها": null,
      "كرد": null,
      "شد": null,
      "اي": null,
      "تا": null,
      "كند": null,
      "بر": null,
      "بود": null,
      "گفت": null,
      "نيز": null,
      "وي": null,
      "هم": null,
      "كنند": null,
      "دارد": null,
      "ما": null,
      "كرده": null,
      "يا": null,
      "اما": null,
      "بايد": null,
      "دو": null,
      "اند": null,
      "هر": null,
      "خواهد": null,
      "او": null,
      "مورد": null,
      "آنها": null,
      "باشد": null,
      "ديگر": null,
      "مردم": null,
      "نمي": null,
      "بين": null,
      "پيش": null,
      "پس": null,
      "اگر": null,
      "همه": null,
      "صورت": null,
      "يكي": null,
      "هستند": null,
      "بي": null,
      "من": null,
      "دهد": null,
      "هزار": null,
      "نيست": null,
      "استفاده": null,
      "داد": null,
      "داشته": null,
      "راه": null,
      "داشت": null,
      "چه": null,
      "همچنين": null,
      "كردند": null,
      "داده": null,
      "بوده": null,
      "دارند": null,
      "همين": null,
      "ميليون": null,
      "سوي": null,
      "شوند": null,
      "بيشتر": null,
      "بسيار": null,
      "روي": null,
      "گرفته": null,
      "هايي": null,
      "تواند": null,
      "اول": null,
      "نام": null,
      "هيچ": null,
      "چند": null,
      "جديد": null,
      "بيش": null,
      "شدن": null,
      "كردن": null,
      "كنيم": null,
      "نشان": null,
      "حتي": null,
      "اينكه": null,
      "ولی": null,
      "توسط": null,
      "چنين": null,
      "برخي": null,
      "نه": null,
      "ديروز": null,
      "دوم": null,
      "درباره": null,
      "بعد": null,
      "مختلف": null,
      "گيرد": null,
      "شما": null,
      "گفته": null,
      "آنان": null,
      "بار": null,
      "طور": null,
      "گرفت": null,
      "دهند": null,
      "گذاري": null,
      "بسياري": null,
      "طي": null,
      "بودند": null,
      "ميليارد": null,
      "بدون": null,
      "تمام": null,
      "كل": null,
      "تر": null,
      "براساس": null,
      "شدند": null,
      "ترين": null,
      "امروز": null,
      "باشند": null,
      "ندارد": null,
      "چون": null,
      "قابل": null,
      "گويد": null,
      "ديگري": null,
      "همان": null,
      "خواهند": null,
      "قبل": null,
      "آمده": null,
      "اكنون": null,
      "تحت": null,
      "طريق": null,
      "گيري": null,
      "جاي": null,
      "هنوز": null,
      "چرا": null,
      "البته": null,
      "كنيد": null,
      "سازي": null,
      "سوم": null,
      "كنم": null,
      "بلكه": null,
      "زير": null,
      "توانند": null,
      "ضمن": null,
      "فقط": null,
      "بودن": null,
      "حق": null,
      "آيد": null,
      "وقتي": null,
      "اش": null,
      "يابد": null,
      "نخستين": null,
      "مقابل": null,
      "خدمات": null,
      "امسال": null,
      "تاكنون": null,
      "مانند": null,
      "تازه": null,
      "آورد": null,
      "فكر": null,
      "آنچه": null,
      "نخست": null,
      "نشده": null,
      "شايد": null,
      "چهار": null,
      "جريان": null,
      "پنج": null,
      "ساخته": null,
      "زيرا": null,
      "نزديك": null,
      "برداري": null,
      "كسي": null,
      "ريزي": null,
      "رفت": null,
      "گردد": null,
      "مثل": null,
      "آمد": null,
      "ام": null,
      "بهترين": null,
      "دانست": null,
      "كمتر": null,
      "دادن": null,
      "تمامي": null,
      "جلوگيري": null,
      "بيشتري": null,
      "ايم": null,
      "ناشي": null,
      "چيزي": null,
      "آنكه": null,
      "بالا": null,
      "بنابراين": null,
      "ايشان": null,
      "بعضي": null,
      "دادند": null,
      "داشتند": null,
      "برخوردار": null,
      "نخواهد": null,
      "هنگام": null,
      "نبايد": null,
      "غير": null,
      "نبود": null,
      "ديده": null,
      "وگو": null,
      "داريم": null,
      "چگونه": null,
      "بندي": null,
      "خواست": null,
      "فوق": null,
      "ده": null,
      "نوعي": null,
      "هستيم": null,
      "ديگران": null,
      "همچنان": null,
      "سراسر": null,
      "ندارند": null,
      "گروهي": null,
      "سعي": null,
      "روزهاي": null,
      "آنجا": null,
      "يكديگر": null,
      "كردم": null,
      "بيست": null,
      "بروز": null,
      "سپس": null,
      "رفته": null,
      "آورده": null,
      "نمايد": null,
      "باشيم": null,
      "گويند": null,
      "زياد": null,
      "خويش": null,
      "همواره": null,
      "گذاشته": null,
      "شش": null,
      "نداشته": null,
      "شناسي": null,
      "خواهيم": null,
      "آباد": null,
      "داشتن": null,
      "نظير": null,
      "همچون": null,
      "باره": null,
      "نكرده": null,
      "شان": null,
      "سابق": null,
      "هفت": null,
      "دانند": null,
      "جايي": null,
      "بی": null,
      "جز": null,
      "زیرِ": null,
      "رویِ": null,
      "سریِ": null,
      "تویِ": null,
      "جلویِ": null,
      "پیشِ": null,
      "عقبِ": null,
      "بالایِ": null,
      "خارجِ": null,
      "وسطِ": null,
      "بیرونِ": null,
      "سویِ": null,
      "کنارِ": null,
      "پاعینِ": null,
      "نزدِ": null,
      "نزدیکِ": null,
      "دنبالِ": null,
      "حدودِ": null,
      "برابرِ": null,
      "طبقِ": null,
      "مانندِ": null,
      "ضدِّ": null,
      "هنگامِ": null,
      "برایِ": null,
      "مثلِ": null,
      "بارة": null,
      "اثرِ": null,
      "تولِ": null,
      "علّتِ": null,
      "سمتِ": null,
      "عنوانِ": null,
      "قصدِ": null,
      "روب": null,
      "جدا": null,
      "کی": null,
      "که": null,
      "چیست": null,
      "هست": null,
      "کجا": null,
      "کجاست": null,
      "کَی": null,
      "چطور": null,
      "کدام": null,
      "آیا": null,
      "مگر": null,
      "چندین": null,
      "یک": null,
      "چیزی": null,
      "دیگر": null,
      "کسی": null,
      "بعری": null,
      "هیچ": null,
      "چیز": null,
      "جا": null,
      "کس": null,
      "هرگز": null,
      "یا": null,
      "تنها": null,
      "بلکه": null,
      "خیاه": null,
      "بله": null,
      "بلی": null,
      "آره": null,
      "آری": null,
      "مرسی": null,
      "البتّه": null,
      "لطفاً": null,
      "ّه": null,
      "انکه": null,
      "وقتیکه": null,
      "همین": null,
      "پیش": null,
      "مدّتی": null,
      "هنگامی": null,
      "مان": null,
      "تان": null
    }
  }
  /* eslint-enable */
};
