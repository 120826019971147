import get from 'lodash/get';

import HitsFactory from '@/classes/factories/HitsFactory';
import BookMetaFactory from '@/classes/factories/BookMetaFactory';
import FullTextSearchFactory from '@/classes/factories/queries/FullTextSearchFactory';

import FullTextSearchService from '@/services/Search/FullTextSearchService';
import ParseResponse from '@/services/ElasticSearch/ParseResponse';
import localStorageService from '@/services/LocalStorageService';

import LocalStorageKeyEnum from '@/enums/LocalStorageKeyEnum';

import { NUMBER_HITS_GROUPES } from '@/constants/constants';

import * as log from 'loglevel';
log.setLevel('error');

const DEFAULT_HITS = [];
const DEFAULT_ACTIVE_HIT = {
  bookMeta: BookMetaFactory.createBookMeta(),
};
const DEFAULT_LOAD_IN_PROCESS = false;
const DEFAULT_START_INDEX = 0;
const DEFAULT_HITS_UPLOADS_COUNT = 1;

const state = _createInitialSearchHitsState();

function _createInitialSearchHitsState() {
  return {
    uploadsCount: 0,
    hits: DEFAULT_HITS,
    hitsUploadsCount: DEFAULT_HITS_UPLOADS_COUNT,
    activeHit: DEFAULT_ACTIVE_HIT,
    loadHitsInProcess: DEFAULT_LOAD_IN_PROCESS,
  };
}

const getTotalLoadedGroups = hitsUploadsCount => {
  const extraHitsGroupCount = 1;
  return hitsUploadsCount * (NUMBER_HITS_GROUPES - extraHitsGroupCount);
};

const getters = {
  getHits: state => {
    return state.hits;
  },

  getActiveHit: state => {
    return state.activeHit;
  },

  isAllHitsUploaded: (state, getters, rootState) => {
    const totalPublications = rootState.SearchStore.totalPublications;
    const totalLoadedGroups = getTotalLoadedGroups(state.hitsUploadsCount);
    return totalPublications <= totalLoadedGroups;
  },
};

const actions = {
  fillHitsStore({ commit, dispatch, state }, hitsSearchResponse) {
    commit('setHits', hitsSearchResponse);
    commit('setActiveHit', state.hits[0]);
    dispatch('setLastActiveHit', state.hits[0]);
    commit('setLoadHitsInProcess');
  },

  resetHitsStore({ commit }) {
    commit('setHits');
    commit('setActiveHit');
    commit('setLoadHitsInProcess');
    commit('setHitsUploadsCount');
  },

  async performSearchMoreHits({ commit, dispatch, state }) {
    const startIndex = getTotalLoadedGroups(state.hitsUploadsCount) - 1;
    const hitsSearchResponse = await dispatch('getHits', startIndex);
    commit('setMoreHits', hitsSearchResponse);
    commit('setHitsUploadsCount', state.hitsUploadsCount + 1);
  },

  /**
   * @returns {Promise}
   */
  async getHits({ rootGetters, commit }, startIndex) {
    try {
      commit('setLoadHitsInProcess', true);

      const language = rootGetters['ContextStore/getLang'];
      const parsedQuery = rootGetters['SearchStore/getParsedQuery'];

      const hitsSearchOptions = {
        language,
        startIndex: startIndex || DEFAULT_START_INDEX,
        parsedQuery,
      };
      return await getHits(hitsSearchOptions);
    } finally {
      commit('setLoadHitsInProcess', false);
    }
  },

  /**
   * @returns {Promise}
   */
  getHitById({ rootGetters }, docId) {
    try {
      const options = {
        docId,
        language: rootGetters['ContextStore/getLang'],
      };
      return getHitById(options);
    } catch (error) {
      log.error(`getHitById failed with ${error}`);
    }
  },

  async setActiveHit({ commit, dispatch, state }, activeHit) {
    const isNewCluster =
      state.activeHit?.publicationId !== activeHit?.publicationId;

    commit('setActiveHit', activeHit);
    dispatch('setLastActiveHit', activeHit);

    if (isNewCluster || activeHit?.firstGroupItem) {
      await dispatch('NavigationStore/performNavigationSearch', activeHit, {
        root: true,
      });
    }
    dispatch('NavigationStore/setNavigationIndex', activeHit.id, {
      root: true,
    });
    await dispatch('ParagraphsStore/performParagraphsSearch', activeHit, {
      root: true,
    });
  },

  async setActiveHitById({ dispatch, commit, state }, docId) {
    let hit = await dispatch('getHitById', docId);

    if (hit) {
      hit.bookMeta = state.activeHit.bookMeta;
      hit = HitsFactory.createHitItem(hit);
      commit('setActiveHit', hit);
      dispatch('setLastActiveHit', hit);

      await dispatch('ParagraphsStore/performParagraphsSearch', hit, {
        root: true,
      });
      dispatch('NavigationStore/setNavigationIndex', hit.id, {
        root: true,
      });
    }
  },

  getLastActiveHit({ commit }) {
    const lastActiveHit = localStorageService.getDataFromLocalStorage(
      LocalStorageKeyEnum.SEARCH_ACTIVE_HIT
    );
    commit('setActiveHit', lastActiveHit);
  },

  setLastActiveHit({ state }) {
    localStorageService.setDataIntoLocalStorage(
      LocalStorageKeyEnum.SEARCH_ACTIVE_HIT,
      state.activeHit
    );
  },

  removeLastActiveHit() {
    localStorageService.removeDataFromLocalStorage(
      LocalStorageKeyEnum.SEARCH_ACTIVE_HIT
    );
  },
};

const mutations = {
  setHits(state, hitsSearchResponse) {
    state.hits = hitsSearchResponse
      ? prepareHits(hitsSearchResponse)
      : DEFAULT_HITS;
  },

  setActiveHit(state, activeHit) {
    state.activeHit = activeHit || DEFAULT_ACTIVE_HIT;
  },

  setMoreHits(state, hitsSearchResponse) {
    const hits = prepareHits(hitsSearchResponse);
    const mergedUniqueHits = [...state.hits];
    hits.forEach(hit => {
      if (
        mergedUniqueHits.findIndex(
          uHit => uHit.publicationId === hit.publicationId
        ) === -1
      ) {
        mergedUniqueHits.push(hit);
      }
    });
    state.hits = mergedUniqueHits;
  },

  setLoadHitsInProcess(state, loadHitsInProcess) {
    state.loadHitsInProcess = loadHitsInProcess || DEFAULT_LOAD_IN_PROCESS;
  },
  setHitsUploadsCount(state, hitsUploadsCount) {
    state.hitsUploadsCount = hitsUploadsCount || DEFAULT_HITS_UPLOADS_COUNT;
  },
};

async function getHits(options) {
  try {
    const query = FullTextSearchFactory.createHitQueryParams(options);
    const response = await FullTextSearchService.getHits(query);
    return ParseResponse.parseHitsResponse({ response });
  } catch (error) {
    throw new Error(`Get hits failed with ${error}`);
  }
}

function getHitById(options) {
  const query = FullTextSearchFactory.createDocByIdQueryParams(options);
  return FullTextSearchService.getDocById(query);
}

function prepareHits(hitsSearchResponse) {
  const hits = get(hitsSearchResponse, 'sentencesList.rows', DEFAULT_HITS);
  return hits.map(hit => {
    return HitsFactory.createHitItem(hit);
  });
}

export default {
  name: 'HitsStore',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
