import each from 'lodash/each';

import TextUtils from './dom-utils/text-utils';
import Highlighter from './highlighter';

const textUtilsInst = new TextUtils();
const highlighterInst = new Highlighter();

export default class SearchHighlighter {
  constructor() {}

  /**
   *
   * @param {Array.<string>} words
   * @param {Element} element
   * @param {string} decoratorClassName
   */
  decorateSearchWords(words, element, decoratorClassName) {
    const preparedText = textUtilsInst.extractContent(element);
    const searchTermsRealOffsets = textUtilsInst.locateSearchTermsInText(
      words,
      preparedText
    );
    const searchTermsStableOffsets = textUtilsInst.turnIntoStableOffsets(
      searchTermsRealOffsets,
      preparedText
    );

    highlighterInst.decorateStableOffsets(
      searchTermsStableOffsets,
      element,
      decoratorClassName
    );
  }

  /**
   * @param  {Array.<string>} quotes
   * @param  {Element} element
   * @param  {string} decoratorClassName
   */
  decorateSearchQuotes(quotes, element, decoratorClassName) {
    const preparedText = textUtilsInst.extractContent(element);
    let quotesRealOffsets = [];
    each(quotes, function(quote) {
      [].push.apply(
        quotesRealOffsets,
        textUtilsInst.searchQuoteRealOffsets(preparedText, quote)
      );
    });
    quotesRealOffsets = textUtilsInst.normalizingOverLoops(quotesRealOffsets);
    const searchTermsStableOffsets = textUtilsInst.turnIntoStableOffsets(
      quotesRealOffsets,
      preparedText
    );

    highlighterInst.decorateStableOffsets(
      searchTermsStableOffsets,
      element,
      decoratorClassName
    );
  }

  decorateSearchWordsByOffsets(stableOffsets, element, decoratorClassName) {
    highlighterInst.decorateStableOffsets(
      stableOffsets,
      element,
      decoratorClassName
    );
  }

  decorateSearchQuotesByOffsets(stableOffsets, element, decoratorClassName) {
    const quotesRealOffsets = textUtilsInst.normalizingOverLoops(stableOffsets);

    highlighterInst.decorateStableOffsets(
      quotesRealOffsets,
      element,
      decoratorClassName
    );
  }
}
