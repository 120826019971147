const META_MARKER = 'data-meta';

/**
 *
 * @param {Element} element
 * @param {string} selector
 * @returns {boolean}
 * @private
 */
export let _is = (element, selector) => {
  _is =
    'matches' in element
      ? (element, selector) => element.matches(selector)
      : (element, selector) => element.msMatchesSelector(selector);

  return _is(element, selector);
};

export default class MarkerUtils {
  constructor() {}

  getParagraphElementsInRange(startParagraph, endParagraph) {
    const paragraphElements = [];
    let currentElement = startParagraph;
    // assertion block
    while (currentElement !== endParagraph) {
      if (this.isContent(currentElement)) {
        paragraphElements.push(currentElement);
      }
      currentElement = this._getParagraphByDirection(currentElement, false);
      if (currentElement === null) {
        throw new Error(
          'Failed to collect paragraphs in range:' +
            ' startParagraph [' +
            startParagraph.id +
            '],' +
            ' endParagraph [' +
            endParagraph.id +
            ']'
        );
      }
    }
    paragraphElements.push(endParagraph);
    return paragraphElements;
  }

  getParagraphById(id, paragraphContainer) {
    if (paragraphContainer === undefined) {
      paragraphContainer = window.document.documentElement;
    }
    if (id.indexOf('para_') === 0) {
      throw new Error('Attempt to use prefixed id ' + id);
    }
    return paragraphContainer.querySelector('[data-sw-id=para_' + id + ']');
  }

  _getParagraphByDirection(paragraph, isDesc) {
    const paragraphs = document.querySelectorAll('[data-sw-id^=para_]');
    const i = Array.prototype.findIndex.call(
      paragraphs,
      a => a.id === paragraph.id
    );

    return paragraphs[i + (isDesc ? -1 : 1)] || null; // if we on last para and try move forward or if we on first para and try move back
  }
  /**
   *
   * @param {Element} element
   * @returns {boolean}
   */
  isMeta(element) {
    return _is(element, this.getMetaElementSelector());
  }

  /**
   *
   * @returns {string}
   */
  getMetaElementSelector() {
    return '[' + META_MARKER + ']';
  }
}
