class BookMeta {
  constructor(data = {}) {
    this.accessStatus = data.accessStatus;
    this.author = data.author;
    this.language = data.language;
    this.priority = data.priority;
    this.paraId = data.paraId;
    this.publicationId = data.publicationId;
    this.title = data.title;
    this.totalResults = data.totalResults;
    this.type = data.type;
    this.urlRules = data.urlRules;
    this.weight = data.weight;
    this.locator = data.locator || '0.0.0';
    this.sourceUrl = data.sourceUrl;
  }
}

class BookMetaBuilder {
  setAccessStatus(accessStatus) {
    this.accessStatus = accessStatus;
    return this;
  }

  setAuthor(author) {
    this.author = author;
    return this;
  }

  setLanguage(language) {
    this.language = language;
    return this;
  }

  setPriority(priority) {
    this.priority = priority;
    return this;
  }

  setParaId(paraId) {
    this.paraId = paraId;
    return this;
  }

  setPublicationId(publicationId) {
    this.publicationId = publicationId;
    return this;
  }

  setTitle(title) {
    this.title = title;
    return this;
  }

  setTotalResults(totalResults) {
    this.totalResults = totalResults;
    return this;
  }

  setType(type) {
    this.type = type;
    return this;
  }

  setUrlRules(urlRules) {
    this.urlRules = urlRules || {};
    return this;
  }

  setWeight(weight) {
    this.weight = weight;
    return this;
  }

  setLocator(locator) {
    this.locator = locator;
    return this;
  }

  setSourceUrl(sourceUrl) {
    this.sourceUrl = sourceUrl;
    return this;
  }

  build() {
    return new BookMeta(this);
  }
}

const createBookMeta = (data = {}) => {
  return new BookMetaBuilder()
    .setAccessStatus(data.accessStatus)
    .setAuthor(data.author)
    .setLanguage(data.language)
    .setPriority(data.priority)
    .setParaId(data.paraId)
    .setPublicationId(data.publicationId)
    .setTitle(data.title)
    .setTotalResults(data.totalResults)
    .setType(data.type)
    .setUrlRules(data.urlRules)
    .setWeight(data.weight)
    .setLocator(data.locator)
    .setSourceUrl(data.sourceUrl)
    .build();
};

export default {
  createBookMeta,
};
