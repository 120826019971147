import * as log from 'loglevel';
log.setLevel('error');

export default {
  setDataIntoLocalStorage(key, data) {
    const value = data === undefined ? null : data;
    window.localStorage.setItem(key, JSON.stringify(value));
  },

  getDataFromLocalStorage(key) {
    const data = window.localStorage.getItem(key);
    try {
      return JSON.parse(data);
    } catch (e) {
      log.error(`${key} didn't retrieve from localstorage with error ${e}`);
      return null;
    }
  },

  removeDataFromLocalStorage(key) {
    window.localStorage.removeItem(key);
  },
};
