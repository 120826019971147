import { UNSET_METHOD } from '@/constants/constants';

class Provider {
  constructor() {}

  async getCoverPath() {
    return await UNSET_METHOD;
  }

  async openPublication() {
    return await UNSET_METHOD;
  }

  async changeSearchLanguage() {
    return await UNSET_METHOD;
  }
}

export default new Provider();
