import { render, staticRenderFns } from "./BookIcon.vue?vue&type=template&id=6f27084d&scoped=true&"
import script from "./BookIcon.vue?vue&type=script&lang=js&"
export * from "./BookIcon.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "6f27084d",
  null
  ,true
)

export default component.exports