<template>
  <div class="keyboard" dir="ltr">
    <div data-test="closeKeyboard" @click="closeKeyboard">
      <span class="icon-container">
        <BaseIcon icon-name="ico-close" />
      </span>
    </div>
    <keyboard v-model="input" :layouts="getLayout" @input="onChange" />
  </div>
</template>

<script>
  /**
   * Keyboard use 'vue-keyboard' node module (https://github.com/MartyWallace/vue-keyboard)
   * In case you need additional language layout for keyboard, just add in layouts folder file
   * with language name (example: arabic.js), insert there corresponding data and include link on layout
   * in 'langToLayoutMap' object.
   */
  import { mapState } from 'vuex';

  import Keyboard from 'vue-keyboard';

  import BaseIcon from '@/components/BaseIcon';
  import arabic from './layouts/arabic.js';
  import farsi from './layouts/farsi.js';
  import portuguese from './layouts/portuguese';

  import { FOCUS_SELECTOR_SEARCH_FIELD } from '@/constants/constants';

  const langToLayoutMap = {
    fa: farsi,
    ar: arabic,
    en: arabic, //should be change if english layout needed
    pt: portuguese,
  };

  export default {
    components: {
      Keyboard,
      BaseIcon,
    },
    data() {
      return {
        input: '',
      };
    },
    computed: {
      ...mapState('ContextStore', ['lang']),
      ...mapState('SearchStore', ['searchText']),
      getLayout() {
        return langToLayoutMap[this.lang];
      },
    },

    watch: {
      searchText: {
        handler: function(text) {
          if (this.input !== text) {
            this.input = text;
          }
        },
      },
    },
    created() {
      this.setSearchFieldFocused();
    },

    destroyed() {
      this.setSearchFieldFocused();
    },

    methods: {
      async onChange(searchTextFromKeyboard) {
        this.$store.dispatch(
          'KeyboardStore/setIsInputFromVirtualKeyboard',
          true
        );
        this.$store.dispatch(
          'SearchStore/setSearchText',
          searchTextFromKeyboard
        );
        this.setSearchFieldFocused();
      },

      closeKeyboard() {
        this.$store.dispatch('ContextStore/setKeyboardOpened', false);
      },

      setSearchFieldFocused() {
        const options = { selector: FOCUS_SELECTOR_SEARCH_FIELD };
        this.$store.dispatch('ContextStore/changeFocus', options);
      },
    },
  };
</script>

<style lang="less">
  @import 'Keyboard.less';
</style>
