import SearchStemsWrapperService from '@/services/SearchStemsWrapperService';
import store from '@/store/store';

class Paragraph {
  constructor(data) {
    this.id = data.id;
    this.title = data.title;
    this.html = data.html;
  }
}

class ParagraphBuilder {
  setId(id) {
    this.id = id;
    return this;
  }

  setTitle(title) {
    this.title = title;
    return this;
  }

  setHtml(paragraph, contentArray) {
    const parsedQuery = store.getters['SearchStore/getParsedQuery'];
    const activeHit = store.getters['HitsStore/getActiveHit'];

    contentArray.forEach(contentItem => {
      if (activeHit && activeHit.id === contentItem.docId) {
        contentItem.text = SearchStemsWrapperService.wrapTextBySentenceDecorator(
          contentItem.text
        );
      }
    });

    paragraph.text = concatParaContentArray(contentArray);

    let html = SearchStemsWrapperService.createHTML(
      paragraph.text,
      paragraph.paragraphs,
      paragraph.paraId,
      paragraph.tag,
      paragraph
    );

    if (parsedQuery?.quotes?.length) {
      html = SearchStemsWrapperService.highlightSearchResultInSentence(
        html,
        parsedQuery.quotesWords[0],
        parsedQuery.quotesQuery[0]
      );
    }
    this.html = html;
    return this;
  }

  build() {
    return new Paragraph(this);
  }
}

const getParagraphBuilder = () => {
  return new ParagraphBuilder();
};

const createParagraph = (paragraph, contentArray) => {
  return new ParagraphBuilder()
    .setId(paragraph.paraId)
    .setTitle(paragraph.title)
    .setHtml(paragraph, contentArray)
    .build();
};

//TODO: move to according utils
function concatParaContentArray(contentArray) {
  return contentArray.reduce((result, contentItem, index) => {
    if (index !== 0) {
      result += ' ';
    }
    result += contentItem.text;
    return result;
  }, '');
}

export default {
  getParagraphBuilder,
  createParagraph,
};
