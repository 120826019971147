import Vue from 'vue';
import VueI18n from 'vue-i18n';
import pluginChecker from '@/utils/pluginChecker';

import config from './configs/default.config.json';
import de from '../locales/de.json';
import es from '../locales/es.json';
import fr from '../locales/fr.json';
import it from '../locales/it.json';
import pt from '../locales/pt.json';
import ru from '../locales/ru.json';
import ua from '../locales/ua.json';
import en from '../locales/en.json';
import fa from '../locales/fa.json';
import ar from '../locales/ar.json';

const il8nConfig = {
  locale: config.defaultLanguage, // global variable holding the local language value
  fallbackLocale: config.defaultLanguage, // global variable holding fallback language value
  messages: { de, es, fr, it, pt, ru, ua, en, fa, ar }, // global variable holding translations by specified languages
};

let _VueI18n = pluginChecker.getPluginIfInstalled('VueI18n');

if (!_VueI18n) {
  _VueI18n = VueI18n;
  Vue.use(VueI18n);
}

const i18n = new _VueI18n(il8nConfig);

export default i18n;
