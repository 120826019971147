export const AR_PHONETIC_DICTIONARY = {
  a: ['ا', 'ة', ''],
  b: ['ب'],
  c: ['تش', 'چ‎'],
  d: ['د', 'ض', 'ذ'],
  e: ['ا', 'ي', 'ـيه', 'ع', ''],
  f: ['ف'],
  g: ['ج', 'ق', 'غ'],
  h: ['ه', 'ح', 'خ', ''],
  i: ['ي', ''],
  j: ['ج'],
  k: ['ك', 'خ'],
  l: ['ل'],
  m: ['م'],
  n: ['ن'],
  o: ['و', ''],
  p: ['پ‎'],
  q: ['ق'],
  r: ['ر'],
  s: ['ث', 'ش', 'س', 'ص'],
  t: ['ث', 'ت', 'ة', 'ط'],
  u: ['و', ''],
  v: ['و', 'ڤ‎', 'ڥ‎'],
  w: ['و', 'ڤ‎', 'ڥ‎'],
  x: [''],
  y: ['ي', ''],
  z: ['ذ', 'ظ', 'ز'],
  ʻ: ['ع'],
  ʿ: ['ع'],
  '‘': ['ع'],
  '`': ['ع'],
  ʼ: ['ء', 'أ', 'آ', 'إ', 'ئ', 'ؤ'],
  ʾ: ['ء', 'أ', 'آ', 'إ', 'ئ', 'ؤ'],
  '’': ['ء', 'أ', 'آ', 'إ', 'ئ', 'ؤ'],
  "'": ['ع', 'ء', 'أ', 'آ', 'إ', 'ئ', 'ؤ'],
  combination: {
    dh: ['ذ'],
    th: ['ث'],
    sh: ['ش'],
    ch: ['ش', 'تش', 'چ‎'],
    kh: ['خ'],
    gh: ['غ'],
    tsh: ['تش', 'چ‎'],
    ts: ['تش', 'چ‎'],
    tch: ['تش', 'چ‎'],
    ei: ['ي', 'ـيه'],
    ai: ['ي', 'ـيه'],
    eh: ['ي', 'ـيه'],
    eih: ['ي', 'ـيه'],
    aih: ['ي', 'ـيه'],
    ee: ['ي'],
    ou: ['و'],
    oo: ['و'],
  },
};

export const BULLIT =
  '\\u064b\\u064c\\u064d\\u064e\\u064f\\u0650\\u0651\\u0652\\u0653\\u0670';

export const SHADDA =
  '\\ufc5e\\ufc5f\\ufc60\\ufc61\\ufc62\\ufc63\\ufe70\\ufe72\\ufe76\\ufe74\\ufe78\\ufe7A\\ufe7c\\ufe7e';

export const KASHIDA =
  '\\ufcf2\\ufcf3\\ufcf4\\ufe71\\ufe77\\ufe79\\ufe7B\\ufe7D\\ufe7f';

export const SOUND_EX_CODES = {
  ف: 1,
  ب: 1,
  خ: 2,
  ج: 2,
  ز: 2,
  س: 2,
  ص: 2,
  ظ: 2,
  ق: 2,
  ك: 2,
  ت: 3,
  ث: 3,
  د: 3,
  ذ: 3,
  ض: 3,
  ط: 3,
  ل: 4,
  م: 5,
  ن: 5,
  ر: 6,
  b: 1,
  f: 1,
  p: 1,
  v: 1,
  w: 1,
  c: 2,
  g: 2,
  j: 2,
  k: 2,
  q: 2,
  s: 2,
  x: 2,
  z: 2,
  d: 3,
  t: 3,
  l: 4,
  m: 5,
  n: 5,
  r: 6,
};
