<template>
  <ul class="languages-list">
    <li
      v-for="(lang, index) in langs"
      :key="lang"
      data-test="change-language"
      :class="{ active: isActive(index) }"
      @click.stop.prevent="changeSearchLang(index)"
    >
      <span>{{ lang }}</span>

      <span class="icon-container">
        <BaseIcon icon-name="ico-check" />
      </span>
    </li>
  </ul>
</template>

<script>
  import { mapState } from 'vuex';

  import BaseIcon from '@/components/BaseIcon';

  import config from '@/configs/default.config.json';

  import {
    OOL,
    PORTUGUESE,
    FOCUS_SELECTOR_SEARCH_FIELD,
  } from '@/constants/constants';

  import provider from '@/provider.js';

  export default {
    name: 'Languages',
    component: {
      BaseIcon,
    },
    data() {
      return {
        langs: (config.languages || []).map(lang => {
          if (this.brand === OOL && lang === PORTUGUESE) {
            return;
          }
          return this.$i18n.tc('Languages.' + lang);
        }),
      };
    },
    computed: {
      ...mapState('ContextStore', ['brand', 'lang']),
    },
    methods: {
      async changeSearchLang(index) {
        this.$store.dispatch('ContextStore/changeFocus', {
          selector: FOCUS_SELECTOR_SEARCH_FIELD,
        });

        this.$store.dispatch('SearchStore/totalReset');

        await this.$store.dispatch(
          'ContextStore/setLang',
          config.languages[index]
        );
        return provider.changeSearchLanguage(config.languages[index]);
      },
      isActive(index) {
        return config.languages[index] === this.lang;
      },
    },
  };
</script>

<style lang="less">
  @import 'Languages.less';
</style>
