<template>
  <div class="tooltip-popup" v-bind:class="[vAlignment, hAlignment, className]">
    <i class="arrow" />
    <div class="tooltip-popup-content">
      <slot />
    </div>
  </div>
</template>

<script>
  /**
   * props {Object}
   * props.vAlignment {String} - vertical alignment | top, bottom or center
   * props.hAlignment {String} - horizontal alignment | left, right or center
   * props.className {String}
   */

  export default {
    name: 'BaseTooltip',
    props: {
      vAlignment: String,
      hAlignment: String,
      className: String,
    },
  };
</script>

<style lang="less">
  @import '../assets/styles/variables.less';

  .tooltip-popup {
    position: relative;
    display: flex;
    justify-content: flex-end;
    z-index: 3;
  }

  .tooltip-popup {
    .tooltip-popup-content {
      box-shadow: 0 7px 8px -1px fade(@c-black, 10%);
      background-color: var(--color-main-bg);
      border-bottom: 1px solid var(--color-gray);
      color: var(--color-main-font);
      text-align: center;
      border-radius: 6px;
      position: absolute;
      top: 68px;
    }

    i {
      position: absolute;
      bottom: 0;
      width: 24px;
      height: 12px;
      overflow: hidden;
      margin-bottom: -11px;
      margin-inline-start: -5px;

      &:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        left: 50%;
        transform: translate(-50%, 50%) rotate(45deg);
        background-color: var(--color-main-bg);
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
      }
    }

    .icon-container {
      margin-inline-end: 5px;

      svg {
        fill: var(--color-lilac);
      }
    }
  }
</style>
