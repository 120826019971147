import config from '../configs/default.config.json';

const fakeClipboardElementId = 'fake-clipboard';

export default {
  /**
   * @param {Object} options
   * @param options.paragraphs
   * @param options.author
   * @param options.title
   * @param options.externalLink
   */
  copyToClipboard(options) {
    removeFakeClipboardElement();

    const fakeClipboardElement = addFakeClipboardElement(options);
    setSelection(fakeClipboardElement);

    document.execCommand('copy');
  },

  /**
   * @param {Object} e
   * @param {String} searchText
   * @return transformed copyText
   */
  getTransformedPasteText(e, searchText) {
    e.preventDefault();
    searchText = searchText || '';

    const input = e.target;
    const pattern = /\n(.*)/g;

    let copyText = window.clipboardData
      ? window.clipboardData.getData('text')
      : e.clipboardData.getData('text/plain');

    copyText = copyText.replace(pattern, '');
    const selectedWordLength = document.getSelection().toString().length;
    const selectionOffset = getSelectionOffset(input);
    const searchTextArray = searchText.split('');

    if (isWholeTextSelected(input)) {
      return copyText;
    }
    //if only one word is selected
    if (selectedWordLength && selectedWordLength < searchText.length) {
      searchTextArray.splice(selectionOffset.start, selectedWordLength);
    }

    const start = searchTextArray.slice(0, selectionOffset.start);
    const middle = start.concat(copyText);
    let newStr = middle.join('');
    const end = searchTextArray.slice(selectionOffset.start);

    if (end.length !== 0) {
      const lastChar = end.pop();
      for (let i = 0; i < end.length; i++) {
        newStr += end[i];
      }
      newStr += lastChar;
    }

    return newStr;
  },
};

function addFakeClipboardElement(options) {
  const fakeElement = document.createElement('textarea');

  fakeElement.id = fakeClipboardElementId;
  fakeElement.value = createClipboardValue(options);
  fakeElement.contentEditable = true;
  fakeElement.readOnly = false;
  fakeElement.style.position = 'absolute';
  fakeElement.style.left = '-9999px';

  document.body.appendChild(fakeElement);
  return fakeElement;
}

function removeFakeClipboardElement() {
  const fakeElement = document.querySelector('#' + fakeClipboardElementId);
  if (fakeElement) {
    fakeElement.remove();
  }
}

function setSelection(el) {
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  if (iOS) {
    const range = document.createRange();
    range.selectNodeContents(el);

    const s = window.getSelection();
    s.removeAllRanges();
    s.addRange(range);

    el.setSelectionRange(0, 999999);
  } else {
    el.select();
  }
}

function getSelectionOffset(element) {
  return {
    start: element.selectionStart,
    end: element.selectionEnd,
  };
}

function createClipboardValue(options) {
  let selection = window.getSelection().toString();
  if (options.author && options.title) {
    selection +=
      `\n\n` +
      config.copyTextSeparator +
      `\n` +
      options.author +
      `, "` +
      options.title +
      `"`;
  }

  if (options.paragraphs) {
    selection += `, ` + options.paragraphs;
  }
  if (options.externalLink) {
    selection += `\n` + options.externalLink;
  }
  return selection;
}

function isWholeTextSelected(input) {
  if (typeof input.selectionStart == 'number') {
    return (
      input.selectionStart === 0 && input.selectionEnd === input.value.length
    );
  } else if (typeof document.selection != 'undefined') {
    input.focus();
    return document.selection.createRange().text === input.value;
  }
}
