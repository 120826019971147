<template>
  <div
    class="virtual-list-wrapper"
    :class="{ 'scrolling-on': isScrolling }"
    @click="setFocus()"
    v-hotkey="keymap"
  >
    <virtual-list
      v-focus="focus.hits"
      class="virtual-list"
      :data-sources="hits"
      :data-key="'id'"
      :data-component="searchHitItem"
      :extra-props="{
        activeHitIndex: activeHitIndex,
        hitItemClick: handleHitItemEvent,
      }"
      :keeps="120"
      :remain="50"
      :start="scrollStartIndex"
      v-on:scroll="onScroll"
      tabindex="100"
      ref="virtualList"
      v-show="isHitsVisible"
      item-class="list-item"
    ></virtual-list>
  </div>
</template>

<script>
  import get from 'lodash/get';
  import { mapState } from 'vuex';
  import { focus } from 'vue-focus';
  import virtualList from 'vue-virtual-scroll-list';

  import SearchHitItem from '@/containers/searchhits/SearchHitItem';

  import EventEnum from '@/enums/EventEnum';

  let onScrollTimeout;
  let currentDocSetTimeout;
  let hitsReadyTimeoutFn;

  const SCROLL_TIMEOUT = 300;
  const DEFAULT_ACTIVE_HIT_INDEX = 0;
  const DEFAULT_SCROLL_START_INDEX = 0;
  const HITS_READY_TIMEOUT = 50;

  import { FOCUS_SELECTOR_HITS } from '@/constants/constants';

  export default {
    name: 'SearchHits',
    directives: {
      focus: focus,
    },
    components: {
      'virtual-list': virtualList,
    },
    data() {
      return {
        searchHitItem: SearchHitItem,
        envConfig: {},
        isScrolling: false,
        activeHitIndex: DEFAULT_ACTIVE_HIT_INDEX,
        scrollStartIndex: DEFAULT_SCROLL_START_INDEX,
      };
    },
    computed: {
      ...mapState('HitsStore', ['hits', 'activeHit', 'loadHitsInProcess']),
      ...mapState('ContextStore', [
        'brand',
        'hitsActive',
        'focus',
        'mainPopupOpened',
      ]),
      isHitsVisible() {
        return this.hits && this.hits.length;
      },
      isAllHitsUploaded() {
        return this.$store.getters['HitsStore/isAllHitsUploaded'];
      },
      keymap() {
        return {
          up: () => {
            if (this.focus && this.focus.hits) {
              this.$_navigate({ isUp: true });
            }
          },
          down: () => {
            if (this.focus && this.focus.hits) {
              this.$_navigate({ isUp: false });
            }
          },
        };
      },
    },
    watch: {
      hits: {
        handler: function(newHits, oldHits) {
          const newFirstHit = get(newHits, '[0]', {});
          const oldFirstHit = get(oldHits, '[0]', {});

          if (newFirstHit.id !== oldFirstHit.id) {
            this.activeHitIndex = DEFAULT_ACTIVE_HIT_INDEX;

            this.$nextTick(() => {
              this.$_scrollToIndex(DEFAULT_SCROLL_START_INDEX);
            });
          }
        },
      },
      activeHit: {
        handler: function() {
          const index = this.$_getActiveHitIndex();
          this.$_setActiveHitIndex(this.activeHit, index);
        },
      },
      mainPopupOpened: {
        immediate: true,
        handler(isOpened) {
          if (!isOpened || !this.activeHit) {
            return;
          }
          this.$_setActiveHit(this.activeHit);

          const index = this.$_getActiveHitIndex();
          this.$_setActiveHitIndex(this.activeHit, index);

          clearTimeout(hitsReadyTimeoutFn);
          hitsReadyTimeoutFn = setTimeout(() => {
            this.$_scrollToActiveHit();
          }, HITS_READY_TIMEOUT);
        },
      },
    },
    methods: {
      handleHitItemEvent({ type, data }) {
        switch (type) {
          case EventEnum.HIT_ITEM_CLICKED:
            this.$_setActiveHit(data.source);
            this.$_toggleHitsActive();
            this.$_setActiveHitIndex(this.activeHit, data.index);
            break;
          default:
            break;
        }
      },

      $_navigate(options) {
        let activeIndex;
        if (options.isUp) {
          activeIndex =
            this.activeHitIndex > 0
              ? this.activeHitIndex - 1
              : this.activeHitIndex;
        } else {
          activeIndex =
            this.activeHitIndex < this.hits.length - 1
              ? this.activeHitIndex + 1
              : this.activeHitIndex;
        }

        clearTimeout(currentDocSetTimeout);
        currentDocSetTimeout = setTimeout(() => {
          this.$_setActiveHit(this.hits[activeIndex]);
          this.$_setActiveHitIndex(this.hits[activeIndex], activeIndex);
          this.$nextTick(() => {
            this.$_updateScrollPosition(options);
          });
        }, SCROLL_TIMEOUT);
      },

      $_updateScrollPosition(options) {
        const hit = this.$el.querySelectorAll(
          '.search-results-item-block.active'
        )[0];
        if (hit) {
          const containerRect = this.$el.getBoundingClientRect();
          const hitRect = hit.getBoundingClientRect();
          if (
            hitRect.top > containerRect.top &&
            hitRect.bottom < containerRect.bottom
          ) {
            return;
          }
          hit.scrollIntoView(options.isUp);
        } else if (options.isUp) {
          this.scrollStartIndex = this.activeHitIndex;
        }
      },

      onScroll(e, data) {
        const container = this.$el.querySelectorAll('.virtual-list')[0];
        const containerHeight = container.getBoundingClientRect().height;
        const self = this;
        self.isScrolling = true;

        clearTimeout(onScrollTimeout);

        onScrollTimeout = setTimeout(() => {
          self.isScrolling = false;
        }, SCROLL_TIMEOUT);

        if (
          !this.isAllHitsUploaded &&
          !this.loadHitsInProcess &&
          data.padBehind - data.padFront <= containerHeight * 7
        ) {
          this.$_loadMore();
        }
      },

      $_setActiveHitIndex(hit, index) {
        if (index === null || !hit || !this.activeHit) {
          return;
        }

        if (hit.id === this.activeHit.id) {
          this.activeHitIndex = index;
        }
      },

      $_getActiveHitIndex() {
        if (!this.activeHit) {
          return null;
        }
        let index;
        if (!this.activeHit.firstGroupItem) {
          index = this.hits.findIndex(hit => hit.id === this.activeHit.id);
        }

        return index >= 0 ? index : null;
      },

      async $_setActiveHit(hit) {
        try {
          await this.$store.dispatch('HitsStore/setActiveHit', hit);
        } catch (e) {
          this.$store.dispatch('ErrorStore/setIsErrorOccurred', true);
          this.$store.dispatch('ErrorStore/setErrorMessage', e);
        }
      },

      $_setActiveHitById(hit) {
        this.$store.dispatch('HitsStore/setActiveHitById', hit);
      },

      $_loadMore() {
        this.$store.dispatch('HitsStore/performSearchMoreHits');
      },

      setFocus() {
        this.$store.dispatch('ContextStore/changeFocus', {
          selector: FOCUS_SELECTOR_HITS,
        });
      },

      $_scrollToIndex(index) {
        if (this.$refs.virtualList) {
          this.$refs.virtualList.scrollToIndex(index);
        }
      },

      $_scrollToActiveHit() {
        const scrollElement = this.$el.querySelector('.virtual-list');
        const selectionElement = this.$el.querySelector('.active');

        if (scrollElement && selectionElement) {
          selectionElement.scrollIntoView(true);
        }
      },

      $_toggleHitsActive() {
        this.$store.dispatch('ContextStore/setHitsActive', !this.hitsActive);
      },
    },
  };
</script>

<style lang="less">
  @import 'SearchHits.less';
</style>
