/**
 * Store Config is a dummy Store with real Store structure.
 * It should be used in Unit Testing to set data needed to
 * proper work and testing .vue components, containers, e.t.c.
 * Each module, defined in config, has its own actions and mutations.
 *
 * @type {{namespaced: boolean, state: {}, mutations: {}, actions: {}}}
 */

import * as searchModule from './modules/search';

import NavigationStore from '@/store/modules/NavigationStore';
import FilterStore from '@/store/modules/FilterStore';
import HitsStore from '@/store/modules/HitsStore';
import ParagraphsStore from '@/store/modules/ParagraphsStore';
import SearchStore from '@/store/modules/SearchStore';
import ContextStore from '@/store/modules/ContextStore';
import OfflineModeStore from './modules/OfflineModeStore';
import KeyboardStore from './modules/KeyboardStore';
import ErrorStore from './modules/ErrorStore';

const storeConfig = {
  modules: {
    search: searchModule,

    NavigationStore,
    FilterStore,
    HitsStore,
    ParagraphsStore,
    SearchStore,
    ContextStore,
    OfflineModeStore,
    KeyboardStore,
    ErrorStore,
  },
};

export default storeConfig;
