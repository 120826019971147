<template>
  <div class="search-sub-filter-wrapper">
    <div
      class="narrow-back-button-wrapper"
      @click.stop.prevent="closeSubcategories"
    >
      <BaseIcon icon-name="ico-arrow-chevron" />
      <span
        >{{ $tc('SearchFilter.Categories.' + activeFilterCategory) }} ({{
          filter[activeFilterCategory].totalCount
        }})</span
      >
    </div>
    <div class="active-sub-filter-categories active-full">
      <ul>
        <li
          v-for="(listItem, name) in categoryListChecked(true)"
          :key="name"
          @click.stop.prevent="handleChecked(name, false)"
        >
          <label>
            <input
              v-model="listItem.checked"
              type="checkbox"
              :class="{ checked: listItem.checked }"
            />
            <span>
              <span class="chech-mark"
                ><BaseIcon icon-name="ico-arrow-chevron"
              /></span>
              <span>{{ name }} ({{ getCounter(listItem) }})</span>
            </span>
          </label>
        </li>
      </ul>
      <ul>
        <li
          v-for="(listItem, name) in categoryListActive()"
          :key="name"
          @click.stop.prevent="handleChecked(name, true)"
        >
          <label>
            <input
              v-model="listItem.checked"
              type="checkbox"
              :class="{ checked: listItem.checked }"
            />
            <span>
              <span class="chech-mark"></span>
              <span>{{ name }} ({{ getCounter(listItem) }})</span>
            </span>
          </label>
        </li>
      </ul>
    </div>
    <ul class="rest-sub-filter-categories">
      <li
        v-for="(listItem, name) in categoryListRest()"
        :key="name"
        @click.stop.prevent="handleChecked(name, true)"
      >
        <label>
          <input
            v-model="listItem.checked"
            type="checkbox"
            :class="{ checked: listItem.checked }"
          />
          <span>
            <span class="chech-mark"></span>
            <span>{{ name }} ({{ getCounter(listItem) }})</span>
          </span>
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import pickBy from 'lodash/pickBy';
  import get from 'lodash/get';

  import BaseIcon from '@/components/BaseIcon';

  import { FILTER_CATEGORY_PUBLICATIONS } from '@/constants/constants';

  export default {
    name: 'SearchSubFilter',
    components: {
      BaseIcon,
    },
    computed: {
      ...mapState('FilterStore', ['filter', 'activeFilterCategory']),

      categoryList() {
        return get(this.filter, `[${this.activeFilterCategory}].list`, []);
      },
    },
    methods: {
      handleChecked(key, isChecked) {
        this.$store.dispatch('FilterStore/setSubCategoryChecked', {
          category: this.activeFilterCategory,
          subCategory: key,
          checked: isChecked,
        });
      },

      categoryListChecked() {
        return pickBy(this.categoryList, cat => cat.checked);
      },

      categoryListActive() {
        return pickBy(this.categoryList, cat => !cat.checked && cat.active);
      },

      categoryListRest() {
        return pickBy(this.categoryList, cat => !cat.checked && !cat.active);
      },

      closeSubcategories() {
        this.$store.dispatch('FilterStore/setActiveFilterCategory', null);
      },

      getCounter(listItem) {
        return this.activeFilterCategory === FILTER_CATEGORY_PUBLICATIONS
          ? listItem.count
          : listItem.related.count;
      },
    },
  };
</script>

<style lang="less">
  @import 'SearchSubFilter';
</style>
