import farsiWords from './farsiWordsUtils';
import farsiStopWords from './farsiStopWords';

export const exp = {};

function populatedExp() {
  [farsiWords, farsiStopWords].forEach(module => {
    Object.keys(module).forEach(
      methodName => (exp[methodName] = module[methodName])
    );
  });

  return exp;
}

export default populatedExp;
