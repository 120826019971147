import store from '@/store/store.js';

class OfflineSearchService {
  updateOnlineStatus() {
    const isOnline = navigator.onLine;
    store.dispatch('OfflineModeStore/setIsOnline', isOnline);
    return isOnline;
  }
}

const offlineSearchService = new OfflineSearchService();

export default offlineSearchService;
