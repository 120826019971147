class ParsedResponse {
  constructor(data = {}) {
    this.quotes = data.quotes || [];
    this.stems = data.stems || [];
  }
}

class ParsedResponseBuilder {
  setStems(stems) {
    this.stems = stems;
    return this;
  }

  setQuotes(quotes) {
    this.quotes = quotes;
    return this;
  }

  build() {
    return new ParsedResponse(this);
  }
}

const createParsedResponse = (data = {}) => {
  return new ParsedResponseBuilder()
    .setQuotes(data.quotes)
    .setStems(data.stems)
    .build();
};

export default {
  createParsedResponse,
};
