const portuguese = [
  '1234567890{Backspace:backspace}|' +
    'qwe\u00E9\u00EArtyu\u00FA\u00FCi\u00EDo\u00F3\u00F4\u00F5p|' +
    'a\u00E0\u00E1\u00E2\u00E3sdfghjkl\u00E7|{shift:goto:1}zxcvbnm|{space}',
  '!@#$%^&*(){Backspace:backspace}|' +
    'QWE\u00C9\u00CARTYU\u00DA\u00DCI\u00CDO\u00D3\u00D4\u00D5P|' +
    'A\u00C0\u00C1\u00C2\u00C3SDFGHJKL\u00C7|{shift:goto:0}ZXCVBNM|{space}',
];

export default portuguese;
