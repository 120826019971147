import each from 'lodash/each';
import map from 'lodash/map';
import compact from 'lodash/compact';
import { MIN_META_LENGTH } from '@/constants/constants';

import languageUtils from '@/utils/languageUtils';
import unidecode from 'unidecode';
import FilteringService from '@/services/ElasticSearch/FilteringService.js';

const getQuotePart = (query, quotesOpt) => {
  //quotes „“«»“”‘’‹›"'
  let quote;
  const openQuotes = quotesOpt.openQuotes;
  const closeQuotes = quotesOpt.closeQuotes;
  const quoteRe = new RegExp('[' + openQuotes + '][^' + openQuotes + closeQuotes + ']*[' + closeQuotes + ']', 'gi');// eslint-disable-line
  quote = query.match(quoteRe);
  if (quote && quote[0] === '""') {
    quote = null;
  }
  return quote || [];
};

const getMetaData = query => {
  //(effendi,abd)
  const letters = query.split('');
  const firstOpenParenthesisIndex = letters.indexOf('(');
  const lastOpenParenthesisIndex = letters.lastIndexOf(')');
  const metaData = Array.of(
    letters
      .slice(firstOpenParenthesisIndex, lastOpenParenthesisIndex + 1)
      .join('')
  );

  return metaData;
};

const getFilter = (filter, leftDashRe) => {
  if (!filter) {
    return null;
  }
  let _filter = JSON.parse(JSON.stringify(filter));
  _filter = FilteringService.getParsedFilterQuery({ filter: _filter });

  Object.keys(_filter).forEach(category => {
    const subCategories = [];
    _filter[category].forEach(subCategory => {
      subCategories.push(subCategory.replace(leftDashRe, '').trim());
    });
    _filter[category] = subCategories;
  });
  return _filter;
};

const getBlackListWords = query => {
  // -word1 -word2 or word1- word2-
  const blackListRaw = query.split(/\s+/);
  const blackList = [];
  each(blackListRaw, function(notWord) {
    notWord = notWord.trim();
    if (notWord[0] === '-') {
      blackList.push(notWord.substring(1));
    } else if (notWord[notWord.length - 1] === '-') {
      blackList.push(notWord.substring(0, notWord.length - 1));
    }
  });
  return blackList;
};

const cleanQuery = (parts, query) => {
  each(parts, word => {
    query = query.replace(word, '');
  });
  return query;
};

function parseQuery(_query, options, lang, filter) {
  let quotes, metaData, query;

  let standAloneWords = [];
  let phoneticWords = [];
  const metaQueryParts = [];
  const quotesQuery = [];
  let phraseWithStopWords = '';

  query = _query.trim();
  quotes = getQuotePart(query, options.quotes); // options.quotes must be escaped for create regExp
  if (quotes.length === 1 && quotes[0] === ' ') {
    query = cleanQuery(quotes, query);
  }
  // const phoneticRe = new RegExp('\\w+');
  const quoteWrapRe = new RegExp('(^[' + options.quotes.openQuotes + ']|[' + options.quotes.closeQuotes + ']$)', 'g');// eslint-disable-line
  const leftDashRe = new RegExp('(^-|\\s-)', 'g');
  const quoteRe = new RegExp('[' + options.quotes.openQuotes + options.quotes.closeQuotes + ']+', 'g'); // eslint-disable-line
  quotes = map(quotes, quote => {
    quote = quote.replace(quoteWrapRe, '').replace(leftDashRe, '');

    return quote;
  });
  if (quotes.length && quotes[0] !== ' ') {
    query = query.replace(quoteRe, '');
  }
  const quotesWords = [];
  quotes.forEach(quote => {
    const quoteQueryWords = [];
    const quotesWord = [];
    let dashWords = [];
    quote
      .trim()
      .toLowerCase()
      .split(/\s+/)
      .forEach(word => {
        languageUtils.tokenizing(word, lang).forEach(token => {
          quoteQueryWords.push(token);
        });
        quotesWord.push([word]);
        if (word.indexOf('-') !== -1) {
          dashWords = word.split('-').map(dashWord => {
            return [dashWord];
          });
        }
      });
    quotesWords.push(quotesWord);
    quotesWords.push(dashWords);
    quotesQuery.push(quoteQueryWords);
  });

  metaData = getMetaData(query);
  query = cleanQuery(metaData, query);

  const blackList = getBlackListWords(query);
  query = cleanQuery(blackList, query);

  query = query.trim();
  /* eslint-disable */
    if (metaData.length !== 0) {
      metaData = map(metaData[0].replace(/^[\(\)]+|[\(\)]+$/, '').replace(/[\(\)]+/, ' ').split(','), meta => {
        meta.replace(leftDashRe, '').trim();
        meta = languageUtils.tokenizing(meta, lang).join(' ');

        return meta;
      }).filter(meta => meta.length >= MIN_META_LENGTH);
    } else {
      metaData = [];
    }


    let _filter = getFilter(filter, leftDashRe);

    /* eslint-enable */
  standAloneWords = languageUtils.tokenizing(query, lang);

  if (lang === 'ar' || lang === 'fa') {
    phoneticWords = languageUtils.tokenizing(query, 'en').map(unidecode);
    phoneticWords = [].concat.apply(
      [],
      Object.values(languageUtils.generateWords(phoneticWords))
    );
  }

  phraseWithStopWords = standAloneWords.join(' ');

  [].push.apply(metaQueryParts, blackList);
  [].push.apply(metaQueryParts, metaData);
  query = cleanQuery(metaQueryParts, _query);
  const queryWords = query.split(/\s+/);
  standAloneWords = languageUtils.filterByStopWords(
    compact(standAloneWords),
    lang
  );

  return {
    quotes,
    quotesQuery,
    quotesWords,
    blackList,
    metaData,
    standAloneWords,
    queryWords,
    phoneticWords,
    filter: _filter,
    phraseWithStopWords,
  };
}

export default parseQuery;
