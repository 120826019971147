import XRegExp from 'xregexp';
import config from '@/configs/default.config.json';

import {
  FRAGMENT_SIZE,
  MAX_BOOST,
  MIN_BOOST,
  MAX_SLOP,
  INNER_HITS_COUNT,
  NUMBER_WORDS_FOR_START_FUZZYNESS,
  FILTER_ITEMS_SIZE,
} from '@/constants/constants';

const BOOK_PRIORITY = MAX_BOOST * 0.4;
const EXACT_BOOST = MAX_BOOST - BOOK_PRIORITY;
const complicatedNamesRe = new XRegExp(
  "(?:([’'`-])[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]\\1)|(?:^|[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF])[’'`-]+(?:[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]|$)"
);

const createMustQuery = (...args) => {
  const [standAloneWord] = args;
  return {
    match: {
      text: {
        query: standAloneWord,
      },
    },
  };
};

const getMustNotQuery = (...args) => {
  const [blackList] = args;
  return blackList.map(createMustQuery);
};

/**
 * create query for phonetic search
 */
const createFuzzyMatchSearchQuery = (...args) => {
  const [standAloneWord] = args;

  let fuzziness = 0;

  if (standAloneWord?.length > NUMBER_WORDS_FOR_START_FUZZYNESS) {
    fuzziness = Math.ceil(standAloneWord.length / 5);
  }

  return {
    match: {
      text: {
        query: standAloneWord,
        boost: MIN_BOOST,
        fuzziness: fuzziness,
        prefix_length: fuzziness,
      },
    },
  };
};

const getStandAloneWordsQuery = (...args) => {
  const [standAloneWords] = args;
  const queryWords = [];
  //This pattern finds complicated parts of names e.g. '-i-' in 'Kitab-i-Iqan', '’u’' in 'Baha’u’llah',
  //''l-' in 'Abdu'l-Baha', '‘u’l-' in 'Rabí‘u’l-Avval'
  const pattern = /(?:[‘’`'-][a-zA-Z\u00C0-\u024F\u1E00-\u1EFF])+[‘’`'-]|[‘’`'-][a-zA-Z\u00C0-\u024F\u1E00-\u1EFF][‘’`'-]|[‘’`'-]/g;
  let normalizedQueryWords;

  standAloneWords.forEach(standAloneWord => {
    if (complicatedNamesRe.test(standAloneWord)) {
      //Replace complicated parts of names to '*' to prevent weird results like 'i', 'l', 'u'
      normalizedQueryWords = standAloneWord.replace(pattern, '*');
      queryWords.push(normalizedQueryWords);
      const separatedWordSplit = standAloneWord.split(pattern);
      separatedWordSplit.forEach(word => queryWords.push(word));
    } else {
      queryWords.push(standAloneWord);
    }
  });

  return queryWords.map(standAloneWord => {
    return {
      bool: {
        should: [
          createMustQuery(standAloneWord),
          createFuzzyMatchSearchQuery(standAloneWord),
        ],
      },
    };
  });
};

/**
 * get query for search by transliteration words
 */
const getPhoneticQuery = (...args) => {
  const [phoneticWords] = args;
  if (phoneticWords?.length === 0) {
    return [];
  }
  return [
    {
      match: {
        text: {
          query: phoneticWords.join(' '),
          operator: 'or',
          boost: MIN_BOOST,
          fuzziness: 1,
          prefix_length: 1,
        },
      },
    },
  ];
};

const createMatchPhraseQuery = (...args) => {
  const [quote, boost] = args;
  return {
    match_phrase: {
      'text.exact': {
        query: quote,
        boost: boost || MIN_BOOST,
      },
    },
  };
};

/**
 * get query for search by quotes
 */
const getPhraseQuery = (...args) => {
  const [quotes] = args;
  return quotes.map(createMatchPhraseQuery);
};

const createQueryString = (...args) => {
  const [str, field, boost, isFilterQueryString] = args;
  const queryString = isFilterQueryString ? `${str}` : `*${str}* OR ${str}`;
  return {
    query_string: {
      default_field: field,
      query: queryString,
      default_operator: 'AND',
      boost: boost,
    },
  };
};

const createMetaDataQuery = (meta, boost) => {
  return [
    createQueryString(meta, 'author.exact', boost),
    createQueryString(meta, 'title.exact', boost),
    createQueryString(meta, 'category.exact', boost),
  ];
};

const getMetaDataQuery = metaData => {
  const metaQuery = [];

  metaData.forEach(meta => {
    [].push.apply(metaQuery, createMetaDataQuery(meta, EXACT_BOOST));
  });

  return {
    bool: {
      should: metaQuery,
    },
  };
};

const getBookIdQuery = bookId => {
  if (!bookId) {
    return [];
  }
  return [
    {
      match: {
        publicationId: bookId,
      },
    },
  ];
};

const getMustQuery = (...args) => {
  const [standAloneWords, quotes, metaData, bookId, phoneticWords] = args;
  const standAloneWordsQuery = getStandAloneWordsQuery(standAloneWords);
  const phoneticWordsQuery = getPhoneticQuery(phoneticWords);
  const phraseQuery = getPhraseQuery(quotes);
  const metaDataQuery = getMetaDataQuery(metaData);
  const bookIdQuery = getBookIdQuery(bookId);

  return [].concat.apply(
    [],
    [
      standAloneWordsQuery,
      phraseQuery,
      metaDataQuery,
      bookIdQuery,
      phoneticWordsQuery,
    ]
  );
};

const removeSpecialFilterChars = string => {
  return string.replace(/[()[\]!?/\\:;><=+\-*~|]+/g, ' ');
};

const createFilterQuery = (...args) => {
  const [category, values] = args;
  const boost = 10;
  const isFilterQueryString = true;

  if (!values.length) {
    return null;
  }

  return values.map(value => {
    return createQueryString(
      removeSpecialFilterChars(value),
      config.filterCategoryToFilterTitleMap[category] + '.exact',
      boost,
      isFilterQueryString
    );
  });
};

const getFilterQuery = (...args) => {
  const [filter] = args;
  const shouldFilterQuery = [];

  if (filter) {
    Object.keys(filter).forEach(key => {
      const terms = createFilterQuery(key, filter[key]);
      if (terms) {
        shouldFilterQuery.push(terms);
      }
    });
  }

  return [
    {
      bool: {
        should: shouldFilterQuery,
      },
    },
  ];
};

/**
 * create main query for search with necessary parts,
 * black list words which need remove and desirable to have
 */
const getSearchQuery = (...args) => {
  const [
    standAloneWords,
    blackList,
    quotes,
    metaData,
    phoneticWords,
    bookId,
    filter,
  ] = args;
  return {
    query: {
      bool: {
        must_not: getMustNotQuery(blackList),
        must: getMustQuery(
          standAloneWords,
          quotes,
          metaData,
          bookId,
          phoneticWords
        ),
        should: [],
        filter: getFilterQuery(filter),
      },
    },
  };
};

const createHighlightObj = (...args) => {
  const [mustQuery] = args;
  return {
    bool: {
      must: mustQuery,
    },
  };
};

/**
 * function for temporary fix quotes highlight
 */
const createHighlightQueryFromSearchQuery = (...args) => {
  const [searchQuery, originalQuery] = args;
  const highlightQuery = JSON.parse(JSON.stringify(searchQuery.query));
  const wordsQuery = [];
  const quotesQuery = [];
  let standAloneWord;

  if (highlightQuery.bool.should[0]) {
    standAloneWord =
      highlightQuery.bool.should[0].match_phrase['text.exact'].query;
  } else if (originalQuery) {
    standAloneWord = originalQuery;
  }
  if (
    complicatedNamesRe.test(standAloneWord) &&
    !highlightQuery.bool.must[1].match
  ) {
    highlightQuery.bool.must[0].bool.should[0].match_phrase =
      highlightQuery.bool.must[0].bool.should[0].match;

    highlightQuery.bool.must[0].bool.should[0].match_phrase.text.query = standAloneWord;
    delete highlightQuery.bool.must[0].bool.should[0].match;
    quotesQuery.push(highlightQuery.bool.must[0]);
  }

  highlightQuery.bool.must.forEach(function(query) {
    if (query.hasOwnProperty('match_phrase')) {
      quotesQuery.push(query);
    } else {
      wordsQuery.push(query);
    }
  });

  return {
    words: createHighlightObj(wordsQuery),
    quotes: createHighlightObj(quotesQuery),
  };
};

const getBoostedStandAloneWordsQuery = (...args) => {
  const [standAloneWords] = args;
  return standAloneWords.map(standAloneWord => {
    return createMatchPhraseQuery(standAloneWord, EXACT_BOOST);
  });
};

const createSlopQuery = (...args) => {
  const [query, slop, boost] = args;

  return {
    match_phrase: {
      text: {
        query: query,
        slop: slop,
        boost: boost,
      },
    },
  };
};

const getSlopQuery = (...args) => {
  const [standAloneWords] = args;

  if (standAloneWords && standAloneWords.length <= 1) {
    return [];
  }

  const query = [];
  const MIN_SLOP_BOOST = 8;
  let queryString;
  if (complicatedNamesRe.test(standAloneWords[0])) {
    queryString = standAloneWords[0];
  } else {
    queryString = standAloneWords.join(' ');
  }

  for (let i = 0; i <= MAX_SLOP; i++) {
    query.push(
      createSlopQuery(queryString, i, MIN_SLOP_BOOST - Number('0.' + i))
    );
  }
  return query;
};

const addSortPriority = (...args) => {
  const [searchQuery, standAloneWords, phraseWithStopWords] = args;
  const exactMatchPriority = getBoostedStandAloneWordsQuery(standAloneWords);
  const phraseWithStopWordsExactMatch = createMatchPhraseQuery(
    phraseWithStopWords || '',
    10
  );
  const slopQuery = getSlopQuery(standAloneWords);
  const should = [].concat.apply(
    [],
    [exactMatchPriority, phraseWithStopWordsExactMatch, slopQuery]
  );

  Object.assign(searchQuery.query.bool, {
    should: should,
  });
  return searchQuery;
};

const wrapInScoreFunction = (...args) => {
  const [searchQuery] = args;

  return {
    query: {
      function_score: {
        query: searchQuery.query,
        script_score: {
          script: {
            source:
              "!doc['isTitle'].value ? _score + (_score * ((doc['weight'].value - 5) * 20 / 100)) : 0",
          },
        },
      },
    },
  };
};

const createHighlightConfig = (...args) => {
  const [highlightQuery, highlightClass] = args;

  return {
    type: 'fvh',
    pre_tags: ['<span class="' + highlightClass + '">'],
    post_tags: ['</span>'],
    number_of_fragments: FRAGMENT_SIZE,
    fragment_size: FRAGMENT_SIZE,
    fragmenter: 'simple',
    highlight_query: highlightQuery,
  };
};

const addHighlightQuery = (...args) => {
  const [searchQuery, highlightQuery] = args;

  return {
    ...searchQuery,
    ...{
      highlight: {
        fields: {
          text: createHighlightConfig(highlightQuery.words, 'search-req'),
          'text.exact': createHighlightConfig(
            highlightQuery.quotes,
            'search-quote'
          ),
        },
      },
    },
  };
};

const addSizeInfo = (...args) => {
  const [searchQuery, size, from] = args;
  const sizeInfo = {};
  if (typeof from === 'number') {
    sizeInfo.from = from;
  }
  if (typeof size === 'number') {
    sizeInfo.size = size;
  }
  return { ...searchQuery, ...sizeInfo };
};

const addCollapseByPubIdQuery = (...args) => {
  const [searchQuery] = args;

  return {
    ...searchQuery,
    ...{
      collapse: {
        field: 'publicationId',
        inner_hits: {
          name: 'cluster',
          size: INNER_HITS_COUNT,
          highlight: searchQuery.highlight,
        },
      },
    },
  };
};

const getTotalAggByFieldName = (...args) => {
  const [fieldName] = args;
  return {
    cardinality: {
      field: fieldName,
    },
  };
};

const addTotalResultsAggsQuery = (...args) => {
  const [searchQuery] = args;

  return {
    ...searchQuery,
    ...{ aggs: { totalPublications: getTotalAggByFieldName('publicationId') } },
  };
};

const createSentencesListQuery = (...args) => {
  const [parsedQuery, from, size] = args;

  let query = getSearchQuery(
    parsedQuery.standAloneWords,
    parsedQuery.blackList,
    parsedQuery.quotes,
    parsedQuery.metaData,
    parsedQuery.phoneticWords,
    null,
    parsedQuery.filter
  );
  const highlightQuery = createHighlightQueryFromSearchQuery(query);
  query = addSortPriority(
    query,
    parsedQuery.standAloneWords,
    parsedQuery.phraseWithStopWords
  );
  query = wrapInScoreFunction(query, from, size, parsedQuery);
  query = addHighlightQuery(query, highlightQuery);
  query = addSizeInfo(query, size, from);
  query = addCollapseByPubIdQuery(query);
  query = addTotalResultsAggsQuery(query);
  query.track_total_hits = true;
  return query;
};

const addHighlight = (...args) => {
  let query = args[0];
  const highlightOptions = args[1];
  const searchText = args[2];

  let highlightQuery = getSearchQuery(
    highlightOptions.standAloneWords,
    [],
    highlightOptions.quotes,
    [],
    highlightOptions.phoneticWords,
    '',
    {}
  );
  highlightQuery = createHighlightQueryFromSearchQuery(
    highlightQuery,
    searchText
  );
  query = addHighlightQuery(query, highlightQuery);
  return query;
};

/**
 * query for get sentences list by ids
 */
const createMoreTextQuery = (...args) => {
  const [sentIds, highlightOptions, numberParagraphs] = args;
  const paramsIds = JSON.parse(JSON.stringify(sentIds)).reverse();
  let query = {
    size: numberParagraphs,
    query: {
      function_score: {
        query: {
          ids: {
            values: sentIds,
          },
        },
        script_score: {
          script: {
            lang: 'painless',
            params: {
              ids: paramsIds,
            },
            inline: "return params.ids.indexOf(doc['docId'].value)",
          },
        },
      },
    },
  };
  if (highlightOptions) {
    query = addHighlight(query, highlightOptions);
  }
  return query;
};

const getListAggByFieldName = (...args) => {
  const [field] = args;
  const _map = {
    'author.keyword': 'title.keyword',
    'title.keyword': 'author.keyword',
    'category.keyword': 'title.keyword',
  };
  const max_score = {
    max: {
      script: '_score',
    },
  };

  return {
    terms: {
      size: FILTER_ITEMS_SIZE,
      field: field,
      order: {
        max_score: 'desc',
      },
    },
    aggs: {
      max_score,
      value: {
        terms: {
          size: FILTER_ITEMS_SIZE,
          field: _map[field],
          order: {
            max_score: 'desc',
          },
        },
        aggs: {
          max_score,
        },
      },
    },
  };
};

const addFiltersAggregationInfo = (...args) => {
  const [searchQuery, isInitial] = args;
  const aggregationInfo = {};

  if (isInitial) {
    aggregationInfo.categories = getListAggByFieldName('category.keyword');
    aggregationInfo.categoriesTotal = getTotalAggByFieldName(
      'category.keyword'
    );
  } else {
    aggregationInfo.authors = getListAggByFieldName('author.keyword');
    aggregationInfo.publications = getListAggByFieldName('title.keyword');
  }
  aggregationInfo.authorsTotal = getTotalAggByFieldName('author.keyword');
  aggregationInfo.publicationsTotal = getTotalAggByFieldName('publicationId');
  return { ...searchQuery, ...{ aggs: aggregationInfo } };
};

const createFiltersQuery = (...args) => {
  const [parsedQuery, from, size, isInitial] = args;

  let query = getSearchQuery(
    parsedQuery.standAloneWords,
    parsedQuery.blackList,
    parsedQuery.quotes,
    parsedQuery.metaData,
    parsedQuery.phoneticWords,
    null,
    parsedQuery.filter
  );
  query = addSizeInfo(query, size, from);
  query = addFiltersAggregationInfo(query, isInitial);
  return query;
};

const createDocIdQuery = docId => {
  return createMoreTextQuery([docId]);
};

const createBookParaQuery = (...args) => {
  const [publicationId, paraRange, highlightOptions, searchText] = args;

  let query = {
    size: 500,
    query: {
      bool: {
        must: [
          {
            match: {
              publicationId: {
                query: publicationId,
              },
            },
          },
          {
            regexp: {
              paraId: {
                value: 'para_<' + paraRange.start + '-' + paraRange.end + '>',
              },
            },
          },
        ],
      },
    },
  };
  if (highlightOptions) {
    query = addHighlight(query, highlightOptions, searchText);
  }
  return query;
};

const addFilterByBookId = (...args) => {
  const [searchQuery, bookId] = args;

  searchQuery.query.bool.filter.push({
    term: {
      publicationId: bookId,
    },
  });
  return searchQuery;
};

const addSelectionFields = (...args) => {
  const [searchQuery] = args;

  return Object.assign(searchQuery, {
    _source: {
      includes: ['docId'],
    },
  });
};

const createBookSentencesListQuery = (...args) => {
  const [parsedQuery, from, size, bookId] = args;
  let query = getSearchQuery(
    parsedQuery.standAloneWords,
    parsedQuery.blackList,
    parsedQuery.quotes,
    parsedQuery.metaData,
    parsedQuery.phoneticWords,
    '',
    parsedQuery.filter
  );

  query = addSortPriority(
    query,
    parsedQuery.standAloneWords,
    parsedQuery.phraseWithStopWords
  );
  query = addFilterByBookId(query, bookId);
  query = wrapInScoreFunction(query, from, size, parsedQuery);
  query = addSizeInfo(query, size, from);
  query = addSelectionFields(query);
  return query;
};

export default {
  createSentencesListQuery,
  createMoreTextQuery,
  createFiltersQuery,
  createDocIdQuery,
  createBookParaQuery,
  createBookSentencesListQuery,
};
