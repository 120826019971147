// import MarkerUtils from './dom-utils/marker-utils';
import TextUtils from './dom-utils/text-utils';
// import Locator from './locator';

// const MarkerUtilsInst = new MarkerUtils();
// const LocatorInst = new Locator();
const TextUtilsInst = new TextUtils();

export default class Highlighter {
  constructor() {}

  decorateStableOffsets(
    stableOffsets,
    element,
    decoratorClassName,
    decoratorData
  ) {
    const domLocatorBlocks = TextUtilsInst.convertIntoDomLocatorBlocks(
      stableOffsets,
      element
    );

    domLocatorBlocks.reverse().forEach(section => {
      this._decorateSection(section, decoratorClassName, decoratorData);
    });
  }
  /**
   *
   * @param {DomLocatorBlock} domLocatorBlock
   * @param {string} [decoratorClassName]
   * @param {Object} [decoratorData]
   * @returns {Array.<Element>}
   * @private
   */
  _decorateSection(domLocatorBlock, decoratorClassName, decoratorData) {
    return domLocatorBlock.map(domLocator => {
      return this._decorateDomLocator(
        domLocator,
        decoratorClassName,
        decoratorData
      );
    });
  }
  /**
   *
   * @param {DomLocator} domLocator
   * @param {string} [decoratorClassName]
   * @param {Object} [decoratorData]
   * @returns {Element}
   * @private
   */
  _decorateDomLocator(domLocator, decoratorClassName, decoratorData) {
    const textNode = domLocator.textNode;
    const start = domLocator.start;
    const end = domLocator.end;
    const decoratorElement = this._createDecorator(
      textNode,
      decoratorClassName,
      decoratorData
    );
    let decorableTextNode = textNode;
    const textNodeLength = textNode.data.length;

    if (start !== 0) {
      decorableTextNode = textNode.splitText(start);
    }
    if (end !== textNodeLength) {
      decorableTextNode.splitText(end - start);
    }
    decorableTextNode.parentNode.insertBefore(
      decoratorElement,
      decorableTextNode
    );
    decoratorElement.appendChild(decorableTextNode);

    return decoratorElement;
  }

  /**
   *
   * @param {Node} decoratedNode
   * @param {string} [decoratorClassName]
   * @param {Object} [decoratorData]
   * @returns {Element}
   * @private
   */
  _createDecorator(decoratedNode, decoratorClassName, decoratorData) {
    const decoratorElement = decoratedNode.ownerDocument.createElement('span');

    if (decoratorClassName && typeof decoratorClassName === 'string') {
      decoratorElement.className = decoratorClassName;
    }
    if (decoratorData && typeof decoratorData === 'object') {
      Object.keys(decoratorData).forEach(function(key) {
        decoratorElement.setAttribute('data-' + key, decoratorData[key]);
      });
    }

    return decoratorElement;
  }
  /**
   *
   * @param {string} className
   * @param {Element} container
   */
  undecorateByClass(className, container) {
    const elements = Array.prototype.slice.call(
      container.querySelectorAll('.' + className)
    );
    elements.forEach(element => {
      this._removeDecorator(element);
    });
  }

  _removeDecorator(decoratorElement) {
    const parentNode = decoratorElement.parentNode;
    const childNodes = Array.prototype.slice.call(decoratorElement.childNodes);
    const documentFragment = decoratorElement.ownerDocument.createDocumentFragment();

    childNodes.forEach(node => {
      documentFragment.appendChild(node);
    });
    parentNode.replaceChild(documentFragment, decoratorElement);
    parentNode.normalize(); // TODO: test in IE
  }
}
