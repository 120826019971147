<template>
  <div v-hotkey="keymap" class="search-criteria-block">
    <div
      class="progress-bar-slider"
      :class="{ 'progress-bg-active': searchInProcess }"
    ></div>

    <span
      class="search-element logo-block"
      :class="{ 'logo-planet': brand === 'ocean' }"
    >
      <BaseIcon :icon-name="logoIconName" />
    </span>

    <SearchField />

    <div v-show="shouldShowToolbar" class="search-result-wrap">
      {{
        $t('TopBar.BooksMatchesWereFound.label', {
          booksTotal: totalPublications,
          sentencesTotal: totalHits,
        })
      }}
    </div>

    <BaseButton
      v-show="shouldShowToolbar"
      data-test="clear-button"
      class="search-element -clear-icon"
      :on-click="clearSearchResults"
      :title="$tc('Tooltips.clearButton')"
    >
      <span class="icon-container">
        <BaseIcon icon-name="ico-clear" />
      </span>
    </BaseButton>

    <BaseButton
      class="search-element -search-filter-icon"
      :class="{
        disabled: !isFilterActive,
      }"
      :on-click="toggleSearchFilter"
      :title="$tc('Tooltips.filterButton')"
    >
      <span class="icon-container">
        <BaseIcon icon-name="ico-filter" />
      </span>
    </BaseButton>

    <BaseButton
      v-show="isKeyboardBtnVisible()"
      data-test="keyboard-icon"
      class="search-element -keyboard-icon"
      :class="{ disabled: !isOnline }"
      :disabled="!isOnline"
      :on-click="toggleKeyboard"
      :title="$tc('Tooltips.keyboardButton')"
    >
      <span class="icon-container">
        <BaseIcon icon-name="ico-keyboard" />
      </span>
    </BaseButton>

    <BaseButton
      v-show="shouldShowMenuButton"
      data-test="dropdown-icon"
      class="search-element -dropdown-icon -narrow"
      :on-click="toggleMenu"
      :title="$tc('Tooltips.changeLanguageButton')"
    >
      <span class="icon-container collapsed-menu">
        <BaseIcon icon-name="ico-hidden-menu" />
      </span>
      <span class="detailed-menu">
        <span>{{ $tc('Languages.' + lang) }}</span>
        <span class="icon-container">
          <BaseIcon icon-name="ico-arrow-chevron" />
        </span>
      </span>
    </BaseButton>

    <BaseTooltip
      v-if="menuOpened"
      :h-alignment="'right'"
      :v-alignment="'bottom'"
    >
      <BaseButton
        v-click-outside="hide"
        class="-tooltip-icon"
        :on-click="toggleInfoApp"
        data-test="dropdown-icon-in-dropdown"
      >
        <span class="icon-container info-block">
          <BaseIcon icon-name="ico-info-app" />
        </span>
        <span>{{ $tc('SearchTips') }}</span>
      </BaseButton>
      <Languages v-click-outside="hide" />
    </BaseTooltip>

    <BaseButton
      data-test="tooltip-icon"
      class="search-element -tooltip-icon"
      :on-click="toggleInfoApp"
      :title="$tc('Tooltips.infoButton')"
    >
      <span class="icon-container">
        <BaseIcon icon-name="ico-info-app" />
      </span>
    </BaseButton>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import defaultConfig from '@/configs/default.config.json';

  import BaseButton from '@/components/BaseButton';
  import BaseIcon from '@/components/BaseIcon';
  import BaseTooltip from '@/components/BaseTooltip';

  import SearchField from '@/containers/searchfield/SearchField';
  import Languages from '@/containers/languages/Languages.vue';

  import layoutUtils from '@/utils/layoutUtils';

  import ClickOutside from 'vue-click-outside';

  import { CLASS_NAME_FOR_LANGUAGE_LIST } from '@/constants/constants';

  import * as log from 'loglevel';
  log.setLevel('info');

  let isMobile;

  export default {
    name: 'TopBar',
    components: {
      SearchField,
      BaseButton,
      BaseTooltip,
      Languages,
      BaseIcon,
    },
    directives: {
      ClickOutside,
    },
    data() {
      return {
        brands: defaultConfig.brands,
      };
    },
    computed: {
      ...mapState('SearchStore', [
        'searchText',
        'searchInProcess',
        'totalPublications',
        'totalHits',
        'foundNothing',
      ]),
      ...mapState('FilterStore', ['filterOpened']),
      ...mapState('OfflineModeStore', ['isOnline']),
      ...mapState('ContextStore', [
        'brand',
        'lang',
        'menuOpened',
        'keyboardOpened',
        'infoOpened',
      ]),

      isFilterActive() {
        return this.$store.getters['FilterStore/isFilterActive'];
      },

      keymap() {
        return {
          'ctrl+i': e => {
            e.preventDefault();
            this.toggleInfoApp();
          },
        };
      },
      shouldShowToolbar() {
        return (
          !this.searchInProcess &&
          (this.foundNothing || this.totalHits > 0 || !!this.searchText)
        );
      },
      logoIconName() {
        return this.brand === 'ocean' ? 'ico-ocean' : 'ico-search';
      },
      shouldShowMenuButton() {
        return (
          this.brand === defaultConfig.brands.ocean ||
          this.brand === defaultConfig.brands.ool
        );
      },
    },
    watch: {
      searchText: {
        handler: function(value) {
          if (value) {
            this.$store.dispatch('FilterStore/setFilterOpened', false);
          }
        },
      },
    },
    mounted() {
      isMobile = layoutUtils.isMobile();
    },
    methods: {
      isKeyboardBtnVisible() {
        return (
          (!isMobile &&
            this.brand === defaultConfig.brands.ocean &&
            this.lang !== defaultConfig.defaultLanguages.en) ||
          (this.brand === defaultConfig.brands.ool &&
            this.lang !== defaultConfig.defaultLanguages.en)
        );
      },

      clearSearchResults() {
        this.$store.dispatch('SearchStore/totalReset');
        this.$store.dispatch('FilterStore/resetFilterStore');
      },

      toggleSearchFilter(event) {
        event.stopPropagation();

        if (this.isFilterActive) {
          this.$store.dispatch(
            'FilterStore/setFilterOpened',
            !this.filterOpened
          );
        }
      },

      toggleMenu(event) {
        if (event) {
          event.preventDefault();
          event.cancelBubble = true;
        }
        this.$store.dispatch('ContextStore/setMenuOpened', !this.menuOpened);
      },

      hide(event) {
        if (event) {
          event.stopPropagation();
        }

        let clickOnLanguageList = false;
        event.path.forEach(elem => {
          if (elem.classList?.value === CLASS_NAME_FOR_LANGUAGE_LIST) {
            clickOnLanguageList = true;
          }
        });
        if (clickOnLanguageList) {
          return;
        }

        this.$store.dispatch('ContextStore/setMenuOpened', false);
      },

      toggleKeyboard() {
        this.$store.dispatch(
          'ContextStore/setKeyboardOpened',
          !this.keyboardOpened
        );
      },

      toggleInfoApp() {
        this.$store.dispatch('ContextStore/setInfoOpened', !this.infoOpened);
      },

      getLocalizedNumber(number) {
        return this.$store.getters['ContextStore/getLocalizedNumber'](number);
      },

      $_checkSearchText(queryInputtedForFilterButton, filterActiveCharsNum) {
        return (
          !this.foundNothing &&
          queryInputtedForFilterButton?.trim().length >= filterActiveCharsNum
        );
      },
    },
  };
</script>

<style lang="less">
  @import 'TopBar.less';
</style>
