<template>
  <div v-hotkey="keymap" class="search-results-block">
    <Info v-if="isSearchTipsOpened" />
    <div class="search-results-wrapper">
      <div class="search-results-noresults" dir="auto">
        <BaseIcon icon-name="ico-search" />
        <span>Sorry, no results found</span>
      </div>
      <div
        class="search-hits-wrapper"
        :class="{ 'narrow-visible': hitsActive }"
      >
        <SearchHits />
      </div>

      <div
        class="search-hits-paragraphs-wrapper"
        :class="{ 'narrow-visible': !hitsActive }"
      >
        <BookMeta />

        <Paragraphs
          ref="paragraphs"
          class="search-result-item-content"
          @copy.native="$refs.paragraphs.copyToClipboard"
        />

        <div v-if="isCopyrightVisible" class="copyright-block">
          {{ $tc('CopyRight.label') }}
        </div>

        <div class="narrow-navigation-block">
          <div class="back-link" @click="toggleHitsActive()">All results</div>
        </div>
      </div>
    </div>
    <SearchFilter v-if="filterOpened" />
    <div
      class="results-shadow-box"
      :class="{ visible: !!activeFilterCategory && searchInProcess }"
    />
    <Keyboard v-if="isKeyboardVisible" />
  </div>
</template>

<script>
  import { focus } from 'vue-focus';
  import { mapState } from 'vuex';

  import config from '@/configs/default.config.json';

  import {
    SPACE_SCROLL_UP,
    SPACE_SCROLL_DOWN,
    INPUT,
  } from '@/constants/constants';
  import { PORTUGUESE } from '@/constants/constants';
  import PublicationVisibilityStatuses from '@/enums/PublicationVisibilityStatusesEnum.js';

  import SearchFilter from '@/containers/searchfilter/SearchFilter.vue';
  import Info from '@/containers/info/Info.vue';
  import SearchHits from '@/containers/searchhits/SearchHits';
  import BookMeta from '@/containers/bookmeta/BookMeta';
  import Paragraphs from '@/containers/paragraphs/Paragraphs';
  import Keyboard from '@/containers/keyboard/Keyboard';

  import eventManager from '@/services/EventService';

  import BaseIcon from '@/components/BaseIcon';

  export default {
    name: 'BottomBar',
    components: {
      SearchFilter,
      SearchHits,
      BookMeta,
      Paragraphs,
      Info,
      Keyboard,
      BaseIcon,
    },
    directives: { focus: focus },
    computed: {
      ...mapState('ContextStore', [
        'hitsActive',
        'lang',
        'keyboardOpened',
        'infoOpened',
      ]),
      ...mapState('HitsStore', ['activeHit']),
      ...mapState('FilterStore', ['filterOpened', 'activeFilterCategory']),
      ...mapState('SearchStore', ['searchInProcess', 'foundNothing']),

      isKeyboardVisible() {
        return (
          this.keyboardOpened &&
          (this.isRtlLanguage() || this.lang === PORTUGUESE)
        );
      },

      isCopyrightVisible() {
        return (
          this.activeHit.bookMeta.accessStatus ===
          PublicationVisibilityStatuses.ENCUMBERED
        );
      },

      isSearchTipsOpened() {
        return this.infoOpened;
      },

      keymap() {
        return {
          'ctrl+c': event => {
            this.$_copy(event);
          },
          'meta+c': event => {
            this.$_copy(event);
          },
        };
      },
    },

    mounted() {
      eventManager.subscribe(SPACE_SCROLL_UP, () => this.$_triggerScroll(-1));
      eventManager.subscribe(SPACE_SCROLL_DOWN, () => this.$_triggerScroll(1));
    },

    beforeDestroy() {
      eventManager.unsubscribe(SPACE_SCROLL_UP);
      eventManager.unsubscribe(SPACE_SCROLL_DOWN);
    },

    methods: {
      isRtlLanguage() {
        return config.rtlLangs.indexOf(this.lang) !== -1;
      },

      $_triggerScroll(direction) {
        const wrapper = this.$el.querySelectorAll('.search-results-wrapper');
        const container = wrapper[0].querySelector('.virtual-list:focus');

        if (container) {
          container.scrollTop += (container.clientHeight / 3) * direction;
        }
      },

      toggleHitsActive() {
        this.$store.dispatch('ContextStore/setHitsActive', !this.hitsActive);
      },

      $_copy(event) {
        if (
          event.target.tagName === INPUT ||
          (event.target.shadowRoot &&
            event.target.shadowRoot.activeElement.tagName === INPUT)
        ) {
          document.execCommand('copy');
        } else {
          this.$refs.paragraphs.copyToClipboard();
          document.execCommand('copy');
        }
      },
    },
  };
</script>

<style scoped lang="less">
  @import 'BottomBar.less';
</style>
