const arabic = [
  '\u0630١٢٣٤٥٦٧٨٩٠-={Backspace:backspace}|' +
    '\u0636\u0635\u062B\u0642\u0641\u063A\u0639\u0647\u062E\u062D\u062C\u062F\\|' +
    '\u0634\u0633\u064A\u0628\u0644\u0627\u062A\u0646\u0645\u0643\u0637|' +
    '\u0626\u0621\u0624\u0631\ufefb\u0649\u0629\u0648\u0632\u0638{Shift:goto:1}|' +
    '{space}',
  '\u0651!@#$%^&*)(_+{Backspace:backspace}|' +
    '\u064E\u064B\u064F\u064C\ufef9\u0625\u2018\u00F7\u00D7\u061B<>|||' +
    '\u0650\u064D][\ufef7\u0623\u0640\u060C/:"|' +
    '~\u0652}}{{\ufef5\u0622\u2019,.\u061F{Shift:goto:0}|' +
    '{space}',
];

export default arabic;
