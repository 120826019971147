<template>
  <div
    class="search-results-item-block"
    :class="{
      'regular-search-hit': source.firstGroupItem,
      active: activeHitIndex === index,
    }"
    @click="handleHitItemClick()"
  >
    <div class="search-results-item-block-wrapper">
      <div class="search-results-item-spacer" />

      <div class="search-results-item-wrapper">
        <div class="search-hit-publication-meta">
          <div class="item-stats">
            <div class="item-book-name-block">
              <BookIcon :publication-type="source.bookMeta.type" />
              <div class="item-book-name" dir="auto">
                <span
                  class="item-book-name-author have-title"
                  :class="{ 'is-empty': !source.bookMeta.author }"
                  ><span>{{ source.bookMeta.author }}</span></span
                >
                <span class="item-book-name-title">{{
                  source.bookMeta.title
                }}</span>
              </div>
            </div>
            <div class="item-book-spacer" />
            <div class="item-book-total-results">
              {{ source.bookMeta.totalResults }}
            </div>
          </div>
          <div class="item-text" dir="auto" v-html="source.text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BookIcon from '@/components/BookIcon';
  import EventEnum from '@/enums/EventEnum';

  export default {
    name: 'SearchHitItem',
    components: {
      BookIcon,
    },
    props: {
      index: {
        type: Number,
      },
      source: {
        type: Object,
        default() {
          return {};
        },
      },
      activeHitIndex: {
        type: Number,
      },
      hitItemClick: {
        type: Function,
        default: () => {},
      },
    },
    methods: {
      handleHitItemClick() {
        this.hitItemClick({
          type: EventEnum.HIT_ITEM_CLICKED,
          data: {
            index: this.index,
            source: { ...this.source },
          },
        });
      },

      getLocalizedNumber(number) {
        return this.$store.getters['ContextStore/getLocalizedNumber'](number);
      },
    },
  };
</script>

<style></style>
