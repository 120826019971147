<template>
  <div
    class="virtual-list-wrapper"
    :class="{ hidden: !isParagraphsReady }"
    @click="setFocus"
    v-hotkey="keymap"
    @keydown.space.exact="$_keymapHandlerDown"
    @keydown.space.shift.exact="$_keymapHandlerUp"
    v-touch:swipe="navigate"
  >
    <div class="hollow-dots-spinner" v-if="isSpinnerVisible">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>

    <virtual-list
      v-focus="focus.paragraphs"
      ref="virtualList"
      tabindex="200"
      class="virtual-list"
      :keeps="200"
      :data-sources="paragraphs"
      :data-key="'id'"
      :data-component="paragraphItem"
      :extra-props="{
        handleMouseDown: cacheFirstSelectionPara,
        handleMouseUp: cacheLastSelectionPara,
      }"
      v-on:totop="handleScrollToTop"
      v-on:tobottom="handleScrollToBottom"
      v-on:scroll="handleScroll"
    ></virtual-list>
  </div>
</template>

<script>
  import virtualList from 'vue-virtual-scroll-list';
  import { focus } from 'vue-focus';

  import { mapState } from 'vuex';
  import {
    SCROLL_DOWN,
    SCROLL_UP,
    MAX_NEXT_PARA_CHECKS_COUNT,
    SPACE_SCROLL_UP,
    SPACE_SCROLL_DOWN,
    WIDGET_NAME_MAP,
    FOCUS_SELECTOR_PARAGRAPHS,
  } from '@/constants/constants';

  import ParagraphItem from '@/containers/paragraphs/ParagraphItem';

  import CopyService from '@/services/CopyService.js';
  import ExternalLinkService from '@/services/ExternalLinkService.js';
  import eventManager from '@/services/EventService';

  import layoutUtils from '@/utils/layoutUtils';

  import EventEnum from '@/enums/EventEnum';

  const TOUCH_DELTA = 5;
  const PARAGRAPHS_READY_TIMEOUT = 50;

  let touchStartY;
  let touchEndY;
  let scrollDirection;
  let paragraphsReadyTimeoutFn;
  let scrollSize;

  let selection;
  let isMainPopupToggled = false;
  let offset = null;

  export default {
    name: 'Paragraphs',
    directives: {
      focus: focus,
    },
    components: {
      'virtual-list': virtualList,
    },
    props: {
      hitParagraphs: {
        type: Array,
      },
    },
    data() {
      return {
        paragraphItem: ParagraphItem,
        isSpinnerVisible: false,
        isParagraphsReady: false,
        isNewActiveHit: true,
      };
    },
    computed: {
      ...mapState('ParagraphsStore', [
        'paragraphs',
        'loadParagraphsInProcess',
        'renderStartIndex',
        'isBookStart',
        'isBookEnd',
      ]),
      ...mapState('HitsStore', ['activeHit']),
      ...mapState('ContextStore', ['brand', 'lang', 'focus']),
      ...mapState('SearchStore', ['parsedResponse']),
      keymap() {
        return {
          up: () => {
            this.$_keymapHandlerUp();
          },
          down: () => {
            this.$_keymapHandlerDown();
          },
        };
      },
    },
    watch: {
      activeHit: {
        handler: function() {
          this.isNewActiveHit = true;
          this.$_setInitialLocalState();
        },
      },
      paragraphs: {
        handler: function() {
          this.$nextTick(() => {
            if (this.isNewActiveHit) {
              this.$_setInitialScrollPosition();
            }
            if (scrollDirection === SCROLL_UP) {
              this.$_setCurrentScrollPosition();
            }
          });
        },
      },
      loadParagraphsInProcess: function(inProcess) {
        if (!inProcess) {
          this.isSpinnerVisible = false;
        }
      },
    },
    mounted() {
      this.$el.addEventListener('wheel', this.handleOnWheelEvent, {
        passive: true,
      });
      this.$el.addEventListener('touchmove', this.handleOnTouchMoveEvent, {
        passive: true,
      });
      this.$el.addEventListener('mousedown', this.handleOnMouseDownEvent);
      this.$el.addEventListener('touchstart', this.handleOnTouchStartEvent, {
        passive: true,
      });
      this.$el.addEventListener('touchend', this.handleOnTouchEndEvent);
      this.$el.addEventListener('keydown', this.handleOnKeyDownEvent);

      this.$store.watch(
        state => state.ContextStore.mainPopupOpened,
        mainPopupOpened => {
          isMainPopupToggled = mainPopupOpened;
          if (offset && isMainPopupToggled) {
            this.$_scrollToLastScrollPlace();
          }
        }
      );
    },
    destroyed() {
      this.$el.removeEventListener('wheel', this.handleOnWheelEvent);
      this.$el.removeEventListener('touchmove', this.handleOnTouchMoveEvent);
      this.$el.removeEventListener('mousedown', this.handleOnMouseDownEvent);
      this.$el.removeEventListener('touchstart', this.handleOnTouchStartEvent);
      this.$el.removeEventListener('touchend', this.handleOnTouchEndEvent);
      this.$el.removeEventListener('keydown', this.handleOnKeyDownEvent);
    },
    methods: {
      setFocus() {
        this.$store.dispatch('ContextStore/changeFocus', {
          selector: FOCUS_SELECTOR_PARAGRAPHS,
        });
      },

      handleScrollToTop() {
        if (this.loadParagraphsInProcess || this.isBookStart) {
          return;
        }

        this.$_setCurrentScrollSize();
        this.isSpinnerVisible = true;
        this.$store.dispatch('ParagraphsStore/performParagraphsSearchPrev');
      },

      handleScrollToBottom() {
        if (this.loadParagraphsInProcess || this.isBookEnd) {
          return;
        }

        this.$store.dispatch('ParagraphsStore/performParagraphsSearchNext');
      },

      handleScroll() {
        switch (scrollDirection) {
          case SCROLL_UP:
            break;
          case SCROLL_DOWN:
            this.handleScrollToBottom();
            break;
          default:
            break;
        }
      },

      $_setCurrentScrollSize() {
        if (this.$refs.virtualList) {
          scrollSize = this.$refs.virtualList.getScrollSize();
        }
      },

      handleOnWheelEvent(event) {
        if (!event) {
          return;
        }

        this.$_setScrollDirectionByDeltaY(event.deltaY);
      },

      handleOnTouchMoveEvent(event) {
        if (!event) {
          return;
        }

        this.$_setScrollDirectionByDeltaY(event.deltaY);
      },

      handleOnMouseDownEvent(event) {
        if (!event) {
          return;
        }

        const scrollBarCoords = this.$_getScrollBarCoords();
        if (
          scrollBarCoords.left >= event.clientX &&
          scrollBarCoords.right <= event.clientX
        ) {
          this.$_setScrollDirectionByDeltaY(event.deltaY);
        }
      },

      handleOnTouchStartEvent(event) {
        if (!event) {
          return;
        }
        this.$_setTouchstartPosition(event);
      },

      handleOnTouchEndEvent(event) {
        if (!event) {
          return;
        }
        this.$_setScrollDirectionByTouches(event);
      },

      handleOnKeyDownEvent(event) {
        switch (event.keyCode) {
          case 38:
          case 33:
          case 36:
            scrollDirection = SCROLL_UP;
            break;
          case 40:
          case 34:
          case 35:
            scrollDirection = SCROLL_DOWN;
            break;
        }
      },

      $_setScrollDirectionByDeltaY(deltaY) {
        if (deltaY < 0) {
          scrollDirection = SCROLL_UP;
          return;
        }
        scrollDirection = SCROLL_DOWN;
      },

      $_setTouchstartPosition(event) {
        if (event && event.touches[0]) {
          touchStartY = event.touches[0].clientY;
        }
      },

      $_setScrollDirectionByTouches(event) {
        if (!event.changedTouches[0]) {
          return;
        }

        touchEndY = event.changedTouches[0].clientY;

        if (touchStartY > touchEndY + TOUCH_DELTA) {
          scrollDirection = SCROLL_DOWN;
        } else if (touchStartY < touchEndY - TOUCH_DELTA) {
          scrollDirection = SCROLL_UP;
        }
      },

      $_getScrollBarCoords() {
        const coords = {
          left: 0,
          right: 0,
        };
        const virtualListElement = this.$el.querySelector('.virtual-list');
        if (!virtualListElement) {
          return coords;
        }

        const virtualListElementRect = virtualListElement.getBoundingClientRect();
        coords.left =
          virtualListElementRect.left + virtualListElement.clientWidth;
        coords.right =
          virtualListElementRect.left + virtualListElement.offsetWidth;

        return coords;
      },

      scrollToFirstVisible() {
        const virtualListElement = this.$el.querySelector('.virtual-list');
        let selection = this.$el.querySelectorAll('.paragraph-number-first');
        selection = selection?.length === 1 ? selection[0] : selection[1];
        selection = selection?.parentElement;
        if (virtualListElement && selection) {
          selection.scrollIntoView(true);
          virtualListElement.scrollTop -= 10;
        }
      },

      copyToClipboard() {
        CopyService.copyToClipboard({
          paragraphs: this.$_getParagraphNumber(),
          author: this.activeHit.bookMeta.author,
          title: this.activeHit.bookMeta.title,
          externalLink: this.$_getExternalLink(),
        });
      },

      $_getParagraphNumber() {
        let counter = MAX_NEXT_PARA_CHECKS_COUNT;
        let firstIndex = (this.paragraphs || []).findIndex(para => {
          if (!selection || !selection.para) {
            return false;
          }
          return para.id === selection.para.id;
        });

        while (counter >= 0) {
          if (
            this.paragraphs[firstIndex] &&
            this.paragraphs[firstIndex].paragraphs
          ) {
            break;
          }
          counter--;
          firstIndex++;
        }

        if (this.paragraphs && this.paragraphs[firstIndex]) {
          return this.paragraphs[firstIndex].paragraphs;
        }
        return '';
      },

      $_getExternalLink() {
        return ExternalLinkService.getExternalLink({
          bookMeta: this.activeHit.bookMeta,
          parsedResponse: this.parsedResponse,
          brand: this.brand,
        });
      },

      cacheFirstSelectionPara(event, para, index) {
        if (this.isRightMouseClickTriggered(event)) {
          return;
        }

        selection = {
          para: para,
          index: index,
        };
      },

      cacheLastSelectionPara(event, para, index) {
        if (this.isRightMouseClickTriggered(event)) {
          return;
        }

        if (index < selection.index) {
          selection = {
            para: para,
            index: index,
          };
        }
      },

      isRightMouseClickTriggered(event) {
        return event.which === 3;
      },

      navigate(direction) {
        if (!layoutUtils.isMobile()) {
          return;
        }

        switch (direction) {
          case 'right':
            eventManager.publish(EventEnum.NAVIGATE_BACK);
            offset = 0;
            break;
          case 'left':
            eventManager.publish(EventEnum.NAVIGATE_FORWARD);
            offset = 0;
            break;
        }
      },

      onScroll(event, data) {
        offset = data.offset;
      },

      $_keymapHandlerUp() {
        if (this.focus && this.focus.paragraphs) {
          scrollDirection = SCROLL_UP;
          eventManager.publish(SPACE_SCROLL_UP);
        }
      },

      $_keymapHandlerDown() {
        if (this.focus && this.focus.paragraphs) {
          scrollDirection = SCROLL_DOWN;
          eventManager.publish(SPACE_SCROLL_DOWN);
        }
      },

      $_setInitialScrollPosition() {
        if (!this.$refs.virtualList) {
          return;
        }

        const self = this;
        this.isParagraphsReady = false;
        this.$_scrollToSelection();
        clearTimeout(paragraphsReadyTimeoutFn);
        paragraphsReadyTimeoutFn = setTimeout(function() {
          self.isParagraphsReady = true;
          self.isNewActiveHit = false;
        }, PARAGRAPHS_READY_TIMEOUT);
      },

      $_setCurrentScrollPosition() {
        if (!this.$refs.virtualList) {
          return;
        }

        this.$_scrollToLastScrollPosition();
      },

      $_scrollToSelection() {
        const scrollElement = this.$el.querySelector('.virtual-list');
        const selectionElement = this.$el.querySelector('.search-sentence');

        if (scrollElement && selectionElement) {
          selectionElement.scrollIntoView({ block: 'start' });

          const virtualListElementRect = scrollElement.getBoundingClientRect();
          const scrollElementRect = selectionElement.getBoundingClientRect();
          const scrollDelta = virtualListElementRect.height / 3;

          if (
            Math.abs(virtualListElementRect.top - scrollElementRect.top) <
            scrollDelta
          ) {
            scrollElement.scrollBy({
              top: -scrollDelta,
            });
          }
        }
      },

      $_scrollToLastScrollPosition() {
        const newScrollSize = this.$refs.virtualList.getScrollSize();
        const delta = newScrollSize - scrollSize;
        this.$refs.virtualList.scrollToOffset(delta);
      },

      $_scrollToLastScrollPlace() {
        this.$nextTick(() => {
          const widget = document.querySelector(WIDGET_NAME_MAP[this.brand]);
          const paragraphsContent =
            widget &&
            widget.shadowRoot.querySelector(`.search-result-item-content`);
          const virtualList = paragraphsContent && paragraphsContent.firstChild;
          if (virtualList && virtualList.scrollTop === 0) {
            virtualList.scrollTop += offset;
          }
        });
      },

      $_setInitialLocalState() {
        touchStartY = 0;
        touchEndY = 0;
        scrollDirection = null;
        paragraphsReadyTimeoutFn = null;
        scrollSize = 0;
      },
    },
  };
</script>

<style lang="less">
  @import 'Paragraphs.less';
</style>
