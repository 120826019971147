<template>
  <div
    v-show="isBookSelected"
    class="search-result-item-info-block"
    :class="{ 'local-book': isTypeLibrary }"
  >
    <div class="search-result-item-info-wrapper">
      <div
        v-if="isBookRegistered && isPartialAccess"
        class="registration-overlay"
        @click="openPublication($event)"
      >
        <div class="free-reg">
          Free with registration
        </div>
      </div>
      <div class="item-block">
        <span v-if="bookCoverPath" class="book-cover-img">
          <img :src="bookCoverPath" class="item-picture-block wide-view" />
          <span class="item-picture-block narrow-view">
            <BaseIcon
              v-if="activeHit.bookMeta.type === 'Book'"
              icon-name="ico-book-new"
            />
            <BaseIcon
              v-if="activeHit.bookMeta.type === 'Supplemental'"
              icon-name="ico-book-new-half"
            />
          </span>
        </span>
        <span v-if="!bookCoverPath" class="book-cover-placeholder">
          <span class="item-picture-block wide-view">
            <BaseIcon icon-name="ico-search-book" />
          </span>
          <span class="item-picture-block narrow-view">
            <BookIcon :publication-type="activeHit.bookMeta.type" />
          </span>
        </span>
        <div class="item-wrapper-block">
          <div class="item-text-wrapper">
            <div class="item-text-block" dir="auto">
              <div class="item-title" dir="auto">
                {{ activeHit.bookMeta.title }}
              </div>
              <div class="item-text-spacer" />
              <div class="item-author" dir="auto">
                {{ activeHit.bookMeta.author }}
              </div>
            </div>
          </div>

          <div class="control-wrapper-block">
            <div>
              <a
                v-if="activeHit.bookMeta.publicationId && openPublicationLink"
                ref="openLink"
                class="item-link"
                target="_blank"
                :title="openButtonTooltip"
                @click="openPublication()"
              >
                <span v-hotkey="keymap" class="local-label">
                  Read
                </span>
                <span v-hotkey="keymap" class="external-label">
                  {{ $t('BookMeta.Open.label') }}
                </span>

                <i class="item-link-icon ico-local">
                  <BaseIcon icon-name="ico-arrow-front" />
                </i>
                <i class="item-link-icon ico-external">
                  <BaseIcon icon-name="ico-external-link" />
                </i>
              </a>
            </div>

            <Navigation data-test="navigation" class="wide-view" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import i18n from '@/i18n';

  import BaseIcon from '@/components/BaseIcon';
  import BookIcon from '@/components/BookIcon';

  import defaultConfig from '@/configs/default.config.json';

  import Navigation from '@/containers/navigation/Navigation';

  import ExternalLinkService from '@/services/ExternalLinkService.js';

  import PublicationVisibilityStatuses from '@/enums/PublicationVisibilityStatusesEnum.js';

  import provider from '@/provider.js';

  import { UNSET_METHOD, SUPPLEMENTAL } from '@/constants/constants';

  import * as log from 'loglevel';
  log.setLevel('info');

  export default {
    name: 'BookMeta',
    components: {
      Navigation,
      BaseIcon,
      BookIcon,
    },
    props: {
      currentDocMock: {
        type: Object,
      },
    },
    data() {
      return {
        externalLinksConfig: defaultConfig.externalLinksConfig,
        openButtonTooltip: i18n.tc('Tooltips.openButton'),
      };
    },
    computed: {
      ...mapState('HitsStore', ['activeHit']),
      ...mapState('ParagraphsStore', ['paraMap']),
      ...mapState('NavigationStore', [
        'navigationList',
        'navigationIndex',
        'totalDocsCount',
      ]),
      ...mapState('ContextStore', ['isPartialAccess', 'parameters', 'brand']),
      ...mapState('SearchStore', [
        'parsedQuery',
        'parsedResponse',
        'searchText',
      ]),

      openPublicationLink() {
        return ExternalLinkService.getExternalLink({
          bookMeta: this.activeHit.bookMeta,
          parsedResponse: this.parsedResponse,
          brand: this.brand,
        });
      },
      isBookSelected() {
        return !!this.activeHit.bookMeta.publicationId;
      },
      bookCoverPath() {
        if (this.activeHit.coverPath === UNSET_METHOD) {
          return false;
        }
        return this.activeHit.coverPath;
      },
      isTypeLibrary() {
        try {
          return this.activeHit.bookMeta?.urlRules?.type === 'library';
        } catch (error) {
          return false;
        }
      },
      isBookRegistered() {
        return (
          this.activeHit.bookMeta.accessStatus ===
          PublicationVisibilityStatuses.REGISTERED
        );
      },
      keymap() {
        return {
          'ctrl+o': event => {
            event.preventDefault();

            if (this.$refs.openLink) {
              this.$refs.openLink.click();
            }
          },
        };
      },
    },
    mounted() {},
    methods: {
      async openPublication(event) {
        try {
          if (event) {
            event.stopPropagation();
          }

          if (this.activeHit.bookMeta.type === SUPPLEMENTAL) {
            this.$_openExternalLink();
            return;
          }

          const parsedQuery = this.parsedQuery || {};
          parsedQuery.stems = this.parsedResponse.stems;

          const searchNavigation = {
            searchQuery: this.searchText,
            navigateIndex: this.navigationIndex,
            totalResults: this.totalDocsCount,
            navigateList: this.navigationList,
            docId: this.activeHit.id,
          };

          const locator = this.activeHit.bookMeta.locator;

          const openPublicationResult = await provider.openPublication(
            this.activeHit,
            locator,
            parsedQuery,
            searchNavigation
          );

          if (openPublicationResult === UNSET_METHOD) {
            this.$_openExternalLink();
          } else {
            this.$store.dispatch('ContextStore/setMainPopupOpened', false);
          }
        } catch (error) {
          log.info(`openPublication failed with ${error}`);
        }
      },

      $_openExternalLink() {
        window.open(this.openPublicationLink, '_blank');
      },
    },
  };
</script>

<style lang="less">
  @import 'BookMeta.less';
</style>
