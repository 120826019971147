<template>
  <BaseIcon :icon-name="iconName" />
</template>

<script>
  import BaseIcon from '@/components/BaseIcon';

  export default {
    name: 'BookIcon',
    comments: {
      BaseIcon,
    },
    props: {
      publicationType: String,
    },
    computed: {
      iconName() {
        return this.publicationType === 'Supplemental'
          ? 'ico-book-new-half'
          : 'ico-book-new';
      },
    },
  };
</script>

<style scoped></style>
