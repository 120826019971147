import axios from 'axios';
import store from '@/store/store.js';

import { TIMER_FOR_CLEAR_INPUT } from '@/constants/constants';
import ErrorMessagesEnum from '@/enums/ErrorMessagesEnum';
import eventManager from '../EventService';

import * as log from 'loglevel';
log.setLevel('debug');

let elasticReq;
let request;
let timer;
const requests = [];

export default {
  /**
   * before start search need get search summary and check elastic search
   * @return {Promise}
   */
  init() {
    const initPromises = [this.getInfo(), this.healthCheck()];
    const supplementalLibConfig =
      store.getters['ContextStore/getSupplementalLibConfig'];
    if (supplementalLibConfig !== null) {
      initPromises.push(this.getSupplementalInfo());
    }
    eventManager.subscribe(
      'cancelPreviousRequest',
      this.cancelPreviousRequests
    );
    eventManager.subscribe(
      'cancelPreviousRequests',
      this.cancelPreviousRequests
    );
    return axios.all(initPromises);
  },

  /**
   * get search summary with content versions
   * @return {Promise}
   */
  getInfo() {
    const env = store.getters['ContextStore/getEnv'];
    const brand = store.getters['ContextStore/getBrand'];
    const indexSummaryUrls = store.getters['ContextStore/getIndexSummaryUrls'];
    const serverUrl = indexSummaryUrls[env];

    if (!serverUrl) {
      throw new Error('serverUrl not defined');
    }
    return this.request(serverUrl, 'get', `indexSummary?target=${brand}`);
  },

  /**
   * transport
   * @param  {string} type  is request types get, post, delete, put
   * @param  {string} url
   * @param  {string} path
   * @param  {object} data
   * @return {Promise}
   */
  request(url, type, path, data) {
    request = axios.CancelToken.source();
    requests.push(request);

    const apiClient = axios.create({
      baseURL: url,
      withCredentials: false,
      responseType: 'json',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const config = {
      url: `${url}${path}`,
      method: type,
      data: JSON.stringify(data),
      cancelToken: (request || {}).token,
    };

    this.waitForClearInput(); //if user open search popup in 10 min after last request (including filter) it should be clear
    return apiClient.request(config).catch(error => {
      if (error.message) {
        log.info(error.message);
      }
      throw error;
    });
  },
  /**
   * check is accessible elastic search
   * @return {Promise}
   */
  healthCheck() {
    return this.elasticRequest('get', '/_cat/indices?format=json', null).then(
      this._createMap
    );
  },
  /**
   * sent request to elastic search server
   * @param  {string} type
   * @param  {string} path
   * @param  {object} data
   * @return {Promise}
   */
  elasticRequest(type, path, data) {
    elasticReq = this.request(this.getUrl(), type, path, data);
    return elasticReq;
  },

  getUrl() {
    const url = store.getters['ContextStore/getElasticSearchUrl'];
    if (!url) {
      const event = new CustomEvent('searchWidgetError', {
        bubbles: true,
        detail: { error: () => 'elasticSearchUrl is not defined' },
      });
      document.querySelector('#search-popup').dispatchEvent(event);
      throw new Error('elasticSearchUrl is not defined');
    }

    return url;
  },
  /**
   * create map from index info arr
   * @param  {array} esInfo
   * @return {object}
   */
  _createMap(esInfo) {
    const map = {};
    if (esInfo && esInfo.data) {
      esInfo.data.forEach(indexInfo => {
        map[indexInfo.index] = indexInfo;
      });
    }

    return map;
  },

  getSupplementalInfo() {
    return this.supplementalServerRequest(
      'get',
      'search/searchinfo',
      null
    ).catch(function() {
      return Promise.resolve(null);
    });
  },
  /**
   * sent request to application server
   * @param  {string} type
   * @param  {string} path
   * @param  {object} data
   * @return {Promise}
   */
  supplementalServerRequest(type, path, data) {
    const supplementalLibConfig =
      store.getters['ContextStore/getSupplementalLibConfig'];
    const url = supplementalLibConfig.searchInfoUrl;

    if (!url) {
      log.debug('supplementalUrl not defined');
      return Promise.resolve(null);
    }

    return this.request(url, type, path, data);
  },
  /**
   * sent search request
   * @param  {object} query
   * @param  {string} lang
   * @param  {object} options
   * @return {Promise}
   */
  searchReq(query, lang, options) {
    const searchIndexes = [];
    const searchInfo = store.getters['ContextStore/getSearchInfo'];
    const indexNames = searchInfo && this.getIndexName(searchInfo, lang);

    if (!indexNames) {
      log.debug(`There aren't indexNames`);
      return;
    }

    const supplementalIndexNames = this.getSupplementalIndexNames(
      searchInfo,
      lang
    );
    let reqFn;

    options && options.nonCanceledRequest
      ? (reqFn = this.nonCanceledElasticRequest)
      : (reqFn = this.elasticRequest);

    this.addSearchIndexNames(searchIndexes, indexNames);
    this.addSearchIndexNames(searchIndexes, supplementalIndexNames);

    if (searchIndexes.length === 0) {
      return Promise.reject(
        new Error(
          `Didn't find search indexes indexNames: ${indexNames} and supplementalIndexNames: ${supplementalIndexNames} for search in elastic.`
        )
      );
    }
    return reqFn.call(
      this,
      'post',
      '/' + searchIndexes.join(',') + '/_search',
      query
    );
  },
  /**
   * create index name base on search summary form libary
   * @param  {object} searchInfo
   * @param  {string} lang
   * @return {string}
   */
  getIndexName(searchInfo, lang) {
    if (!searchInfo) {
      return null;
    }

    return searchInfo.searchSummary[lang];
  },
  /**
   * @param  {object} searchInfo
   * @param  {string} lang
   * @return {object}
   */
  getSupplementalIndexNames(searchInfo, lang) {
    if (searchInfo === null) {
      return null;
    }

    const allSupplementalInfo = searchInfo.supplementalSummary;
    const supplementalIndexNames = [];

    allSupplementalInfo.forEach(supplementalSummary => {
      const supplementalIndexName = this.getIndexName(
        supplementalSummary,
        lang
      );
      if (supplementalIndexName) {
        supplementalIndexNames.push(supplementalIndexName);
      }
    });

    return supplementalIndexNames;
  },

  nonCanceledElasticRequest(type, path, data, requestId) {
    return this.request(this.getUrl(), type, path, data, requestId);
  },

  addSearchIndexNames(searchIndexes, supplementalIndexNames) {
    supplementalIndexNames.forEach(indexName => {
      this.addSearchIndexName(searchIndexes, indexName);
    });
  },
  /**
   * add to array index name for search by them
   * @param {array} searchIndexes
   * @param {string} indexName
   */
  addSearchIndexName(searchIndexes, indexName) {
    const esInfoMap = store.getters['ContextStore/getEsInfoMap'];
    if (!esInfoMap.hasOwnProperty(indexName)) {
      if (indexName !== null) {
        log.warn(
          `Didn't find indexName: ${indexName} in elastic search index map.`
        );
      }
      return;
    }

    searchIndexes.push(indexName);
  },

  cancelPreviousRequests() {
    requests.forEach(req => req.cancel(ErrorMessagesEnum.CANCEL_REQUEST));
  },

  waitForClearInput() {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      store.dispatch('SearchStore/totalReset'); //dispatch the same language to clear lastInput in BaseInput
    }, TIMER_FOR_CLEAR_INPUT);
    return timer;
  },
};
