export default {
  minimizeSent(textContent) {
    const MAX_SYMBOLS_NUM = 80;
    const dots = '...';
    const HALF_SYMBOLS_NUM = Math.round(MAX_SYMBOLS_NUM / 2);
    const trimLeftRe = /^\S+\s/g;
    const trimRightRe = /\s\S+$/g;
    const re = /<span class="search-req">[^><]*<\/span>/g;
    textContent = textContent.replace(
      /<span class="search-quote">/g,
      '<span class="search-req">'
    );

    //find words and replace to mark with index
    const words = [];
    let index = 0;
    let title = textContent.replace(re, function(word) {
      words.push(word);
      const resp = '#word' + index + '#';
      index += 1;
      return resp;
    });

    //minimize sentence around word
    const parts = [];
    words.forEach(function(word, index) {
      const searchPart = '#word' + index + '#';
      const indexPart = title.indexOf(searchPart); //find word position in sentence
      const subStr = title.substring(0, indexPart); // get part from string
      let leftPart;
      let rightPart;
      if (subStr.length > MAX_SYMBOLS_NUM) {
        rightPart = subStr
          .substring(0, HALF_SYMBOLS_NUM)
          .replace(trimRightRe, '');
        leftPart = subStr
          .substring(subStr.length - HALF_SYMBOLS_NUM)
          .replace(trimLeftRe, '');
        if (parts.length === 0) {
          // in case first part ignore rigth part
          [].push.apply(parts, [dots, leftPart, word]);
        } else {
          [].push.apply(parts, [rightPart, dots, leftPart, word]);
        }
      } else {
        [].push.apply(parts, [subStr, word]);
      }
      title = title.substring(indexPart + searchPart.length);
    });
    if (title.length > HALF_SYMBOLS_NUM) {
      title = title.substring(0, HALF_SYMBOLS_NUM).replace(trimRightRe, '');
      [].push.apply(parts, [title, dots]);
    } else {
      parts.push(title);
    }
    return parts.join(' ').replace(/\s{2,}/g, ' ');
  },
};
