import ErrorMessagesEnum from '@/enums/ErrorMessagesEnum';
import ErrorMessagesForUsersEnum from '@/enums/ErrorMessagesForUsersEnum';
import * as log from 'loglevel';
log.setLevel('info');

const DEFAULT_IS_ERROR_OCCURRED = false;
const DEFAULT_ERROR_MESSAGE = '';
const DEFAULT_IS_REQUEST_CANCELLED = false;
const DEFAULT_STOP_RETRY = false;

function _createInitialErrorState() {
  return {
    isErrorOccurred: DEFAULT_IS_ERROR_OCCURRED,
    errorMessage: DEFAULT_ERROR_MESSAGE,
    isRequestCancelled: DEFAULT_IS_REQUEST_CANCELLED,
    stopRetry: DEFAULT_STOP_RETRY,
  };
}

const state = _createInitialErrorState();

const getters = {
  getIsRetryBlockShouldBeShown: state => {
    return (
      state.errorMessage === ErrorMessagesForUsersEnum.SERVER_ERROR &&
      !state.stopRetry
    );
  },
};

const actions = {
  setIsErrorOccurred({ commit, dispatch }, isErrorOccurred) {
    if (isErrorOccurred) {
      dispatch('SearchStore/totalReset', null, { root: true });
    }
    commit('setIsErrorOccurred', isErrorOccurred);
  },
  setErrorMessage({ commit, dispatch, state }, error) {
    const isStatus500 = calculateIsStatus500(error);
    const isRequestCancelled = calculateIsRequestCancelled(error);
    const isNetworkError = calculateIsNetworkError(error);
    switch (true) {
      case isStatus500:
        dispatch('setIsErrorOccurred', true);
        commit('setErrorMessage', ErrorMessagesForUsersEnum.SERVER_ERROR);

        log.info(`performSearch failed with ${error}`);
        break;
      case isNetworkError:
        dispatch('setIsErrorOccurred', true);
        commit('setErrorMessage', ErrorMessagesForUsersEnum.NETWORK_ERROR);

        log.info(`performSearch failed with ${error}`);
        break;
      case isRequestCancelled:
        dispatch('setIsRequestCancelled', true);
        break;
      case !state.isRequestCancelled:
        commit('SearchStore/setTotalPublications', null, { root: true });
        commit('SearchStore/setTotalHits', null, { root: true });

        dispatch('SearchStore/resetSearchStates', null, { root: true });
        dispatch('SearchStore/setFoundNothing', true, { root: true });
        dispatch('FilterStore/resetFilterStore', null, { root: true });

        log.info(`performSearch found nothing`);
        break;

      default:
        break;
    }
  },

  setIsRequestCancelled({ commit }, isRequestCancelled) {
    commit('setIsRequestCancelled', isRequestCancelled);
  },

  setStopRetry({ commit }, stopRetry) {
    commit('setStopRetry', stopRetry);
  },

  resetErrorStore({ commit }) {
    commit('setStopRetry');
    commit('setIsErrorOccurred');
    commit('setIsRequestCancelled');
    commit('setErrorMessage');
  },
};

const mutations = {
  setIsErrorOccurred(state, isErrorOccurred) {
    state.isErrorOccurred = isErrorOccurred || DEFAULT_IS_ERROR_OCCURRED;
  },

  setIsRequestCancelled(state, isRequestCancelled) {
    state.isRequestCancelled =
      isRequestCancelled || DEFAULT_IS_REQUEST_CANCELLED;
  },

  setErrorMessage(state, message) {
    state.errorMessage = message || DEFAULT_ERROR_MESSAGE;
  },

  setStopRetry(state, stopRetry) {
    state.stopRetry = stopRetry || DEFAULT_STOP_RETRY;
  },
};

function calculateIsStatus500(error) {
  return error?.response?.status === 500;
}

function calculateIsRequestCancelled(error) {
  return !!error?.message.match(
    new RegExp(ErrorMessagesEnum.CANCEL_REQUEST.replace(/['"]+/g, ''))
  );
}

function calculateIsNetworkError(error) {
  return !!error?.message.match(
    new RegExp(ErrorMessagesEnum.NETWORK_ERROR.replace(/['"]+/g, ''))
  );
}
export default {
  getters,
  actions,
  mutations,
  state,
  namespaced: true,
  name: 'ErrorStore',
};
