import elasticSearch from '@/services/ElasticSearch/ElasticSearch';
import store from '@/store/store';

import request from '@/services/ElasticSearch/Request';

import * as log from 'loglevel';
log.setLevel('info');

function _runSearchCommand(onlineFn, offlineFn, args) {
  args = args || [];
  return onlineFn.apply(null, args);
}

export default {
  init() {
    try {
      return request.init();
    } catch (error) {
      log.error(`Elastic search init request aborted with ${error}`);
    }
  },

  getHits(hitQueryParams) {
    const onlineFn = elasticSearch.getHits;
    // const offlineFn = swOfflineSearchService.performSearch;
    const offlineFn = null; //should be deleted

    return _runSearchCommand(onlineFn, offlineFn, [hitQueryParams]);
  },

  getAvaliableIndexes() {
    const onlineFn = this.getEsInfoMap;
    // const offlineFn = swOfflineSearchService.getAvaliableIndexes;
    const offlineFn = () => {}; //should be deleted

    return _runSearchCommand(onlineFn, offlineFn);
  },

  getEsInfoMap() {
    return store.getters['ContextStore/getEsInfoMap'];
  },
  getSentenceResults() {
    const args = [].slice.call(arguments);
    const onlineFn = elasticSearch.getSentenceResults;
    // const offlineFn = swOfflineSearchService.getSentenceResults;
    const offlineFn = null; //should be deleted

    return _runSearchCommand(onlineFn, offlineFn, args);
  },

  getPreviousParagraphs() {
    const args = [].slice.call(arguments);
    const onlineFn = elasticSearch.getPreviousParagraphs;
    // const offlineFn = swOfflineSearchService.getPreviousParagraphs;
    const offlineFn = null; //should be deleted

    return _runSearchCommand(onlineFn, offlineFn, args);
  },

  getNextParagraphs() {
    const args = [].slice.call(arguments);
    const onlineFn = elasticSearch.getNextParagraphs;
    // const offlineFn = swOfflineSearchService.getNextParagraphs;
    const offlineFn = null; //should be deleted

    return _runSearchCommand(onlineFn, offlineFn, args);
  },

  getMoreSentence() {
    // const args = [].slice.call(arguments);
    // const onlineFn = elasticSearch.getMoreSentence;
    // // const offlineFn = swOfflineSearchService.getMoreSentence;
    //
    // return _runSearchCommand(onlineFn, offlineFn, args);
  },

  getDocById() {
    const args = [].slice.call(arguments);
    const onlineFn = elasticSearch.getDocById;
    return _runSearchCommand(onlineFn, null, args);
  },

  getParagraphs() {
    const args = [].slice.call(arguments);
    const onlineFn = elasticSearch.getParagraphs;
    // const offlineFn = swOfflineSearchService.getParagraphs;
    const offlineFn = null; //should be deleted

    return _runSearchCommand(onlineFn, offlineFn, args);
  },

  getParagraphForEncumbered() {
    const args = [].slice.call(arguments);
    const onlineFn = elasticSearch.getParagraphForEncumbered;
    // const offlineFn = swOfflineSearchService.getParagraphForEncumbered;
    const offlineFn = null;

    return _runSearchCommand(onlineFn, offlineFn, args);
  },

  getFilter() {
    const args = [].slice.call(arguments);
    const onlineFn = elasticSearch.getFilter;
    // const offlineFn = swOfflineSearchService.getParagraphForEncumbered;
    const offlineFn = null;

    return _runSearchCommand(onlineFn, offlineFn, args);
  },
};
