import arabicUtils from '@/utils/arabic/arabicUtils';
import farsiUtils from '@/utils/farsi/farsiUtils';
import englishUtils from '@/utils/english/englishUtils';
import { IGNORE_QUOTES } from '@/utils/english/englishWordUtilsEnum';
import XRegExp from 'xregexp';

const arabicUtilsPopulated = arabicUtils();
const farsiUtilsPopulated = farsiUtils();
const englishUtilsPopulated = englishUtils();

function getStopwordsByLang(lang) {
  let stopWordsList;

  switch (lang) {
    case 'en':
    case 'pt':
    case 'de':
    case 'it':
      stopWordsList = englishUtilsPopulated.getStopWordsList();
      break;
    case 'ar':
      stopWordsList = farsiUtilsPopulated.getStopWordsList();
      break;
    case 'fa':
      stopWordsList = arabicUtilsPopulated.getStopWordsList();
      break;
  }
  return stopWordsList;
}

function filterByStopWords(words, lang) {
  const stopWords = getStopwordsByLang(lang);
  return words.filter(rawWord => {
    const word = replaceDiacritic(rawWord.toLowerCase(), lang);
    return !stopWords.hasOwnProperty(word);
  });
}

function tokenizing(sentence, lang) {
  let tokenizedSentence;

  switch (lang) {
    case 'en':
    case 'pt':
    case 'de':
    case 'it':
      tokenizedSentence = englishUtilsPopulated.tokenizer(sentence);
      break;
    case 'ar':
      tokenizedSentence = arabicUtilsPopulated.tokenizer(sentence);
      break;
    case 'fa':
      tokenizedSentence = farsiUtilsPopulated.tokenizer(sentence);
      break;
  }
  return tokenizedSentence;
}

function simpleEnTokenizing(sentence) {
  const tokenizeEnRe = new XRegExp('[^\\p{Latin}-' + IGNORE_QUOTES + ']+', 'g');
  const deshEnRe = new XRegExp('((^| )-)', 'g');
  sentence = sentence.replace(tokenizeEnRe, ' ').replace(deshEnRe, ' ');
  sentence = sentence.replace(/\s{2,}/, ' ').split(/\s+/);

  return sentence;
}

function replaceDiacritic(sentence, lang) {
  let sentenceWithReplacedDiacritic;

  switch (lang) {
    case 'en':
    case 'pt':
    case 'de':
    case 'it':
      sentenceWithReplacedDiacritic = englishUtilsPopulated.replaceDiacritic(
        sentence
      );
      break;
    case 'ar':
      sentenceWithReplacedDiacritic = arabicUtilsPopulated.replaceDiacritic(
        sentence
      );
      break;
    case 'fa':
      sentenceWithReplacedDiacritic = farsiUtilsPopulated.replaceDiacritic(
        sentence
      );
      break;
  }
  return sentenceWithReplacedDiacritic;
}

function stemming(token, lang) {
  let stem;

  switch (lang) {
    case 'en':
    case 'pt':
    case 'de':
    case 'it':
      stem = englishUtilsPopulated.stemmer(token);
      break;
    case 'ar':
      stem = arabicUtilsPopulated.stemmer(token);
      break;
    case 'fa':
      stem = farsiUtilsPopulated.stemmer(token);
      break;
  }
  return stem;
}

function getPhoneme(stem, lang) {
  let phoneme;

  switch (lang) {
    case 'en':
    case 'pt':
    case 'de':
    case 'it':
      phoneme = englishUtilsPopulated.soundEx(stem);
      break;
    case 'ar':
      phoneme = arabicUtilsPopulated.soundEx(stem);
      break;
    case 'fa':
      phoneme = farsiUtilsPopulated.soundEx(stem);
      break;
  }
  return phoneme;
}

const englishToPersian = s => s.replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d]);

const englishToArabic = s => s.replace(/\d/g, d => '٠١٢٣٤٥٦٧٨٩'[d]);

const localizeNumber = (number, language) => {
  try {
    let translatedNumber = number;
    number = typeof number === 'string' ? number : number.toString();
    switch (language) {
      case 'ar':
        translatedNumber = englishToArabic(number);
        break;
      case 'fa':
        translatedNumber = englishToPersian(number);
        break;
      default:
    }
    return translatedNumber;
  } catch (error) {
    return number;
  }
};

const languageUtils = {
  generateWords: arabicUtilsPopulated.generateWords,
  filterByStopWords,
  tokenizing,
  replaceDiacritic,
  stemming,
  getPhoneme,
  simpleEnTokenizing,
  localizeNumber,
};

export default languageUtils;
