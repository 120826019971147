import { FFA, OCEAN, OOL, WIDGET_NAME_MAP } from '@/constants/constants';
import store from '../store/store.js';
let start;
let end;
let selectElement;
let insert;
export default {
  correctInputIfCursorInsideWord(searchTextFromKeyboard) {
    if (!searchTextFromKeyboard) {
      return searchTextFromKeyboard;
    }
    let correctInput;
    start = 0;
    end = 0;
    selectElement = this.findElement();
    start = selectElement?.selectionStart;
    end = selectElement?.selectionEnd;
    const isCopyPast = store.getters['KeyboardStore/getIsCopyPast'];
    const isBackspacePressed = this.$_checkIfBackspace(
      searchTextFromKeyboard,
      isCopyPast
    );
    const stringLength = searchTextFromKeyboard.length;
    insert = searchTextFromKeyboard[end - 1];

    if (isBackspacePressed) {
      if (selectElement) {
        correctInput =
          selectElement.value?.substring(selectElement.value.length, end) +
          selectElement.value?.substring(0, start - 1);
      } else {
        correctInput = searchTextFromKeyboard;
      }
      this.correctCursorPositionForDelete();
    } else if (isCopyPast) {
      correctInput = searchTextFromKeyboard;
    } else if (start !== 0 && start < stringLength) {
      correctInput = this.$_getCorrectInput(
        searchTextFromKeyboard,
        stringLength
      );
    } else {
      correctInput = searchTextFromKeyboard;
    }

    isCopyPast && store.dispatch('KeyboardStore/setIsCopyPast', false);

    return correctInput;
  },
  findElement() {
    const widgetName = this.$_getWidgetName();
    const widget = document.querySelector(widgetName);
    return widget
      ? widget.shadowRoot.querySelector('input[name="searchInput"]')
      : document.querySelector('input[name="searchInput"]');
  },

  $_getWidgetName() {
    let widgetName;
    const brand = store.getters['ContextStore/getBrand'];
    switch (brand) {
      case FFA:
        widgetName = WIDGET_NAME_MAP.ffa;
        break;
      case OCEAN:
        widgetName = WIDGET_NAME_MAP.ocean;
        break;
      case OOL:
        widgetName = WIDGET_NAME_MAP.ool;
        break;
    }
    return widgetName;
  },
  correctCursorPositionForInsert() {
    const isCopyPast = store.getters['KeyboardStore/getIsCopyPast'];
    let position;
    if (isCopyPast) {
      position = insert?.length;
    } else {
      position = 1;
    }
    selectElement &&
      selectElement.setSelectionRange(start + position, end + position);
  },
  correctCursorPositionForDelete() {
    selectElement?.setSelectionRange(start - 1, end - 1);
  },
  $_checkIfBackspace(searchTextFromKeyboard, isCopyPast) {
    return (
      !isCopyPast &&
      searchTextFromKeyboard.length < selectElement?.value?.length
    );
  },
  $_getCorrectInput(string, stringEnd) {
    const correctInput =
      string.substring(0, start - 1) +
      insert +
      string.substring(end, stringEnd);

    return correctInput;
  },
};
