export default {
  getStopWordsList() {
    /* eslint-disable */
    return {
      'a': null,
      'an': null,
      'and': null,
      'are': null,
      'as': null,
      'at': null,
      'be': null,
      'but': null,
      'by': null,
      'for': null,
      'if': null,
      'in': null,
      'into': null,
      'is': null,
      'it': null,
      'no': null,
      'not': null,
      'of': null,
      'on': null,
      'or': null,
      'such': null,
      'that': null,
      'the': null,
      'their': null,
      'then': null,
      'there': null,
      'these': null,
      'they': null,
      'this': null,
      'to': null,
      'was': null,
      'will': null,
      'with': null,
    };
    /* eslint-enable */
  },
};
