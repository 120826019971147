import {
  NUMBER_PARAGRAPHS,
  NUMBER_HITS_GROUPES,
  DEFAULT_HITS_START_INDEX,
  NUMBER_PARAGRAPHS_ENCUMBERED,
} from '@/constants/constants';

class HitQueryParams {
  constructor(data = {}) {
    this.language = data.language;
    this.startIndex = data.startIndex || DEFAULT_HITS_START_INDEX;
    this.sentencesCount = data.sentencesCount || NUMBER_HITS_GROUPES;
    this.paragraphsCount = data.paragraphsCount || NUMBER_PARAGRAPHS;
    this.docId = data.docId;
    this.parsedQuery = data.parsedQuery;
  }
}

class HitQueryParamsBuilder {
  setLanguage(language) {
    this.language = language;
    return this;
  }

  setStartIndex(startIndex) {
    this.startIndex = startIndex || DEFAULT_HITS_START_INDEX;
    return this;
  }

  setSentencesCount(sentencesCount) {
    this.sentencesCount = sentencesCount || NUMBER_HITS_GROUPES;
    return this;
  }

  setParagraphsCount(paragraphsCount) {
    this.paragraphsCount = paragraphsCount || NUMBER_PARAGRAPHS;
    return this;
  }

  setDocId(docId) {
    this.docId = docId;
    return this;
  }

  setParsedQuery(parsedQuery) {
    this.parsedQuery = parsedQuery;
    return this;
  }

  build() {
    return new HitQueryParams(this);
  }
}

const createHitQueryParams = (data = {}) => {
  return new HitQueryParamsBuilder()
    .setDocId(data.docId)
    .setLanguage(data.language)
    .setStartIndex(data.startIndex)
    .setSentencesCount(data.sentencesCount)
    .setParagraphsCount(data.paragraphsCount)
    .setParsedQuery(data.parsedQuery)
    .build();
};

class ParagraphsEncumberedQueryParams {
  constructor(data) {
    this.moreTextIds = data.moreTextIds || [];
    this.highlight = data.highlight || [];
    this.language = data.language;
    this.paragraphsCount = data.paragraphsCount || NUMBER_PARAGRAPHS_ENCUMBERED;
  }
}

class ParagraphsEncumberedQueryParamsBuilder {
  setHighlight(highlight) {
    this.highlight = highlight;
    return this;
  }

  setLanguage(language) {
    this.language = language;
    return this;
  }

  setMoreTextIds(moreTextIds) {
    this.moreTextIds = moreTextIds;
    return this;
  }

  setNumberParagraphs(numberParagraphs) {
    this.paragraphsCount = numberParagraphs;
    return this;
  }

  build() {
    return new ParagraphsEncumberedQueryParams(this);
  }
}

const createParagraphsEncumberedQueryParams = (data = {}) => {
  return new ParagraphsEncumberedQueryParamsBuilder()
    .setHighlight(data.highlight)
    .setLanguage(data.language)
    .setMoreTextIds(data.moreTextIds)
    .setNumberParagraphs(data.paragraphsCount)
    .build();
};

class ParagraphsQueryParams {
  constructor(data = {}) {
    this.publicationId = data.publicationId;
    this.paraId = data.paraId;
    this.paragraphsCount = data.paragraphsCount || NUMBER_PARAGRAPHS;
    this.language = data.language;
    this.highlight = data.highlight;
    this.accessStatus = data.accessStatus;
    this.searchText = data.searchText;
  }
}

class ParagraphsQueryParamsBuilder {
  setPublicationId(publicationId) {
    this.publicationId = publicationId;
    return this;
  }

  setParaId(paraId) {
    this.paraId = paraId;
    return this;
  }

  setLanguage(language) {
    this.language = language;
    return this;
  }

  setAccessStatus(accessStatus) {
    this.accessStatus = accessStatus;
    return this;
  }

  setHighlight(highlight) {
    this.highlight = highlight;
    return this;
  }

  setParagraphsCount(paragraphsCount) {
    this.paragraphsCount = paragraphsCount || NUMBER_PARAGRAPHS;
    return this;
  }

  setSearchText(searchText) {
    this.searchText = searchText;
    return this;
  }

  build() {
    return new ParagraphsQueryParams(this);
  }
}

const createParagraphsQueryParams = (data = {}) => {
  return new ParagraphsQueryParamsBuilder()
    .setPublicationId(data.publicationId)
    .setParaId(data.paraId)
    .setParagraphsCount(data.paragraphsCount)
    .setLanguage(data.language)
    .setHighlight(data.highlight)
    .setAccessStatus(data.accessStatus)
    .setSearchText(data.searchText)
    .build();
};

class NavigationQueryParams {
  constructor(data = {}) {
    this.parsedQuery = data.parsedQuery;
    this.publicationId = data.publicationId;
    this.language = data.language;
    this.itemsCount = data.itemsCount;
  }
}

class NavigationQueryParamsBuilder {
  setParsedQuery(parsedQuery) {
    this.parsedQuery = parsedQuery;
    return this;
  }

  setPublicationId(publicationId) {
    this.publicationId = publicationId;
    return this;
  }

  setLanguage(language) {
    this.language = language;
    return this;
  }

  setItemsCount(itemsCount) {
    this.itemsCount = itemsCount;
    return this;
  }

  build() {
    return new NavigationQueryParams(this);
  }
}

const createNavigationQueryParams = (data = {}) => {
  return new NavigationQueryParamsBuilder()
    .setParsedQuery(data.parsedQuery)
    .setPublicationId(data.publicationId)
    .setLanguage(data.language)
    .setItemsCount(data.itemsCount)
    .build();
};

class DocByIdQueryParams {
  constructor(data = {}) {
    this.docId = data.docId;
    this.language = data.language;
  }
}

class DocByIdQueryParamsBuilder {
  setDocId(docId) {
    this.docId = docId;
    return this;
  }

  setLanguage(language) {
    this.language = language;
    return this;
  }

  build() {
    return new DocByIdQueryParams(this);
  }
}

const createDocByIdQueryParams = (data = {}) => {
  return new DocByIdQueryParamsBuilder()
    .setDocId(data.docId)
    .setLanguage(data.language)
    .build();
};

class FilterQueryParams {
  constructor(data = {}) {
    this.parsedQuery = data.parsedQuery;
    this.language = data.language;
    this.isInitial = data.isInitial;
  }
}

class FilterQueryParamsBuilder {
  setParsedQuery(parsedQuery) {
    this.parsedQuery = parsedQuery;
    return this;
  }

  setLanguage(language) {
    this.language = language;
    return this;
  }

  setIsInitial(isInitial) {
    this.isInitial = isInitial;
    return this;
  }

  build() {
    return new FilterQueryParams(this);
  }
}

const createFilterQueryParams = (data = {}) => {
  return new FilterQueryParamsBuilder()
    .setParsedQuery(data.parsedQuery)
    .setLanguage(data.language)
    .setIsInitial(data.isInitial)
    .build();
};

export default {
  createHitQueryParams,
  createParagraphsQueryParams,
  createParagraphsEncumberedQueryParams,
  createNavigationQueryParams,
  createDocByIdQueryParams,
  createFilterQueryParams,
};
